import { AuthService } from "@/helpers/services";
const authService = new AuthService();

const tiposInstrumentos = [
  {
    text: "Piezômetro / INA",
    short: "Piezômetro / INA",
    shortPlural: "Piezômetros",
    value: "inst_piezometro",
  },
  {
    text: "Pluviômetro",
    short: "Pluviômetro",
    shortPlural: "Pluviômetros",
    value: "inst_pluviometro",
  },
  {
    text: "Régua do reservatório",
    shortPlural: "Reservatórios",
    value: "inst_regua_reservatorio",
  },
  {
    text: "Outros",
    value: "inst_outros",
  },
];

export default {
  namespaced: true,
  state: () => ({
    tipos: tiposInstrumentos,

    creating: false,
    createFailure: null,
    createSuccess: null,

    editing: false,
    editFailure: null,
    editSuccess: null,

    deleting: false,
    deleteFailure: null,
    deleteSuccess: null,
  }),
  mutations: {
    CREATE_START: (state) => {
      state.creating = true;
      state.createFailure = null;
      state.createSuccess = null;
    },
    CREATE_FAILURE: (state, error) => {
      state.creating = false;
      state.createFailure = error;
    },
    CREATE_SUCCESS: (state, response) => {
      state.creating = false;
      state.createFailure = null;
      state.createSuccess = response;
    },
    EDIT_START: (state) => {
      state.editing = true;
      state.editFailure = null;
      state.editSuccess = null;
    },
    EDIT_FAILURE: (state, error) => {
      state.editing = false;
      state.editFailure = error;
    },
    EDIT_SUCCESS: (state, response) => {
      state.editing = false;
      state.editFailure = null;
      state.editSuccess = response;
    },
    DELETE_START: (state) => {
      state.deleting = true;
      state.deleteFailure = null;
      state.deleteSuccess = null;
    },
    DELETE_FAILURE: (state, error) => {
      state.deleting = false;
      state.deleteFailure = error;
    },
    DELETE_SUCCESS: (state, response) => {
      state.deleting = false;
      state.deleteFailure = null;
      state.deleteSuccess = response;
    },
  },
  actions: {
    async create({ commit }, payload) {
      commit("CREATE_START");

      try {
        const result = await authService.gravaInstrumento(payload);
        const message = result?.data?.message;

        return message
          ? commit("CREATE_SUCCESS", result.data.message)
          : commit("CREATE_FAILURE", new Error(message || "Invalid response"));
      } catch (err) {
        console.log(err);
        return commit("CREATE_FAILURE", err);
      }
    },
    async createOther({ commit }, payload) {
      commit("CREATE_START");

      try {
        const result = await authService.novoInstrumentoGenerico(payload);
        const message = result?.data?.message;

        return message
          ? commit("CREATE_SUCCESS", result.data.message)
          : commit("CREATE_FAILURE", new Error(message || "Invalid response"));
      } catch (err) {
        console.log(err);
        return commit("CREATE_FAILURE", err);
      }
    },
    async edit({ commit }, payload) {
      commit("EDIT_START");

      try {
        const result = await authService.editaInstrumento(payload);
        const message = result?.data?.message;

        return message
          ? commit("EDIT_SUCCESS", result.data.message)
          : commit("EDIT_FAILURE", new Error(message || "Invalid response"));
      } catch (err) {
        console.log(err);
        return commit("EDIT_FAILURE", err);
      }
    },
    async delete({ commit }, payload) {
      commit("DELETE_START");
      try {
        if (payload.tipo_instrumento_value) {
          const result = await authService.deletarOutrosInstrumentos(payload);
          const message = result?.data?.message;
          return message
            ? commit("DELETE_SUCCESS", result.data.message)
            : commit(
                "DELETE_FAILURE",
                new Error(message || "Invalid response")
              );
        }
        const result = await authService.deletaInstrumento(payload);
        const message = result?.data?.message;

        return message
          ? commit("DELETE_SUCCESS", result.data.message)
          : commit("DELETE_FAILURE", new Error(message || "Invalid response"));
      } catch (err) {
        console.log(err);
        return commit("DELETE_FAILURE", err);
      }
    },
  },
};
