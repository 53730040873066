import { http } from "@/helpers/services";

function AuthService(options = {}) {
  const baseURL = process.env.VUE_APP_BASE_URL;
  const service = this;
  const httpClient = http({
    baseURL,
    ...options,
  });
  // const token = localStorage.getItem("token");
  service.authUser = (payload) =>
    httpClient.post(
      "/user/check",
      {
        ...payload,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    );
  service.gravaUsuario = (payload) =>
    httpClient.post(
      "/user/create",
      {
        ...payload,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    );
  service.gravaCliente = (payload) =>
    httpClient.post(
      "/client/create",
      {
        ...payload,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    );
  service.gravaEstrutura = (payload) =>
    httpClient.post(
      "/client/estrutura",
      {
        ...payload,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    );
  service.buscaEstruturas = () =>
    httpClient.get(
      `/client/estrutura?cliente_id=${localStorage.getItem(
        "cliente_uuid"
      )}&usuario_id=${localStorage.getItem("usuario_uuid")}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    );
  service.deletaEstrutura = (payload) =>
    httpClient.delete(
      `/client/estrutura?usuario_id=${
        payload.usuario_id
      }&cliente_id=${localStorage.getItem("cliente_uuid")}&estrutura_id=${
        payload.estrutura_id
      }&nome=${payload.nome}&responsavel=${payload.responsavel}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    );
  service.editaEstrutura = (payload) =>
    httpClient.put(
      "/client/estrutura",
      {
        ...payload,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    );
  service.gravaInstrumento = (payload) =>
    httpClient.post(
      "/client/instrumento",
      {
        ...payload,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    );
  service.editaInstrumento = (payload) =>
    httpClient.put(
      "/client/instrumento",
      {
        ...payload,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    );
  service.buscaInstrumentos = () =>
    httpClient.get(
      `/client/instrumento?cliente_id=${localStorage.getItem(
        "cliente_uuid"
      )}&usuario_id=${localStorage.getItem("usuario_uuid")}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    );
  service.deletaInstrumento = (payload) =>
    httpClient.delete(
      `/client/instrumento?usuario_id=${
        payload.usuario_id
      }&cliente_id=${localStorage.getItem("cliente_uuid")}&estrutura_id=${
        payload.estrutura_id
      }&instrumento_id=${payload.instrumento_id}&tipo_instrumento=${
        payload.tipo_instrumento
      }&estrutura=${payload.estrutura}&responsavel=${
        payload.responsavel
      }&nome=${payload.nome}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    );
  service.buscaHistorico = (payload) =>
    httpClient.get(
      `/client/historico?cliente_id=${localStorage.getItem(
        "cliente_uuid"
      )}&usuario_id=${localStorage.getItem("usuario_uuid")}&tipo=${payload}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    );
  service.gravaLeitura = (payload) =>
    httpClient.post(
      "/client/leitura",
      {
        ...payload,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    );
  service.editaLeitura = (payload) =>
    httpClient.put(
      "/client/leitura",
      {
        ...payload,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    );
  service.getTipoInstrumentos = () =>
    httpClient.get(`/client/instrumento/lista`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    });
  service.buscaLeituras = () =>
    httpClient.get(
      `/client/leitura?usuario_id=${localStorage.getItem(
        "usuario_uuid"
      )}&cliente_id=${localStorage.getItem("cliente_uuid")}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    );
  service.deletaLeitura = (payload) =>
    httpClient.delete(
      `/client/leitura?usuario_id=${
        payload.usuario_id
      }&cliente_id=${localStorage.getItem("cliente_uuid")}&estrutura_id=${
        payload.estrutura_id
      }&leitura_id=${payload.leitura_id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    );

  service.getClientResume = () =>
    httpClient.get(
      `/client/resume?cliente_id=${localStorage.getItem("cliente_uuid")}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    );

  service.getClientNomeInstrumento = (payload) =>
    httpClient.get(
      `/client/instrumento?cliente_id=${localStorage.getItem(
        "cliente_uuid"
      )}&usuario_id=${payload.usuario_id}&estrutura_id=${
        payload.estrutura_id
      }&instrumento_id=${payload.instrumento_id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    );
  service.getClientTipoInstrumento = (payload) =>
    httpClient.get(
      `/client/tipoinstrumento?cliente_id=${localStorage.getItem(
        "cliente_uuid"
      )}&usuario_id=${payload.usuario_id}&estrutura_id=${payload.estrutura_id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    );
  service.getLeituraResumo = (payload) =>
    httpClient.get(
      `/client/leitura/resumo?cliente_id=${localStorage.getItem(
        "cliente_uuid"
      )}&usuario_id=${localStorage.getItem("usuario_uuid")}&estrutura_id=${
        payload.estrutura_id
      }&tipo_instrumento=${payload.tipo_instrumento}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    );
  service.getLeituraDynamoPorGrafico = (payload) =>
    httpClient.get(
      `/resumoLeituraPorGraficoDynamo?tableName=${payload.tableName}&sn=${payload.sn}&chartName=${payload.chartName}&dataIni=${payload.dataIni}&dataFim=${payload.dataFim}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    );

  service.getLeituraDynamoCalculada = (payload) =>
    httpClient.get(
      `/resumoLeituraCalculadaInstrumentoDynamo?tableName=${payload.tableName}&sn=${payload.sn}&dataIni=${payload.dataIni}&dataFim=${payload.dataFim}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    );

  service.getLeituraDynamoCalculadaNova = (payload) =>
    httpClient.get(
      `/resumoLeituraCalculadaInstrumentoDynamo?tableName=${payload.tableName}&sn=${payload.sn}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    );

  service.getTipoInstrumentosAutomaticos = (payload) =>
    httpClient.get(`/buscarInstrumentosAutomaticos?estrutura_id=${payload}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    });

  service.getLeiturasInstrumentos = (payload) =>
    httpClient.get(
      `/dadosInstrumento?sn=${payload.sn}&dataIni=${payload.dataIni}&dataFim=${payload.dataFim}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    );

  service.getLeiturasInstrumentosHomol = (payload) =>
    httpClient.get(
      `/dadosInstrumentoHomol?id=${payload.id}&sn=${payload.sn}&name=${payload.name}&dataIni=${payload.dataIni}&dataFim=${payload.dataFim}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    );

  service.getLeiturasInstrumentosHomolNovo = (payload) =>
    httpClient.get(
      `/dadosInstrumentoHomol?id=${payload.id}&sn=${payload.sn}&name=${payload.name}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    );

  service.resumoAlarmeDynamo = (payload) =>
    httpClient.post(
      `/resumoAlarmeDynamo?tableName=${payload.tableName}&estruturaId=${payload.estruturaId}&dataIni=${payload.dataIni}&dataFim=${payload.dataFim}`,
      payload.body,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    );

  service.csvDataDownload = (payload) =>
    httpClient.get(
      `/csvDataDownload?name=${payload.name}&id=${payload.id}&dataIni=${payload.dataIni}&dataFim=${payload.dataFim}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    );

  service.getVisaoGeral = (payload) =>
    httpClient.get(`/dadosVisaoGeral?estrutura_id=${payload.estrutura_id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    });

  service.createNovaInspecao = (payload) =>
    httpClient.post(
      "/inspecao",
      {
        ...payload,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    );
  service.findInspecoes = (client_id) =>
    httpClient.get(`/inspecao?client_id=${client_id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    });
  service.findInspecoesById = (client_id, inspecao_id) =>
    httpClient.get(
      `/inspecao?client_id=${client_id}&inspecao_id=${inspecao_id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    );
  service.editInspecoes = (payload) =>
    httpClient.put(
      "/inspecao",
      { ...payload },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    );

  service.deleteInspecao = ({ inspecao_id, client_id }) =>
    httpClient.delete(
      `/inspecao?inspecao_id=${inspecao_id}&client_id=${client_id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    );
  service.visualizarInspecaoByData = ({ tableName, dataIni, dataFim }) =>
    httpClient.get(
      `/visualizarInspecaoByData?tableName=${tableName}&dataIni=${dataIni}&dataFim=${dataFim}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    );

  service.listarTodosCanaisByAutoSn = (tableName, auto_sn_dl) =>
    httpClient.get(
      `/listarTodosCanaisByAutoSn?tableName=${tableName}&auto_sn_dl=${auto_sn_dl}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    );

  service.novoInstrumentoGenerico = (payload) =>
    httpClient.post(
      `/novoInstrumentoGenerico`,
      {
        ...payload,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    );

  service.listarTodosDataloggers = () =>
    httpClient.get(`/listarTodosDataloggers`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    });

  service.deletarOutrosInstrumentos = (payload) =>
    httpClient.delete(`/deletarOutrosInstrumentos`, {
      data: payload,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    });
}

export default AuthService;
