<template>
  <v-dialog
    transition="dialog-bottom-transition"
    max-width="564"
    :value="opened"
    @input="$emit('toggle', false)"
  >
    <v-card class="py-5">
      <v-progress-linear
        v-if="closeTimeout"
        :class="opened && 'auto-close-progress-bar'"
        color="primary"
        value="100"
        :style="autoCloseCssVars"
        top
        absolute
      />
      <v-row justify="center" no-gutters>
        <span :class="iconClass">
          <v-icon x-large dark>{{ computedIcon }}</v-icon>
        </span>
      </v-row>
      <span class="d-block pa-6 text-center text-subtitle font-weight-bold">
        <slot name="title">
          {{ title }}
        </slot>
      </span>
      <v-card-text class="text-center">
        <span>{{ text }}</span>
      </v-card-text>
      <v-form
        class="px-4"
        ref="form"
        @submit.prevent="confirmationAction"
        v-model="valid"
      >
        <v-card-actions>
          <v-row dense>
            <v-col v-if="shouldValidate" cols="12">
              <v-text-field
                ref="input"
                label="Confirmação"
                v-model="confirmationValue"
                :placeholder="confirmationPlaceholder"
                :rules="[confirmationRule]"
                persistent-placeholder
                outlined
                autofocus
                required
              />
            </v-col>
            <v-col v-if="cancel" cols="12" md="6">
              <v-btn
                ref="cancel"
                color="primary"
                @click="$emit('toggle', false)"
                block
                outlined
              >
                {{ cancel }}
              </v-btn>
            </v-col>
            <v-col cols="12" :md="cancel ? 6 : 12">
              <v-btn
                ref="submit"
                type="submit"
                :disabled="shouldValidate && !valid"
                class="primary darken-2"
                block
              >
                {{ confirm }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
function normalized(str) {
  return String(str).trim().toLowerCase();
}

export default {
  name: "ConfirmationDialog",
  model: {
    prop: "opened",
    event: "toggle",
  },
  props: {
    opened: { type: Boolean, required: false, default: false },
    theme: { type: String, required: false, default: "info" },
    icon: { type: String, required: false },
    title: { type: String, required: false, default: "Tem certeza?" },
    text: { type: String, required: false },
    cancel: { type: String, required: false },
    confirm: { type: String, required: false, default: "Confirmar" },
    confirmationText: { type: String, required: false },
    autoClose: { type: [Number, String, Boolean], required: false },
  },
  data: () => ({
    valid: true,
    confirmationValue: "",
    timeoutId: null,
  }),
  watch: {
    opened(isOpen) {
      if (isOpen) {
        if (this.timeoutId) clearTimeout(this.timeoutId);

        if (this.closeTimeout) {
          this.timeoutId = setTimeout(
            () => this.$emit("toggle", false),
            this.closeTimeout * 1000
          );
        }
      }
    },
  },
  computed: {
    closeTimeout() {
      const timeout = this.autoClose;
      const defaultTimeout = 5;

      if (typeof timeout === "string")
        return timeout === "" ? defaultTimeout : Number.parseInt(timeout, 10);

      if (typeof timeout === "boolean") return timeout ? defaultTimeout : false;

      if (typeof timeout === "number") return timeout;

      return false;
    },
    autoCloseCssVars() {
      return {
        "--auto-close-animation": `shrink ${this.closeTimeout}s linear`,
      };
    },
    shouldValidate() {
      const text = this.confirmationText;
      return typeof text === "string" && text !== "";
    },
    confirmationPlaceholder() {
      return `Digite ${this.computedConfirmationText}`;
    },
    iconClass() {
      return `${this.theme} my-auto pa-3 rounded-circle d-inline-block`;
    },
    computedConfirmationText() {
      return normalized(this.confirmationText).toUpperCase();
    },
    computedIcon() {
      if (typeof this.icon === "string") return this.icon;

      switch (this.theme) {
        case "success":
          return "mdi-check-circle";
        case "warning":
          return "mdi-alert";
        default:
          return "mdi-information";
      }
    },
  },
  methods: {
    confirmationAction() {
      if (this.valid) {
        this.$emit("confirmed");
        this.$emit("toggle", false);
        this.$emit("buscar", true);
      }
    },
    confirmationRule(val) {
      return (
        normalized(val) === normalized(this.confirmationText) ||
        `Você precisa digitar ${this.computedConfirmationText}`
      );
    },
  },
};
</script>

<style lang="scss">
.auto-close-progress-bar {
  .v-progress-linear__determinate {
    animation: var(--auto-close-animation);
    animation-fill-mode: both;
  }
}

@keyframes shrink {
  0% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}
</style>
