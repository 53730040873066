<script>
export default {
  name: "UserAppBar",
  props: {
    user: { type: Object },
    drawer: { type: Boolean },
  },
  data: () => ({}),
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
};
</script>
<template>
  <v-app-bar
    color="white"
    :height="isMobile ? 60 : 80"
    :dense="isMobile"
    :flat="!isMobile"
    clipped-left
    app
  >
    <router-link :to="{ name: 'Admin' }">
      <v-img
        v-if="!isMobile"
        class="my-5 ml-2 mr-10"
        src="@/assets/logo-geomin.png"
        max-width="152px"
        contain
      />
    </router-link>
    <v-app-bar-nav-icon @click="$emit('update:drawer', !drawer)">
      <template v-slot:default>
        <v-icon v-if="drawer">
          {{ !isMobile ? "mdi-chevron-double-left" : "" }}
        </v-icon>
        <v-icon v-else :color="isMobile ? 'primary' : undefined">
          {{ isMobile ? "mdi-menu" : "mdi-chevron-double-right" }}
        </v-icon>
      </template>
    </v-app-bar-nav-icon>
    <span class="ma-1 text-h6 font-weight-bold">Olá, {{ user.name }}!</span>

    <v-spacer></v-spacer>

    <div v-if="!isMobile">
      <v-btn icon @click="$emit('user:logout')">Sair</v-btn>
    </div>
    <div v-else>
      <router-link :to="{ name: 'Admin' }">
        <v-img
          class="ma-1"
          src="@/assets/logo-geomin.png"
          max-height="32px"
          contain
        />
      </router-link>
    </div>
  </v-app-bar>
</template>
<style lang="scss" scoped>
.v-app-bar.v-toolbar--flat {
  border-bottom: 1px solid map-get($grey, "lighten-1") !important;
}
</style>
