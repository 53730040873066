import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"pa-0",attrs:{"fluid":""}},[_c(VCard,{staticClass:"my-8",attrs:{"min-height":"20rem","flat":""}},[_c(VContainer,[(_vm.loading)?_c(VRow,[_c(VCol,{staticClass:"text-center",attrs:{"cols":"12"}},[_c(VProgressCircular,{attrs:{"indeterminate":"","color":"primary"}})],1)],1):_c(VRow,[_c(VCol,{staticClass:"d-inline-flex flex-nowrap justify-space-between overflow-x-auto",attrs:{"cols":"12"}},_vm._l((_vm.instrumentos),function(piezometro,i){return _c(VHover,{key:("p-" + i),scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{on:{"click":function($event){return _vm.setInstrumentoId(piezometro)}}},[_c('InstrumentoBarChart',{staticClass:"instrumento-bar mx-4",attrs:{"instrumento":piezometro,"position-y":_vm.positionYByHighest(piezometro),"hover":hover,"alarmesAtuados":_vm.alarmesList}})],1)]}}],null,true)})}),1)],1)],1)],1),(_vm.instrumentoEscolhido)?_c(VRow,{staticClass:"my-10"},[_c(VCol,[_c('h3',[_vm._v("Informações do instrumento:")]),(_vm.instrumentoEscolhido)?_c('InstrumentoDetails',{attrs:{"instrumento":_vm.instrumentoEscolhido}}):_vm._e()],1)],1):_vm._e(),_c(VRow,{staticClass:"my-10"},[_c(VCol,[_c('h3',[_vm._v("Status da saúde")]),_c('HealthDetails',{attrs:{"instrumento":_vm.instrumentoEscolhido,"alarmesAtuados":_vm.instrumentoEscolhido.alarmesAtuados,"isAuto":_vm.instrumento_automatizado,"tipoInstrumento":_vm.tipoInstrumento.value}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }