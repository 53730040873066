<template>
  <v-container>
    <v-row>
      <v-col md="6" class="mx-auto white elevation-12 rounded">
        <v-img
          class="my-5 mx-auto"
          src="@/assets/logo-geomin.png"
          max-width="152px"
        />
        <v-form ref="form" v-model="valid" lazy-validation class="pa-5 rounded">
          <v-text-field
            v-model="loginForm.email"
            :rules="emailRules"
            label="E-mail"
            required
            outlined
            @keypress.enter="sendLoginForm"
          ></v-text-field>
          <v-text-field
            v-model="loginForm.password"
            :append-icon="toggleHidden ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="passRules"
            :type="toggleHidden ? 'text' : 'password'"
            label="Senha"
            required
            outlined
            @click:append="toggleHidden = !toggleHidden"
            @keypress.enter="sendLoginForm"
          ></v-text-field>
          <v-btn
            :disabled="!valid"
            color="primary"
            large
            block
            @click="sendLoginForm"
          >
            Acessar
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "HomeLogin",

  data: () => ({
    valid: true,
    toggleHidden: false,
    loginForm: { email: "", password: "" },
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length >= 5) || "Name grater than 5 characters",
    ],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    passRules: [(v) => v.length >= 3 || "Min 3 characters"],
  }),
  mounted() {
    // this.$router.push({ name: "CriarCliente" });
  },
  methods: {
    async sendLoginForm() {
      if (this.validate()) {
        await this.loginFromServer(this.loginForm);
        this.$router.push({ name: "Admin" });
      }
    },

    validate() {
      return this.$refs.form.validate();
    },

    ...mapActions("user", ["loginFromServer"]),
  },
};
</script>
