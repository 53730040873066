<template>
  <v-container>
    <v-row>
      <v-col>
        <v-btn @click="backHome">Voltar</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-form ref="form" v-model="valid" lazy-validation class="pa-5 rounded">
          <v-text-field
            v-model="cliente.nome"
            :rules="clienteRule"
            :type="`text`"
            label="Nome da empresa/cliente"
            required
          ></v-text-field>
          <v-text-field
            v-model="cliente.cnpj"
            :rules="cnpjRule"
            :type="`number`"
            label="Nome da empresa/cliente"
            required
          ></v-text-field>
          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="sendClienteForm"
          >
            Adicionar meus dados
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { AuthService } from "@/helpers/services";

const authService = new AuthService();
export default {
  name: "CriarCliente",
  components: {},

  data: () => ({
    valid: true,
    toggleHidden: false,
    token: localStorage.getItem("token"),
    cliente: {
      nome: "Nome da empresa",
      cnpj: "0000000000010",
      usuario_id: localStorage.getItem("usuario_uuid"),
    },
    clienteRule: [
      (v) => !!v || "Informe o nome da sua empresa",
      (v) => (v && v.length >= 3) || "Precisa ter pelo menos 3 caracteres",
    ],
    cnpjRule: [
      (v) => !!v || "Informe cnpj",
      (v) => /[0-9]{11}/.test(v) || "CNPJ Incorreto",
    ],
    passRules: [(v) => v.length >= 8 || "Min 8 characters"],
  }),
  mounted() {
    if (localStorage.getItem("cliente_uuid")) {
      this.$router.push({ name: "Admin" });
    }
  },
  methods: {
    sendClienteForm() {
      if (this.validate()) {
        authService
          .gravaCliente(this.cliente)
          .then(({ data }) => {
            localStorage.setItem("cliente_uuid", data.data.uuid);
            localStorage.setItem("cliente_nome", data.data.nome);
            alert(data.message);

            this.$router.push({ name: "Admin" });
            return data;
          })
          .catch(({ response }) => {
            alert(response.data.message);

            return response.data;
          });
      }
    },
    validate() {
      return this.$refs.form.validate();
    },
    backHome() {
      this.$router.push({ name: "Home" });
    },
  },
};
</script>
