export default {
  data: () => ({
    confirmationDialog: false,
  }),
  computed: {
    isAdicionar() {
      return String(this.tipoAcao).toLocaleLowerCase() === "adicionar";
    },
    isEditar() {
      return String(this.tipoAcao).toLocaleLowerCase() === "editar";
    },
    isExcluir() {
      return String(this.tipoAcao).toLocaleLowerCase() === "excluir";
    },
    isDownload() {
      return String(this.tipoAcao).toLocaleLowerCase() === "download";
    },
    confirmButton() {
      if (this.isEditar) {
        return "Sim, quero editar";
      } else if (this.isAdicionar) {
        return "Sim, quero adicionar";
      } else {
        return "Sim, tenho certeza";
      }
    },
    confirmTheme() {
      // TODO: add more themes
      return "warning";
    },
    confirmResource() {
      return "";
    },
    confirmTitle() {
      if (this.isDownload) {
        return `Você está prestes a gerar o ${this.tipoAcao.toLocaleLowerCase()} ${
          this.confirmResource
        }`;
      } else {
        return `Você está prestes a ${this.tipoAcao.toLocaleLowerCase()} ${
          this.confirmResource
        }`;
      }
    },
  },
  methods: {
    openConfirmationDialog() {
      this.confirmationDialog = true;
    },
  },
};
