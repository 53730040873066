<template>
  <v-simple-table dense class="inspecao-resumo-table">
    <tbody>
      <tr>
        <td class="text-caption font-weight-bold">Estrutura</td>
        <td class="ml-1 text-caption">{{ inspecao.estrutura }}</td>
      </tr>
      <tr>
        <td class="text-caption font-weight-bold">Instrumento</td>
        <td class="ml-1 text-caption">{{ inspecao.instrumento }}</td>
      </tr>
      <tr>
        <td class="text-caption font-weight-bold">Inspeção</td>
        <td class="ml-1 text-caption">
          [{{ inspecao.data_inspecao }}]
          {{ inspecao.inspecao }}
        </td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
export default {
  name: "InspecaoResumo",
  props: { inspecao: Object },
};
</script>

<style scoped lang="scss">
.inspecao-resumo-table {
  tr > td {
    text-align: right;
    & + td {
      text-align: left;
    }
  }
}
</style>
