<script>
import moment from "moment";

export default {
  name: "InstrumentoBarChart",
  props: {
    instrumento: { type: Object, required: true },
    positionY: { type: Number },
    hover: { type: Boolean },
    alarmesAtuados: { type: Array },
    // displayCota: { type: Boolean, required: true }, // Add prop to indicate whether to display cue or 0
  },
  data: () => ({
    leiturasDynamo: 0,
    dates: [
      moment().add({ days: -7 }).format("YYYY-MM-DD"),
      moment().format("YYYY-MM-DD"),
    ],
    nivelAtual: null,
    dataAtual: null,
    NivelCota: null, // if true, means that Nivel will be displayed. If false, Cota will be diplayed.
  }),

  computed: {
    ultimaLeitura() {
      let leituraAutomatica = this.leiturasDynamo?.leitura;
      let leituraManual = this.instrumento?.leituras[0]?.leitura;
      let dataManual = this.instrumento?.leituras[0]?.data_leitura;
      let dataAutomatica = this.leiturasDynamo?.timeStamp;

      //caso NaN transforma em undefined ( erro )
      if (leituraAutomatica?.leitura == "NaN") {
        leituraAutomatica = undefined;
      }
      //caso NaN transforma em undefined ( erro )
      if (leituraManual?.leitura == "NaN") {
        leituraManual = undefined;
      }

      // Verifica se existe as duas cotas
      if (leituraManual != undefined && leituraAutomatica != undefined) {
        if (dataManual >= moment(dataAutomatica)) {
          // Cota manual
          return leituraManual;
        } else {
          // Cota automatica
          return leituraAutomatica;
        }
      }
      if (leituraManual != undefined) {
        // Verifica se existe apenas a manual
        return leituraManual;
      }
      if (leituraAutomatica != undefined) {
        // Verifica se existe apenas a leitura automatica
        return leituraAutomatica;
      }
      // retornando vazio (sem leituras)
      return 0;
    },

    dataLeitura() {
      return moment(this.dataAtual).format("YYYY-MM-DD") || "-";
    },

    horaLeitura() {
      return moment(this.dataAtual).format("HH:mm:ss") || "-";
    },

    leituraProfundidade() {
      // calcula a profundidade do furo (topo - fundo)
      return (
        (this.instrumento.cota_topo - this.instrumento.cota_fundo).toFixed(2) ||
        0
      );
    },

    leituraValor() {
      this.cadam_cliente();
      this.getDados(this.instrumento);
      let leituraManual = this.instrumento?.leituras[0]?.leitura;
      let leituraAutomatica = this.leiturasDynamo.leitura;
      if (leituraAutomatica == "NaN") {
        leituraAutomatica = undefined;
      }
      if (this.NivelCota) {
        if (leituraManual) {
          const leituraValor =
            parseFloat(
              Number(this.leituraProfundidade - this.ultimaLeitura).toFixed(2)
            ) || 0;
          this.$emit("dadosUltimaLeitura", leituraValor);
          return this.nivelAtual;
        } else {
          this.$emit("dadosUltimaLeitura", leituraAutomatica || 0);
          return this.nivelAtual || 0;
        }
      } else {
        if (leituraManual) {
          const leituraValor =
            parseFloat(
              Number(this.instrumento.cota_topo - this.nivelAtual).toFixed(2)
            ) || 0;
          this.$emit("dadosUltimaLeitura", leituraValor);
          return leituraValor.toFixed(2);
        } else {
          this.$emit(
            "dadosUltimaLeitura",
            this.leiturasDynamo.leitura + this.instrumento.cota_fundo || 0
          );
          return (
            (
              parseFloat(Number(this.nivelAtual)) +
              parseFloat(Number(this.instrumento.cota_fundo))
            ).toFixed(2) || 0
          );
        }
      }
    },
    leituraValorPercentage() {
      if (this.NivelCota) {
        const highest =
          this.instrumento.cota_topo - this.instrumento.cota_fundo ||
          this.leituraValor;
        return Math.max((this.leituraValor / highest) * 100, 1);
      } else {
        const highest =
          this.instrumento.cota_topo - this.instrumento.cota_fundo ||
          this.nivelAtual;
        return Math.max((this.nivelAtual / highest) * 100, 1);
      }
    },
    thresholds() {
      const ts = [];
      const padding = 5;

      const proportion = (x, y) => (!x ? 0 : x / (y || x));
      const highest = this.instrumento.cota_topo - this.instrumento.cota_fundo;

      if (this.instrumento.nivel_emergencia) {
        const p = proportion(
          this.instrumento.nivel_emergencia - this.instrumento.cota_fundo,
          highest
        );
        ts.push({
          classes: "instrumento-bar-chart__line--emergencia",
          cssVars: {
            "--position-from-top": `${(1 - p) * 100 + padding}%`,
          },
        });
      }

      if (this.instrumento.nivel_alerta) {
        const p = proportion(
          this.instrumento.nivel_alerta - this.instrumento.cota_fundo,
          highest
        );
        ts.push({
          classes: "instrumento-bar-chart__line--alerta",
          cssVars: {
            "--position-from-top": `calc(${(1 - p) * 100 + padding}% + 2px)`,
          },
        });
      }

      if (this.instrumento.nivel_atencao) {
        const p = proportion(
          this.instrumento.nivel_atencao - this.instrumento.cota_fundo,
          highest
        );
        ts.push({
          classes: "instrumento-bar-chart__line--atencao",
          cssVars: {
            "--position-from-top": `calc(${(1 - p) * 100 + padding}% + 4px)`,
          },
        });
      }

      return ts;
    },
    fillLabelClasses() {
      const textClass =
        this.leituraValorPercentage > 48.5
          ? "white--text"
          : "grey--text text--darken-4";

      return `instrumento-bar-chart__value ${textClass}`;
    },
    fillClasses() {
      var colorClass = "instrumento-bar-chart__fill";
      let leituraFillClasses = this.NivelCota
        ? this.leituraValor
        : this.nivelAtual;
      if (
        leituraFillClasses >
        (this.instrumento.nivel_emergencia - this.instrumento.cota_fundo || 0)
      ) {
        colorClass = `${colorClass}--emergencia`;
      } else if (
        leituraFillClasses >
        (this.instrumento.nivel_alerta - this.instrumento.cota_fundo || 0)
      ) {
        colorClass = `${colorClass}--alerta`;
      } else if (
        leituraFillClasses >
        (this.instrumento.nivel_atencao - this.instrumento.cota_fundo || 0)
      ) {
        colorClass = `${colorClass}--atencao`;
      } else {
        colorClass = `${colorClass}--normal`;
      }

      return `instrumento-bar-chart__fill transition-swing ${colorClass}`;
    },
    labelClasses() {
      var colorClass = "instrumento-bar-chart__label";

      if (this.alarmesAtuados === "Normal") {
        return `instrumento-bar-chart__label --normal`;
      }

      if (
        Array.isArray(this.alarmesAtuados) &&
        this.alarmesAtuados.length > 0
      ) {
        let items = this.alarmesAtuados?.find(
          (i) => i.nome === this.instrumento.nome
        );
        if (items.alarmes === null) {
          return `instrumento-bar-chart__label --normal`;
        }
        if (Array.isArray(items?.alarmes) && items.alarmes.length > 0) {
          let falhaComunicacao = items.alarmes.find(
            (i) => i === "Falha de comunicação"
          );
          if (!falhaComunicacao) {
            colorClass = `${colorClass}--alertaLaranja`;
          } else {
            colorClass = `${colorClass}--falhaComunicacao`;
          }
        } else {
          colorClass = `${colorClass}--normal`;
        }
      } else {
        colorClass = `${colorClass}--normal`;
      }

      return `instrumento-bar-chart__label ${colorClass}`;
    },
    labelCssVars() {
      return { "--fill-height": `${this.leituraValorPercentage}%` };
    },
    fillCssVars() {
      return {
        "--fill-height": `${this.leituraValorPercentage}%`,
      };
    },
    cardCssVars() {
      return {
        "--margin-bottom": `calc(2rem * ${this.positionY}%)`,
      };
    },
  },
  methods: {
    async getDados(instrumento) {
      if (instrumento.auto_manual) {
        this.nivelAtual = this.tipoDados(
          instrumento.auto_canal_dl,
          instrumento
        ).toFixed(2);
        this.dataAtual = instrumento.leituras.Timestamps.S;
      } else {
        this.nivelAtual = (
          this.instrumento.cota_topo -
          this.instrumento.cota_fundo -
          instrumento.leituras.leitura
        ).toFixed(2);
        this.dataAtual = instrumento.leituras.data_leitura;
      }
    },
    tipoDados(req, data) {
      if (req === "I0") {
        return data.leituras.I0.S * data.auto_consta + data.auto_constb;
      } else if (req === "I1") {
        return data.leituras.I1.S * data.auto_consta + data.auto_constb;
      } else if (req === "V1") {
        return data.leituras?.["0-1V"].S * data.auto_consta + data.auto_constb;
      } else if (req === "V10") {
        return (
          parseFloat(data.leituras?.["0-10V"].S) * data.auto_consta +
          data.auto_constb
        );
      } else if (
        req.startsWith("engineeringValue") ||
        req.includes("engineeringValue")
      ) {
        return parseFloat(
          data.leituras?.[req].S * data.auto_consta + data.auto_constb
        );
      } else {
        try {
          return parseFloat(data.leituras?.[req].S);
        } catch (e) {
          console.error(
            `Sem dados para esse tipo de Canal ${this.instrumento.auto_canal_dl}`
          );
        }
      }
    },
    toggleSwitch() {
      // Method to toggle the switch state
      this.NivelCota = !this.NivelCota;
    },
    async cadam_cliente() {
      let cliente_id = localStorage.getItem("cliente_uuid");
      if (cliente_id === "b893fdc8-3584-411f-ab46-56f99698f88e") {
        this.NivelCota = false;
      } else {
        this.NivelCota = true;
      }
    },
  },
};
</script>
<template>
  <v-card
    :style="cardCssVars"
    class="instrumento-bar-chart__card text-center d-inline-block px-2"
    flat
  >
    <span :class="labelClasses" v-text="instrumento.nome"></span>
    <div
      class="instrumento-bar-chart__background mx-auto rounded-lg grey lighten-5 my-10 transition-swing"
      :class="hover ? 'elevation-6' : 'elevation-1'"
    >
      <div :style="fillCssVars" :class="fillClasses"></div>
      <div
        v-for="(t, i) in thresholds"
        :key="`t-${i}`"
        :class="t.classes"
        :style="t.cssVars"
        class="instrumento-bar-chart__line"
      ></div>
      <span :class="fillLabelClasses" v-text="leituraValor"></span>
    </div>
    <small
      v-if="leituraValor == 0"
      class="instrumento-bar-chart__label text--disabled font-italic"
    >
      Seco
    </small>
    <div v-else>
      <div class="instrumento-bar-chart__label" v-text="dataLeitura"></div>
      <div class="instrumento-bar-chart__label" v-text="horaLeitura"></div>
    </div>
  </v-card>
  <!-- eslint-enable prettier/prettier -->
</template>
<style lang="scss">
.instrumento-bar-chart__card {
  margin-bottom: var(--margin-bottom);
}
.instrumento-bar-chart__background {
  position: relative;
  overflow: hidden;
  width: 5rem;
  height: 20rem;
}

.instrumento-bar-chart__label {
  border: 0;
  border-radius: 0 !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
  font-size: 0.75em;
  line-height: 1.5;
  padding: 5%;

  &.text--disabled {
    line-height: 3;
  }
  &--normal {
    background-color: none;
  }
  &--atencao {
    background-color: #ffcb47;
  }
  &--alerta {
    background-color: #fdaa63;
  }
  &--emergencia {
    background-color: #f83f3f;
  }
  &--falhaComunicacao {
    background-color: #eb57f4;
  }
  &--alertaLaranja {
    background-color: #f67e3a;
  }
}

.instrumento-bar-chart__line,
.instrumento-bar-chart__fill,
.instrumento-bar-chart__value {
  width: 100%;
  position: absolute;
}

.instrumento-bar-chart__line {
  height: 2px;
  top: var(--position-from-top);

  &--atencao {
    background-color: #ffeab3;
  }
  &--alerta {
    background-color: #fcc89b;
  }
  &--emergencia {
    background-color: #ff6b6b;
  }
}

.instrumento-bar-chart__fill {
  height: var(--fill-height);
  bottom: 0;

  &--normal {
    background-color: #20df6c;
  }
  &--atencao {
    background-color: #ffcb47;
  }
  &--alerta {
    background-color: #fdaa63;
  }
  &--emergencia {
    background-color: #f83f3f;
  }
}

.instrumento-bar-chart__value {
  top: 50%;
  bottom: 50%;
  left: 0;
  right: 0;
  font-weight: bold;
  font-size: 0.75em;
  line-height: 1.5;
}
</style>
