<template>
  <div>
    <v-text-field
      label="Cota de topo *"
      v-model="payload.cota_topo"
      :rules="requiredField"
      placeholder="Digite a cota de topo"
      outlined
      required
      class="border--gray"
      type="number"
      @blur="payloadToParent"
      suffix="m"
    />
    <v-text-field
      label="Cota de fundo *"
      v-model="payload.cota_fundo"
      :rules="requiredField"
      placeholder="Digite a cota de fundo"
      outlined
      required
      class="border--gray"
      type="number"
      @blur="payloadToParent"
      suffix="m"
    />
    <v-text-field
      label="Diâmetro do tubo"
      v-model="payload.diametro_tubo"
      placeholder="Digite o diâmetro do tubo"
      outlined
      class="border--gray"
      type="number"
      @blur="payloadToParent"
      suffix="mm"
    />
    <v-text-field
      label="Cota de atenção"
      v-model="payload.nivel_atencao"
      placeholder="Digite a cota de atenção"
      outlined
      class="border--gray"
      type="number"
      @blur="payloadToParent"
      suffix="m"
    />
    <v-text-field
      label="Cota de alerta"
      v-model="payload.nivel_alerta"
      placeholder="Digite a cota de alerta"
      outlined
      class="border--gray"
      type="number"
      @blur="payloadToParent"
      suffix="m"
    />
    <v-text-field
      label="Cota de emergência"
      v-model="payload.nivel_emergencia"
      placeholder="Digite a cota de emergência"
      outlined
      class="border--gray"
      type="number"
      @blur="payloadToParent"
      suffix="m"
    />
  </div>
</template>

<script>
export default {
  name: "Piezometro",
  props: {
    form: {
      default: () => {},
      type: Object,
    },
    tipoAcao: {
      default: "Adicionar",
      type: String,
    },
  },
  data: () => ({
    originalPayload: {},
    payload: {},
    requiredField: [(v) => !!v || "Campo obrigatório"],
  }),
  mounted() {
    this.atualizaCampos();
  },
  computed: {},
  methods: {
    atualizaCampos() {
      this.payload = { ...this.form };
      this.originalPayload = { ...this.form };
    },
    getItemSelecionado(item) {
      return item;
    },
    payloadToParent() {
      this.$emit("fieldsPayload", this.payload);
    },
  },
};
</script>

<style scoped>
.v-text-field {
  border-radius: 10px;
}
</style>
