<script>
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  TimeScale,
  TimeSeriesScale,
  Title,
  Tooltip,
} from "chart.js";
import moment from "moment";
import { Bar as ChartBar, Line as ChartLine } from "vue-chartjs/legacy";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  TimeScale,
  TimeSeriesScale
);

const datasetPluviometro = {
  label: "Pluviômetro",
  backgroundColor: "#69C8FF",
  type: "bar",
  yAxisID: "pluviometroScale",
};
const datasetPluviometroAutomatico = {
  label: "Pluviômetro Automático",
  borderColor: "#0b43e9",
  backgroundColor: "#0B99E9",
  type: "bar",
  yAxisID: "pluviometroScale",
};

const datasetEmergencia = {
  label: "Cota de Emergência",
  borderColor: "#F83F3F",
  backgroundColor: "#FF6B6B",
};

const datasetAtencao = {
  label: "Cota de atenção",
  borderColor: "#FDAA63",
  backgroundColor: "#FCC89B",
};

const datasetAlerta = {
  label: "Cota de alerta",
  borderColor: "#FFCB47",
  backgroundColor: "#FFEAB3",
};

const datasetNivelAgua = {
  label: "Cota d'água (manual)",
  borderColor: "#0B99E9",
  backgroundColor: "#69C8FF",
  fill: true,
};

const datasetNivelAguaAutomatico = {
  label: "Cota d'água (automático)",
  borderColor: "#0b43e9",
  backgroundColor: "#0B99E9",
  fill: true,
};

const datasetCotaReservatorioAutomatico = {
  label: "Cota Reservatório automático",
  borderColor: "#0b43e9",
  backgroundColor: "#0B99E9",
  fill: true,
};

const datasetCotaReservatorio = {
  label: "Cota do reservatório",
  borderColor: "#000",
  backgroundColor: "#fff",
  fill: true,
};
const datasetCotaAlarme = {
  label: "Cota alarme",
  borderColor: "#90ee90",
  backgroundColor: "#bcf5bc",
  fill: true,
};
const datasetInspecoes = {
  label: "Inspeções",
  borderColor: "#FFA500",
  backgroundColor: "#FFA500",
  fill: true,
  spanGaps: false,
  pointStyle: "rect",
  showLine: false,
  pointRadius: 6,
};

const any = (vals) => (v) => vals.includes(v);

// eslint-disable-next-line no-unused-vars
function mean(leiturasValues) {
  if (leiturasValues.length === 0) return null;

  const sum = leiturasValues.reduce((sum, l) => Number(sum) + Number(l), 0);
  const qty = leiturasValues.length;

  return (sum / qty).toFixed(6);
}

function add(leiturasValues) {
  if (leiturasValues.length === 0) return null;

  const sum = leiturasValues.reduce((sum, l) => Number(sum) + Number(l), 0);

  return sum.toFixed(2);
}

function no_aggr(leiturasValues) {
  if (leiturasValues.length === 0) return null;
  // Return the values as they are, without any aggregation
  return leiturasValues.map((v) => Number(v).toFixed(2));
}

// eslint-disable-next-line no-unused-vars
function max(leiturasValues) {
  if (leiturasValues.length === 0) return null;

  let max = -Infinity;
  leiturasValues.forEach((l) => (max = max < l ? l : max));

  return max;
}

// eslint-disable-next-line no-unused-vars
function closestIndex(date, dates) {
  let index = 0;
  let diff = -Infinity;

  for (const i in dates) {
    const p = dates[i];

    if (p.diff(date) < 0) {
      diff = p.diff(date);
      index = i;
    } else {
      if (Math.abs(diff) > p.diff(date)) {
        return i;
      } else {
        return index;
      }
    }
  }

  return index;
}

// eslint-disable-next-line no-unused-vars
function smallestIndex(date, dates) {
  let index = 0;

  for (const i in dates) {
    const p = dates[i];

    if (p.diff(date) <= 0) {
      index = i;
    } else {
      return index;
    }
  }

  return index;
}

export default {
  name: "LeituraChart",
  components: { ChartLine, ChartBar },
  props: {
    chartId: { type: String, required: true },
    instrumento: { type: Object, required: true },
    pluviometro: { type: Object, required: false },
    leiturasPluviometroAutomaticos: { type: Array, required: false },
    leituras: { type: Array, required: true },
    datas: { type: Array, required: true },
    intervalo: {
      type: String,
      required: false,
      default: "day",
      validator: any(["day", "week", "month", "none"]),
    },
    width: { type: [String, Number], default: "600" },
    inspecoes: { type: Array, required: false },
  },
  created() {
    ChartJS.register(BarController);
  },
  mounted() {},
  computed: {
    isPiezometro() {
      return this.instrumento.tipo_instrumento === "inst_piezometro";
    },
    isPluviometro() {
      return this.instrumento.tipo_instrumento === "inst_pluviometro";
    },
    isIndicadorNivelAgua() {
      return this.instrumento.tipo_instrumento === "inst_outros";
    },
    isReguaReservatorio() {
      return this.instrumento.tipo_instrumento === "inst_regua_reservatorio";
    },

    selectedPluviometro() {
      this.isPluviometroTrue;
      if (!this.pluviometro) return null;
      if (!this.isPiezometro && !this.isIndicadorNivelAgua) return null;
      let leiturasManuais = this.pluviometro.leituras;

      const leiturasValuesAuto =
        this.intervalo === "none"
          ? this.groupLeiturasInPeriodosPluviometro(
              this.leiturasPluviometroAutomaticos,
              no_aggr
            ) || []
          : this.groupLeiturasInPeriodosPluviometro(
              this.leiturasPluviometroAutomaticos,
              add
            ) || [];
      let leituraManuaisData;
      leituraManuaisData = leiturasManuais.map((v) =>
        moment(v.data_leitura).format("YYYY-MM-DD")
      );

      let result = leituraManuaisData.filter((v) => this.labels.includes(v));

      if (result) {
        leiturasManuais = leiturasManuais.filter((v) =>
          result.includes(moment(v.data_leitura).format("YYYY-MM-DD"))
        );
      } else {
        leiturasManuais = [];
      }
      return {
        label: this.pluviometro.nome,
        data:
          this.intervalo === "none"
            ? [
                this.groupLeiturasInPeriodosPluviometro(
                  leiturasManuais,
                  no_aggr
                ),
                leiturasValuesAuto,
              ]
            : [
                this.groupLeiturasInPeriodosPluviometro(leiturasManuais, add),
                leiturasValuesAuto,
              ],
      };
    },

    yAxisOptions() {
      let options = {
        title: {
          display: true,
          text: this.isPluviometro
            ? "Precipitação (mm)"
            : this.instrumento?.subtipo_instrumento
            ? `${this.instrumento?.subtipo_instrumento} (${this.instrumento?.unidade_eng})`
            : "Cota (m)",
          padding: {
            top: 10,
            bottom: 30,
          },
        },
      };

      let cliente_id = localStorage.getItem("cliente_uuid");
      if (cliente_id === "b893fdc8-3584-411f-ab46-56f99698f88e") {
        const delta_cota =
          (this.instrumento.cota_topo -
            (this.instrumento.cota_base || this.instrumento.cota_fundo)) *
          0.15;

        if (
          this.instrumento.cota_base !== undefined ||
          this.instrumento.cota_fundo !== undefined
        ) {
          options.min =
            this.instrumento.cota_base - delta_cota ||
            this.instrumento.cota_fundo - delta_cota;
        }

        if (this.instrumento.cota_topo !== undefined) {
          options.max = this.instrumento.cota_topo + delta_cota;
        }
      }

      return options;
    },

    chartOptions() {
      return {
        responsive: false,
        elements: {
          line: {
            tension: 0.1,
            spanGaps: true,
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: function (context) {
                if (context.dataset.label !== "Inspeções") {
                  return context.dataset.label + ": " + context.formattedValue;
                } else {
                  return context.dataset.text[context.dataIndex];
                }
              },
            },
          },
        },
        scales: {
          y: this.yAxisOptions,
          //   // eslint-disable-next-line
          //   min: 1.15 * this.instrumento.cota_base - 0.15 * this.instrumento.cota_topo || 1.15 * this.instrumento.cota_fundo  - 0.15 * this.instrumento.cota_fundo,
          //   max: 1.15 * this.instrumento.cota_topo - 0.15 * (this.instrumento.cota_base || this.instrumento.cota_fundo),
          //   ...(this.isPluviometro
          //     ? {
          //         title: {
          //           display: true,
          //           text: "Precipitação (mm)",
          //           padding: {
          //             top: 10,
          //             bottom: 30,
          //           },
          //         },
          //       }
          //     : {
          //         title: {
          //           display: true,
          //           text: this.instrumento?.subtipo_instrumento
          //             ? `${this.instrumento?.subtipo_instrumento} (${this.instrumento?.unidade_eng})`
          //             : "Cota (m)",
          //           padding: {
          //             top: 10,
          //             bottom: 30,
          //           },
          //         },
          //       }),
          // },
          ...(this.selectedPluviometro
            ? {
                pluviometroScale: {
                  position: "right",
                  title: {
                    display: true,
                    text: "Precipitação (mm)",
                    padding: {
                      top: 10,
                      bottom: 30,
                    },
                  },
                },
              }
            : {}),
        },
      };
    },

    chartPlugins() {
      return [
        {
          legend: {
            display: true,
            position: "bottom",
          },
        },
      ];
    },
    chartStyles() {
      return {};
    },
    chartData() {
      return {
        datasets: this.datasets,
        labels: this.labels,
      };
    },
    labels() {
      // return this.periodos.map((p) => p.format("YYYY-MM-DD"));
      if (this.intervalo === "none") {
        // Directly use the timestamps from the data points when "none" is selected
        return this.periodos.map((p) => p.format("YYYY-MM-DD HH:mm:ss"));
      } else {
        // For other intervals, use the periodos
        return this.periodos.map((p) => p.format("YYYY-MM-DD"));
      }
    },

    datasets() {
      const emergencia = new Array(this.periodos.length);
      const atencao = new Array(this.periodos.length);
      const alerta = new Array(this.periodos.length);
      const alarme = new Array(this.periodos.length);
      let inspecoes = [];
      let text = [];

      const lastIndex = this.periodos.length - 1;
      emergencia.splice(
        0,
        0,
        this.instrumento?.tipo_instrumento_value
          ? this.instrumento?.p1
          : this.instrumento.nivel_emergencia
      );
      emergencia.splice(
        lastIndex,
        0,
        this.instrumento?.tipo_instrumento_value
          ? this.instrumento?.p1
          : this.instrumento.nivel_emergencia
      );

      atencao.splice(
        0,
        0,
        this.instrumento?.tipo_instrumento_value
          ? this.instrumento?.p2
          : this.instrumento.nivel_atencao
      );
      atencao.splice(
        lastIndex,
        0,
        this.instrumento?.tipo_instrumento_value
          ? this.instrumento?.p2
          : this.instrumento.nivel_atencao
      );

      alerta.splice(0, 0, this.instrumento.nivel_alerta);
      alerta.splice(lastIndex, 0, this.instrumento.nivel_alerta);

      alarme.splice(0, 0, this.instrumento.cota_alarme);
      alarme.splice(lastIndex, 0, this.instrumento.cota_alarme);

      let leiturasAuto = this.leituras[0];
      let leiturasManuais = this.leituras[1];

      let leiturasValuesAuto = [];
      if (this.intervalo === "none") {
        leiturasValuesAuto = this.isPluviometro
          ? this.groupLeiturasInPeriodosPluviometro(leiturasAuto, no_aggr) || []
          : this.groupLeiturasInPeriodos(leiturasAuto, no_aggr) || [];
      } else {
        leiturasValuesAuto = this.isPluviometro
          ? this.groupLeiturasInPeriodosPluviometro(leiturasAuto, add) || []
          : this.groupLeiturasInPeriodos(leiturasAuto, mean) || [];
      }

      let leiturasValuesManual = [];
      if (this.intervalo === "none") {
        // leiturasValuesManual = this.isPluviometro
        //   ? this.groupLeiturasInPeriodosPluviometro(leiturasManuais, no_agg)
        //   : this.groupLeiturasInPeriodos(leiturasManuais, no_aggr);
      } else {
        leiturasValuesManual = this.isPluviometro
          ? this.groupLeiturasInPeriodosPluviometro(leiturasManuais, add)
          : this.groupLeiturasInPeriodos(leiturasManuais, mean);
      }

      // const leiturasValuesAuto = this.isPluviometro
      //   ? this.groupLeiturasInPeriodosPluviometro(leiturasValuesAuto, add) || []
      //   : this.groupLeiturasInPeriodos(leiturasValuesAuto, mean) || [];

      // const leiturasValuesManual = this.isPluviometro
      //   ? this.groupLeiturasInPeriodosPluviometro(leiturasManuais, add) || []
      //   : this.groupLeiturasInPeriodos(leiturasManuais, mean) || [];

      const isNivelAgua =
        this.isPluviometro || this.isPiezometro || this.isIndicadorNivelAgua;

      const hasThresholds =
        this.isNivelAgua || this.isPiezometro || this.isIndicadorNivelAgua;

      const valorFundo =
        this.instrumento.cota_base ||
        this.instrumento.cota_fundo ||
        this.instrumento.p2;
      const valorTopo = this.instrumento.cota_topo || this.instrumento.p1;
      const valorMedio = (valorFundo + valorTopo) / 2;

      this.inspecoes.map((item, i) => {
        if (item) {
          text.push(this.inspecoes[i].titulo);
          inspecoes.push(leiturasValuesAuto[i] ?? valorMedio);
        } else {
          text.push(null);
          inspecoes.push(null);
        }
      });

      let datasetLeiturasM = {};
      let datasetLeiturasA = {};
      let value = {};
      if (isNivelAgua) {
        if (this.instrumento.tipo_instrumento == "inst_pluviometro") {
          datasetNivelAgua.label = "Precipitação (manual)";
          datasetNivelAguaAutomatico.label = "Precipitação (automático)";
        } else {
          datasetNivelAgua.label = "Cota d'água (manual)";
          datasetNivelAguaAutomatico.label = "Cota d'água (automático)";
        }

        if (this.instrumento?.tipo_instrumento_value) {
          datasetNivelAgua.label = "Valor (manual)";
          datasetNivelAguaAutomatico.label = "Valor (automático)";
        }

        datasetLeiturasM = { ...datasetNivelAgua, data: leiturasValuesManual };
        datasetLeiturasA = {
          ...datasetNivelAguaAutomatico,
          data: leiturasValuesAuto,
        };
        value = {
          ...datasetInspecoes,
          data: inspecoes,
          text: text,
        };
      } else if (this.isReguaReservatorio) {
        datasetLeiturasM = {
          ...datasetCotaAlarme,
          data: alarme,
        };
        datasetLeiturasA = {
          ...datasetCotaReservatorioAutomatico,
          data: leiturasValuesAuto,
        };
        value = {
          ...datasetInspecoes,
          data: inspecoes,
          text: text,
        };
      }

      let cotas = [
        {
          ...datasetEmergencia,
          data: emergencia,
          label: this.instrumento?.tipo_instrumento_value
            ? "Alarme alto"
            : datasetEmergencia.label,
        },
        {
          ...datasetAtencao,
          data: atencao,
          label: this.instrumento?.tipo_instrumento_value
            ? "Alarme baixo"
            : datasetAtencao.label,
        },
      ];
      if (!this.instrumento?.tipo_instrumento_value) {
        cotas = [...cotas, { ...datasetAlerta, data: alerta }];
      }

      //plot no gráfico
      return [
        value,
        datasetLeiturasM,
        datasetLeiturasA,
        ...(hasThresholds ? cotas : []),
        ...(this.selectedPluviometro
          ? [
              { ...datasetPluviometro, data: this.selectedPluviometro.data[0] },
              {
                ...datasetPluviometroAutomatico,
                data: this.selectedPluviometro.data[1],
              },
            ]
          : []),
        ...(this.isReguaReservatorio
          ? [{ ...datasetCotaReservatorio, data: leiturasValuesManual }]
          : []),
      ];
    },

    periodos() {
      const dates = [];
      const end = moment(this.datas[1] + " 23:59:59", "YYYY-MM-DD HH:mm:ss");

      if (this.intervalo === "none") {
        // For "none", return the actual timestamps for each data point, date and time
        this.leituras[0].forEach((leitura) => {
          const ts = `${leitura.data_leitura} ${leitura.hora_leitura}`;
          dates.push(moment(ts, "YYYY-MM-DD HH:mm:ss").clone());
        });
        return dates;
      }

      for (
        const date = moment(this.datas[0] + " 00:00:00", "YYYY-MM-DD HH:mm:ss");
        date.isBefore(end);
        date.add(1, this.intervalo)
      ) {
        dates.push(date.clone());
      }
      if (this.datas[0] === this.datas[1] && dates.length == 1) {
        dates.push(
          moment(this.datas[0] + " 00:00:00", "YYYY-MM-DD HH:mm:ss").clone()
        );
        return dates;
      } else {
        // const last = dates.slice(-1)[0].clone().add(1, this.intervalo);
        // dates.push(last);
      }

      return dates;
    },
  },
  methods: {
    isPluviometroTrue() {
      return (this.instrumento.tipo_instrumento = "inst_pluviometro");
    },

    groupLeiturasInPeriodos(leituras, aggregate) {
      const leiturasValues = new Array(this.periodos.length);
      const grouped = leituras.reduce((byPeriodo, leitura) => {
        const idx = this.periodoIndexForLeitura(leitura);
        byPeriodo[idx] ||= [];
        if (this.instrumento?.tipo_instrumento_value) {
          byPeriodo[idx].push(leitura.leitura);
        } else {
          byPeriodo[idx].push(this.calculateValue(leitura));
        }

        return byPeriodo;
      }, Object.create(null));
      for (const i in this.periodos) {
        leiturasValues[i] = aggregate(grouped[i] || []);
      }

      return this.intervalo === "none"
        ? leiturasValues.flat().filter((value) => value !== null)
        : leiturasValues;
    },
    groupLeiturasInPeriodosPluviometro(leituras, aggregate) {
      const leiturasValues = new Array(this.periodos.length);
      if (leituras != null) {
        const grouped = leituras.reduce((byPeriodo, leitura) => {
          const idx = this.periodoIndexForLeitura(leitura);
          byPeriodo[idx] ||= [];
          byPeriodo[idx].push(this.calculateValuePluviometro(leitura));

          return byPeriodo;
        }, Object.create(null));

        for (const i in this.periodos) {
          leiturasValues[i] = aggregate(grouped[i] || []);
        }
      }

      return this.intervalo === "none"
        ? leiturasValues.flat().filter((value) => value !== null)
        : leiturasValues;
    },
    periodoIndexForLeitura(leitura) {
      const date = moment(leitura.data_leitura);
      return smallestIndex(date, this.periodos);
    },
    calculateValue(leitura) {
      switch (this.instrumento.tipo_instrumento) {
        case "inst_outros":
        case "inst_piezometro":
          // Verifica se possui leituras automaticas
          // Se for automatica a medição é do fundo
          if (leitura.pluviometros) {
            return (
              this.instrumento.cota_base ||
              this.instrumento.cota_fundo +
                (leitura.leitura * this.instrumento.auto_consta +
                  this.instrumento.auto_constb)
            );
          }
          // Se for manual a medição é do topo
          return this.instrumento.cota_topo - leitura.leitura;

        case "inst_regua_reservatorio":
          // Verifica se possui leituras automaticas
          // Se for automatica a medição é do fundo
          if (leitura.pluviometros) {
            return (
              this.instrumento.cota_base ||
              this.instrumento.cota_fundo + leitura.leitura
            );
          }
          // Se for manual a medição é do topo
          return this.instrumento.cota_topo - leitura.leitura;
        default:
          return leitura.leitura;
      }
    },
    calculateValuePluviometro(leitura) {
      return leitura.leitura;
    },
  },
};
</script>
<template>
  <ChartBar
    v-if="isPluviometro"
    ref="chart"
    :chart-id="chartId"
    :chart-options="chartOptions"
    :chart-data="chartData"
    :plugins="chartPlugins"
    :styles="chartStyles"
    :width="width"
  />

  <ChartLine
    v-else
    ref="chart"
    :chart-id="chartId"
    :chart-options="chartOptions"
    :chart-data="chartData"
    :plugins="chartPlugins"
    :styles="chartStyles"
    :width="width"
  />
</template>
