<template>
  <v-form @submit.prevent="openConfirmationDialog" ref="formLeitura">
    <v-row class="justify-content-center">
      <hr />
      <v-col md="6" offset-md="3">
        <v-select
          label="Selecione uma de suas estruturas *"
          :items="estruturas"
          v-model="payload.estrutura_id"
          item-text="text"
          item-value="value"
          outlined
          class="border--gray"
          @blur="geraListaInstrumento"
          :rules="requiredField"
          required
        />
        <v-select
          label="Selecione um de seus instrumentos *"
          :items="listaInstrumentos"
          v-model="payload.instrumento_id"
          :rules="requiredField"
          item-text="text"
          item-value="value"
          outlined
          required
          class="border--gray"
          @blur="atualizaNomeInstrumento"
        />
        <v-row>
          <v-col>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="payload.data_leitura"
                  :rules="requiredField"
                  required
                  label="Data da leitura *"
                  append-icon="mdi-calendar"
                  readonly
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  class="border--gray"
                  @click="setHoraAtual"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="payload.data_leitura"
                color="primary"
                @input="menu = false"
                show-adjacent-months
                no-title
                scrollable
                id="dateOverdue"
                :max="getToday()"
              >
                <!-- <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(payload.data_leitura)"
                >
                  OK
                </v-btn> -->
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col>
            <v-text-field
              @change="getHourNow"
              label="Hora da leitura *"
              v-model="payload.hora_leitura"
              :rules="requiredField"
              outlined
              required
              class="border--gray"
              type="time"
            />
            <!-- <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="payload.hora_leitura"
                  :rules="requiredField"
                  required
                  label="Hora da leitura *"
                  append-icon="mdi-clock-time-four-outline"
                  readonly
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  class="border--gray"
                ></v-text-field>
              </template>
              <v-time-picker
                v-model="payload.hora_leitura"
                color="primary"
                scrollable
                format="24hr"
                id="hourOverdue"
                :max="getHourNow()"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="setHoraAtual">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu1.save(payload.hora_leitura)"
                >
                  OK
                </v-btn>
              </v-time-picker>
            </v-menu> -->
          </v-col>
        </v-row>
        <v-text-field
          label="Leitura *"
          v-model="payload.leitura"
          :rules="requiredField"
          placeholder="Digite o valor lido"
          outlined
          required
          class="border--gray"
          type="number"
          :suffix="setUnidade()"
        />

        <!-- <v-text-field
          label="Responsável"
          v-model="payload.responsavel"
          placeholder="Digite o nome do responsável"
          outlined
          required
          class="border--gray d-none"
        /> -->
        <v-textarea label="Observação" v-model="payload.descricao" outlined />
        <v-btn block type="submit" class="pa-6 primary darken-2 my-6">
          {{ tipoAcao }} leitura
        </v-btn>
        <ConfirmationDialog
          v-model="confirmationDialog"
          :theme="confirmTheme"
          :title="confirmTitle"
          :confirm="confirmButton"
          @confirmed="submit"
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import ConfirmationDialog from "@/components/ConfirmationDialog.vue";
import ResourceConfirmationMixin from "@/mixins/ResourceConfirmationMixin.js";
// import { estados } from "@/helpers/utils/ufCidades";
import { AuthService } from "@/helpers/services";
import moment from "moment";

const authService = new AuthService();
const leituraAgora = () => ({
  data_leitura: moment(new Date()).format("YYYY-MM-DD"),
  hora_leitura: moment(new Date()).format("HH:mm"),
});

function newPayload(override = {}) {
  return {
    estrutura: "",
    estrutura_id: "",
    instrumento: "",
    instrumento_id: "",
    tipo_instrumento: "",
    data_leitura: "",
    hora_leitura: "",
    ...override,
  };
}

export default {
  name: "FormularioLeitura",
  components: { ConfirmationDialog },
  mixins: [ResourceConfirmationMixin],
  props: {
    form: {
      default: () => {},
      type: Object,
    },
    tipoAcao: {
      default: "Adicionar",
      type: String,
    },
  },
  data: () => ({
    estruturas: [],
    estruturaSelecionada: {},
    tipoInstrumentoSelecionado: {},
    nomeInstrumentoSelecionado: [],
    listaInstrumentos: [],
    originalPayload: {},
    menu: false,
    menu1: false,
    dateOverdue: "",
    hourOverdue: "",
    // estrutura: {
    //   value: "625c901d-5ec2-4788-ac70-27393563190b",
    //   text: "Estrutura Com todos os Campos",
    // },
    payload: newPayload(),

    requiredField: [(v) => !!v || "Campo obrigatório"],
  }),
  created() {
    this.buscaEstruturas();
    this.atualizaCampos();
    // this.atualizaEstruturaSelecionada();
  },
  computed: {
    confirmResource() {
      return "essa leitura";
    },
  },
  methods: {
    setUnidade() {
      let unidade = "m";

      if (this.payload.tipo_instrumento == "inst_pluviometro") {
        unidade = "mm";
      }
      return unidade;
    },
    setHoraAtual() {
      const agora = leituraAgora();

      this.payload.hora_leitura = agora.hora_leitura;
    },

    setDataAtual() {
      const agora = leituraAgora();

      this.payload.data_leitura = agora.data_leitura;
      this.payload.hora_leitura = agora.hora_leitura;
    },
    atualizaCampos() {
      if (this.tipoAcao === "Adicionar") this.setDataAtual();
      // this.atualizaEstruturaSelecionada(this.form);
      if (this.form?.id) {
        this.payload = {
          ...this.form,
          estrutura: this.form.estrutura,
          estrutura_id: this.form.estrutura_id,
          instrumento_id: this.form.instrumento_id,
          instrumento: this.form.instrumento,
          tipo_instrumento: this.form.tipo_instrumento,
          data_leitura: moment(this.form.data_leitura).format("YYYY-MM-DD"),
          hora_leitura: moment(this.form.data_leitura).format("HH:mm"),
        };

        this.originalPayload = { ...this.form };
        this.listaInstrumentos = [];

        this.listaInstrumentos.push({
          text: this.form.instrumento,
          nome: this.form.instrumento,
          value: this.form.instrumento_id,
        });
        this.listaInstrumentos.sort((a, b) => {
          const nomeA = a.text.toUpperCase(); // ignore upper and lowercase
          const nomeB = b.text.toUpperCase(); // ignore upper and lowercase
          if (nomeA < nomeB) {
            return -1;
          }
          if (nomeA > nomeB) {
            return 1;
          }

          // names must be equal
          return 0;
        });
      }
    },

    getToday() {
      return new Date().toISOString().split("T")[0];
    },

    getHourNow() {
      if (this.payload.data_leitura == new Date().toISOString().split("T")[0]) {
        if (this.payload.hora_leitura.split(":")[0] > new Date().getHours()) {
          // hora escolhida é maior que a hora atual(resetar para hora atual)
          return this.setHoraAtual();
        } else if (
          this.payload.hora_leitura.split(":")[0] == new Date().getHours() &&
          this.payload.hora_leitura.split(":")[1] > new Date().getMinutes()
        ) {
          // hora escolhida é igual porem os minutos são maiores(resetar para hora atual)
          return this.setHoraAtual();
        } else {
          // hora escolhida é menor que a atual
          return "";
        }
      } else {
        // data da leitura escolhida é menor do que a atual;
        return "";
      }
    },

    buscaEstruturas() {
      authService
        .getClientResume()
        .then(({ data }) => {
          let payload = data.data;
          for (let i in payload) {
            this.estruturas.push({
              ...payload[i],
              text: payload[i].nome,
              value: payload[i].estrutura_id,
              estrutura: {
                value: payload[i].estrutura_id,
                text: payload[i].nome,
              },
            });
          }
          this.estruturas.sort((a, b) => {
            const nomeA = a.text.toUpperCase(); // ignore upper and lowercase
            const nomeB = b.text.toUpperCase(); // ignore upper and lowercase
            if (nomeA < nomeB) {
              return -1;
            }
            if (nomeA > nomeB) {
              return 1;
            }

            // names must be equal
            return 0;
          });
          return payload;
        })
        .catch((err) => {
          console.error(err);
          return err;
        });
    },
    atualizaNomeInstrumento() {
      const result = this.listaInstrumentos.filter(
        (e) => e.value === this.payload.instrumento_id
      );

      const instrumento = result[0]?.instrumento;

      if (instrumento) this.payload = { ...this.payload, ...instrumento };
    },

    geraListaInstrumento() {
      if (this.estruturas && this.payload.estrutura_id) {
        this.listaInstrumentos = [];
        let [{ tipo_instrumento, estrutura }] = this.estruturas?.filter((e) => {
          return e.value === this.payload.estrutura_id;
        });
        // atualiza o nome da estrutura no payload
        this.payload.estrutura = estrutura.text;

        for (let i in tipo_instrumento) {
          let instrumento_lista = tipo_instrumento[i].instrumentos;

          for (let instrumento in instrumento_lista) {
            this.listaInstrumentos.push({
              text: instrumento_lista[instrumento].nome,
              name: instrumento_lista[instrumento].nome,
              value: instrumento_lista[instrumento].id,
              instrumento: {
                instrumento_id: instrumento_lista[instrumento].id,
                instrumento: instrumento_lista[instrumento].nome,
                tipo_instrumento:
                  instrumento_lista[instrumento].tipo_instrumento,
              },
            });
          }
          this.listaInstrumentos.sort((a, b) => {
            const nomeA = a.text.toUpperCase(); // ignore upper and lowercase
            const nomeB = b.text.toUpperCase(); // ignore upper and lowercase
            if (nomeA < nomeB) {
              return -1;
            }
            if (nomeA > nomeB) {
              return 1;
            }

            // names must be equal
            return 0;
          });
        }
      }
      return ["Selecione uma estrutura antes"];
    },
    submit() {
      let estruturas = this.estruturas;
      let estrutura_nome = "";
      for (let i in estruturas) {
        if (estruturas[i].uuid === this.payload.estrutura_id) {
          estrutura_nome = estruturas[i].text;
        }
      }

      let payload = {
        ...this.payload,
        usuario_id: localStorage.getItem("usuario_uuid"),
        cliente_id: localStorage.getItem("cliente_uuid"),
        [`${this.payload.tipo_instrumento + "_id"}`]:
          this.payload.instrumento_id,
        tipo_leitura: "na",
        data_leitura: new Date(
          this.payload.data_leitura + " " + this.payload.hora_leitura
        ),
      };
      switch (this.tipoAcao) {
        case "Adicionar":
          if (this.validate()) {
            this.$emit("enviaFormulario", {
              ...payload,
            });
          }
          break;
        case "Editar":
          this.$emit("enviaFormulario", {
            ...this.originalPayload,
            update: { ...this.payload, estrutura: estrutura_nome },
          });
          break;
      }
    },
    reset() {
      const override = this.tipoAcao === "Adicionar" ? leituraAgora() : {};
      const payload = newPayload(override);

      this.$refs.formLeitura.resetValidation();

      this.payload = payload;
    },
    validate() {
      return this.$refs.formLeitura.validate();
    },
  },
};
</script>

<style scoped>
.v-text-field {
  border-radius: 10px;
}
</style>
