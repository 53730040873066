<script>
import ConfirmationDialog from "@/components/ConfirmationDialog.vue";
import ResourceTableTitleRow from "@/components/ResourceTableTitleRow.vue";
import Vue from "vue";

const actionsNames = [
  "adicionar",
  "editar",
  "excluir",
  "historico",
  "download",
  "visualizar",
];
const historicoActionsNames = ["Adicionado", "Editado", "Excluido"];

export { historicoActionsNames };

export default {
  name: "ResourceTable",
  components: { ConfirmationDialog, ResourceTableTitleRow },
  props: {
    resourceName: String,
    actionName: {
      type: String,
      required: true,
      validator: (v) => actionsNames.includes(v),
    },
    tableTitle: String,
    items: Array,
    itemKey: String,
    headers: Array,
    confirmation: [Object, String],
    showSelect: {
      type: Boolean,
      default: () => true,
    },
  },
  data: (vm) => ({
    selectedItem: null,
    menu: false,
    fieldActivator: Object.fromEntries(vm.headers.map((h) => [h.value, null])),
    dialogOpened: false,
  }),
  computed: {
    dialog() {
      return typeof this.confirmation === "string"
        ? { confirm: this.confirmation }
        : this.confirmation;
    },
    buttonActionLabel() {
      return `${this.actionName} ${this.resourceName}`;
    },
    isHistorico() {
      return this.actionName === "historico";
    },
    actionIcon() {
      switch (this.actionName) {
        case "editar":
          return "mdi-pencil";
        case "excluir":
          return "mdi-delete";
        case "visualizar":
          return "mdi-magnify";
        default:
          return "mdi-plus";
      }
    },
    search() {
      const searches = this.filterableHeaders.searches;
      for (const k in Object.keys(searches)) {
        if (searches[k]) return searches[k];
      }

      return "";
    },
    headersProps() {
      return this.headersDetails.map((h) => h.props);
    },
    filterableHeaders() {
      const filterable = this.headersDetails.filter((h) => h.filter);

      const searches = Vue.observable(
        Object.fromEntries(filterable.map((_, i) => [i, ""]))
      );

      return { headers: filterable, searches };
    },
    headersDetails() {
      return this.headers.map((h) => {
        return {
          props: { ...h, filterable: !!h.filterable },
          filter: h.filterable
            ? { input: "text", label: h.text, ...h.filterable }
            : false,
        };
      });
    },
    hasDialogTitleSlot() {
      return !!this.$slots.dialogTitle;
    },
  },
  methods: {
    confirmed() {
      if (this.selectedItem) {
        this.$emit("activated:action", this.selectedItem);
      }
    },
    dispatchAction() {
      if (this.selectedItem) {
        if (this.dialog) {
          this.dialogOpened = true;
        } else {
          this.confirmed();
        }
      }
    },
    updateSelectedItem({ item }) {
      this.selectedItem = {};
      this.selectedItem = { ...item };
      this.$emit("selected:item", this.selectedItem);
    },
    chipColorForActionFromHistorico(actionNameDoHistorico) {
      switch (actionNameDoHistorico) {
        case "Adicionado":
          return "green accent-2";
        case "Editado":
          return "yellow lighten-2";
        case "Excluido":
          return "red accent-1";
      }
    },
    filterSubstring(value, search) {
      if (value && search) {
        if (typeof value === "string") {
          return (
            value.toLowerCase().indexOf(search.toString().toLowerCase()) >= 0
          );
        }
      }
    },
    filterEq(value, search) {
      if (value && search) {
        return value == search;
      }
    },
    withFilters(...filters) {
      return (value, search) => {
        if (value && search) {
          return filters.some((f) => f(value, search));
        }
      };
    },
  },
};
</script>

<template>
  <v-card tile flat>
    <ResourceTableTitleRow
      :tableTitle="tableTitle"
      :showAction="!isHistorico"
      :dispatchAction="dispatchAction"
      :actionLabel="buttonActionLabel"
      :hasSelection="!!selectedItem"
      :fabIcon="actionIcon"
    />
    <ConfirmationDialog
      v-if="dialog"
      v-model="dialogOpened"
      :theme="dialog.theme"
      :title="dialog.title"
      :text="dialog.text"
      :confirm="dialog.confirm"
      :cancel="dialog.cancel"
      @confirmed="confirmed"
    >
      <template v-if="hasDialogTitleSlot" v-slot:title>
        <slot name="dialogTitle"></slot>
      </template>
    </ConfirmationDialog>
    <v-data-table
      :headers="headersProps"
      :items="items"
      :search="search"
      :custom-filter="filterSubstring"
      :item-key="itemKey"
      :show-select="showSelect"
      checkbox-color="primary darken-2 border"
      @item-selected="updateSelectedItem"
      single-select
    >
      <template v-slot:top>
        <v-container>
          <v-row no-gutters>
            <v-col
              cols="12"
              sm="auto"
              class="flex-sm-grow-1 px-sm-2"
              v-for="(h, i) in filterableHeaders.headers"
              :key="i"
            >
              <v-select
                v-if="h.filter.input === 'select'"
                v-model="filterableHeaders.searches[i]"
                :items="h.filter.items"
                :full-width="$vuetify.breakpoint.mobile"
                :label="h.filter.label"
                clearable
                outlined
              />
              <v-text-field
                v-else-if="h.filter.input === 'text'"
                v-model="filterableHeaders.searches[i]"
                :full-width="$vuetify.breakpoint.mobile"
                :label="h.filter.label"
                outlined
              />
              <v-menu
                v-else-if="h.filter.input === 'date'"
                v-model="fieldActivator[h.props.value]"
                :close-on-content-click="false"
                transition="scale-transition"
                min-width="auto"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="filterableHeaders.searches[i]"
                    v-bind="attrs"
                    v-on="on"
                    :full-width="$vuetify.breakpoint.mobile"
                    :label="h.filter.label"
                    append-icon="mdi-calendar"
                    clearable
                    readonly
                    outlined
                  />
                </template>
                <v-date-picker
                  v-model="filterableHeaders.searches[i]"
                  @input="fieldActivator[h.props.value] = false"
                  color="primary"
                  show-adjacent-months
                  no-title
                  scrollable
                />
              </v-menu>
              <v-text-field
                v-else-if="h.filter.input === 'time:raw'"
                v-model="filterableHeaders.searches[i]"
                :full-width="$vuetify.breakpoint.mobile"
                :label="h.filter.label"
                type="time"
                outlined
              />
              <v-menu
                v-else-if="h.filter.input === 'time'"
                v-model="fieldActivator[h.props.value]"
                transition="scale-transition"
                min-width="auto"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="filterableHeaders.searches[i]"
                    v-bind="attrs"
                    v-on="on"
                    :full-width="$vuetify.breakpoint.mobile"
                    :label="h.filter.label"
                    append-icon="mdi-clock"
                    clearable
                    readonly
                    outlined
                  />
                </template>
                <v-time-picker
                  v-model="filterableHeaders.searches[i]"
                  format="24hr"
                  @input="fieldActivator[h.props.value] = false"
                />
              </v-menu>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:item.acao="{ item }">
        <v-chip :color="chipColorForActionFromHistorico(item.acao)">
          {{ item.acao }}
        </v-chip>
      </template>
    </v-data-table>
  </v-card>
</template>
