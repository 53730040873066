<script lang="js">
import HealthDetails from "@/components/HealthDetails.vue";

import LineChart from "@/components/charts/line/LineChart.vue";

import PieChart from "@/components/charts/pie/PieChart.vue";

import { AuthService } from "@/helpers/services";

import moment from "moment";

const authService = new AuthService();

const intervals = {
  day: { value: "day", text: "Dia", icon: "mdi-calendar-today" },
  week: { value: "week", text: "Semana", icon: "mdi-calendar-week" },
  month: { value: "month", text: "Mês", icon: "mdi-calendar-month" },
  none: { value: "none", text: "Sem Agrupamento", icon: "mdi-calendar-clock" },
};

export default {
  name: "GeneralChart",
  components: { HealthDetails, LineChart, PieChart },
  props: {
    estruturaId: { type: String, required: true },
    date: { type: Date, required: false, default: () => new Date() },
  },
  data: (vm) => ({
    tipoInstrumento: { value: "tela_saude" },
    instrumentoId: null,
    autoSnDl: null,
    baseDados: null,
    BATERIAData: [],
    TEMPERATURAData: [],
    UMIDADEData: [],
    CSQData: [],
    RSSIData: [],
    LQIData: [],
    datesRange: [
      moment(vm.date).add({ days: -30 }).format("YYYY-MM-DD HH:mm:ss"),
      moment(vm.date).format("YYYY-MM-DD HH:mm:ss"),
    ],
    dates: [
      moment(vm.date).add({ days: -30 }).format("YYYY-MM-DD"),
      moment(vm.date).format("YYYY-MM-DD"),
    ],
    intervalo: intervals.day,
    intervalos: [intervals.month, intervals.week, intervals.day, intervals.none],
    datesMenu: false,
    nivelAtual: null,
    nivelAlerta: null,
    nivelAtencao: null,
    nivelEmergencia: null,
    falhaConexao: false,
    instrumentoEscolhido: {},
    instrumentoEscolhidoVisualizado: {},
    alarmesList: [],
    dadosGerais: [],
    inspectionsList: [],
    pieChartValue: [],
    loading: true,
  }),

  async mounted() {
    moment.tz.setDefault("America/Sao_Paulo");

    this.getDados();
    const baseDados = await authService.getClientResume(this.estruturaId);
    this.baseDados = baseDados?.data?.data[0]?.baseDados;
    this.autoRefresh();
  },

  watch: {
    instrumentoEscolhido: function () {
      return (
        this.setInstrumentoId(this.instrumentoEscolhido),
        this.getDataToRssiLineChart(),
        this.getDataToCSQLineChart(),
        this.getDataToLQILineChart(),
        this.getDataToBATERIALineChart(),
        this.getDataToTEMPERATURALineChart(),
        this.getDataToUMIDADELineChart(),
        this.getInspecoesByDate(),
        this.renderPieChart()
      );
    },
    datesRange: function () {
      return (
        this.setInstrumentoId(this.instrumentoEscolhido),
        this.getDataToRssiLineChart(),
        this.getDataToCSQLineChart(),
        this.getDataToLQILineChart(),
        this.getDataToBATERIALineChart(),
        this.getDataToTEMPERATURALineChart(),
        this.getDataToUMIDADELineChart(),
        this.getInspecoesByDate()
      );
    },

    intervalo: function () {
      return (
        this.getDataToRssiLineChart(),
        this.getDataToCSQLineChart(),
        this.getDataToLQILineChart(),
        this.getDataToBATERIALineChart(),
        this.getDataToTEMPERATURALineChart(),
        this.getDataToUMIDADELineChart()
      );
    },
  },
  computed: {
    instrumentos() {
      return this.dadosGerais;
    },
    instrumentoLeituras() {
      const leiturasAutomaticasDynamo = this.leiturasDynamo || [];
      const leiturasManuaisPostGres =
        (this.instrumento && this.instrumento.leituras) || [];

      const leituras = [leiturasAutomaticasDynamo, leiturasManuaisPostGres];

      const dataInicio = this.datesRange[0]
        ? moment(this.datesRange[0]).startOf("day")
        : null;

      const dataFim = this.datesRange[1]
        ? moment(this.datesRange[1]).endOf("day")
        : null;

      return leituras.filter((l) => {
        const dataLeitura = l.data_leitura ? moment(l.data_leitura) : null;

        return (
          dataLeitura === null ||
          ((dataInicio === null || dataInicio.isSameOrBefore(dataLeitura)) &&
            (dataFim === null || dataFim.isSameOrAfter(dataLeitura)))
        );
      });
    },
    instrumento_automatizado() {
      return this.autoSnDl !== null;
    },

    datesText() {
      const today = moment().format("YYYY-MM-DD");
      const dates = [...this.dates].sort();

      if (dates[1] === today) {
        const inicio = moment(dates[0]);
        const fim = moment(dates[1]);
        const dias = fim.diff(inicio, "days");
        if (dias === 0) {
          return "Hoje";
        } else if (dias === 1) {
          return "Desde ontem";
        } else if (dias > 0) {
          return `Últimos ${dias} dias`;
        }
      }
      return dates.join(" ~ ");
    },
    instrumento() {
      return this.dadosGerais?.find((i) => i.id === this.instrumentoId);
    },
    piechartData() {
      return [
        {
          backgroundColor: ["#41B883", "#E46651", "#808080"],
          data: this.pieChartValue,
        },
      ];
    },
  },
  methods: {
    renderPieChart() {
      if (this.alarmesList.length > 0) {
        const resposta = this.alarmesList.filter(
          (items) => items.nome === this.instrumentoEscolhido.nome
        );
        const item = resposta[0].items?.pie_chart ?? [0, 0, 0];
        this.pieChartValue = item;
      } else {
        this.pieChartValue = [0, 0, 0];
      }
    },
    async getDados() {
      let { data } = await authService.getVisaoGeral({
        estrutura_id: this.estruturaId,
      });
      this.dadosGerais = data?.data;
      let instrumentosFiltrados = this.dadosGerais?.sort((a, b) => {
        const nomeA = a.nome.toUpperCase(); // ignore upper and lowercase
        const nomeB = b.nome.toUpperCase(); // ignore upper and lowercase
        if (nomeA < nomeB) {
          return -1;
        }
        if (nomeA > nomeB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
      this.instrumentoEscolhido = instrumentosFiltrados[0];
      this.setInstrumentoId(this.instrumentoEscolhido);
      await this.alarmeList();
      this.loading = false;
      await this.renderPieChart();
    },
    async setInstrumentoId(piezometro) {
      await this.alarmeList();
      let diff = null;
      if (piezometro.auto_manual) {
        this.nivelAtual = this.tipoDados(
          piezometro.auto_canal_dl,
          piezometro
        ).toFixed(2);
        this.dataAtual = piezometro.leituras.Timestamps.S;
        diff = moment().diff(moment(this.dataAtual));
        if (diff >= 14400000) {
          this.falhaConexao = true;
        } else {
          this.falhaConexao = false;
        }
      } else {
        this.nivelAtual =
          piezometro.cota_topo -
          piezometro.cota_fundo -
          piezometro.leituras.leitura;
        this.dataAtual = piezometro.leituras.data_leitura;
        this.falhaComunicacao = false;
      }

      const listaAlarme = this.alarmesList.filter(
        (item) => item.nome === piezometro.nome
      );

      let statusAlarme = false;
      if (listaAlarme[0]?.alarmes?.length > 0) {
        statusAlarme = true;
      } else {
        statusAlarme = false;
      }

      const body = {
        ...piezometro,
        name: piezometro.nome,
        nivel: this.nivelAtual || "-",
        status: statusAlarme ? "Alarme" : "Normal",
        temperatura: piezometro.leituras.Temp?.S || null,
        umidade: piezometro.leituras.Umid?.S || null,
        rssi: piezometro.leituras.RSSI?.S || null,
        bateria: piezometro.leituras.Bat?.S || null,
        alarmesAtuados: listaAlarme[0]?.alarmes || [],
        perdaPacotes: listaAlarme[0]?.items?.perda_pacotes || "",
      };
      this.instrumentoEscolhidoVisualizado = body;
      await this.renderPieChart();
      return body;
    },
    tipoDados(req, data) {
      if (req === "I0") {
        return data.leituras?.I0.S * data.auto_consta + data.auto_constb;
      } else if (req === "I1") {
        return data.leituras?.I1.S * data.auto_consta + data.auto_constb;
      } else if (req === "V1") {
        return data.leituras?.["0-1V"].S * data.auto_consta + data.auto_constb;
      } else if (req === "V10") {
        return (
          parseFloat(data.leituras?.["0-10V"].S) * data.auto_consta +
          data.auto_constb
        );
      } else if (req === "INT0") {
        return (
          parseFloat(data.leituras?.INT0?.S ?? 0) * data.auto_consta +
          data.auto_constb
        );
      } else if (req === "INT1") {
        return (
          parseFloat(data.leituras?.INT1?.S ?? 0) * data.auto_consta +
          data.auto_constb
        );
      } else if (req?.startsWith("engineeringValue") || req?.includes("engineeringValue")) {
        return parseFloat(
          data.leituras?.[req].S * data.auto_consta +
            data.auto_constb
        );
      } else {
        // console.error(
        // `Sem dados para esse tipo de Canal ${this.instrumentoEscolhido.auto_canal_dl}`
        // );
        // return 0;
        try {
          return parseFloat(data.leituras?.[req].S);
        } catch (e) {
          console.error(
            `Sem dados para esse tipo de Canal ${this.instrumentoEscolhido.auto_canal_dl}`
          );
          return 0;
        }
      }
    },
    autoRefresh() {
      return setInterval(() => {
        this.getDataToBATERIALineChart();
        this.getDataToTEMPERATURALineChart();
        this.getDataToUMIDADELineChart();
        this.getDataToCSQLineChart();
        this.getDataToRssiLineChart();
        this.getDataToLQILineChart();
        this.instrumentoLeituras;
        this.chartBateria += 1;
        this.chartTemp += 1;
        this.chartUmidade += 1;
        this.chartCSQ += 1;
        this.chartRSSI += 1;
        this.chartLQI += 1;
      }, 600000); // 10 minutos
    },
    setDatesRange(val) {
      if (val.length > 1) {
        this.datesRange = [...val].sort();
        if (
          moment().format("YYYY-MM-DD") ===
          moment(this.datesRange[1]).format("YYYY-MM-DD")
        ) {
          this.datesRange[1] = moment().format("YYYY-MM-DD HH:mm:ss");
        } else {
          this.datesRange[1] = moment(this.datesRange[1])
            .endOf("day")
            .format("YYYY-MM-DD HH:mm:ss");
        }
        this.datesRange[0] = moment(this.datesRange[0])
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
      }
    },
    async alarmeList() {
      let array = [];
      await Promise.all(
        this.instrumentos.map((item) => {
          array.push({
            sn: item.auto_sn_dl,
            name: item.nome,
          });
        })
      );

      const alarmes = await authService.resumoAlarmeDynamo({
        tableName: this.baseDados,
        estruturaId: this.estruturaId,
        dataIni: this.datesRange[0],
        dataFim: this.datesRange[1],
        body: array,
      });
      this.alarmesList = alarmes?.data?.data;
    },

    async getDataToBATERIALineChart() {
      try {
        let BATERIADados = await authService.getLeituraDynamoPorGrafico({
          tableName: this.baseDados,
          chartName: "Bat",
          sn: this.instrumentoEscolhido.auto_sn_dl,
          dataIni: await this.datesRange[0],
          dataFim: await this.datesRange[1],
        });
        this.BATERIAData = BATERIADados?.data?.map((el) => {
          return {
            data_leitura: moment(el.timestamp).format("YYYY-MM-DD"),
            leitura: el.value,
            hora_leitura: moment(el.timestamp, "YYYY-MM-DD HH:mm:ss").format("HH:mm:ss"),
          };
        });

        return this.BATERIAData;
      } catch (err) {
        this.BATERIAData = undefined;
        return err;
      }
    },

    async getDataToTEMPERATURALineChart() {
      try {
        let TEMPERATURADados = await authService.getLeituraDynamoPorGrafico({
          tableName: this.baseDados,
          chartName: "Temp",
          sn: this.instrumentoEscolhido.auto_sn_dl,
          dataIni: await this.datesRange[0],
          dataFim: await this.datesRange[1],
        });
        this.TEMPERATURAData = TEMPERATURADados?.data?.map((el) => {
          return {
            data_leitura: moment(el.timestamp).format("YYYY-MM-DD"),
            leitura: el.value,
            hora_leitura: moment(el.timestamp, "YYYY-MM-DD HH:mm:ss").format("HH:mm:ss"),
          };
        });
        return this.TEMPERATURAData;
      } catch (err) {
        this.TEMPERATURAData = undefined;
        return err;
      }
    },

    async getDataToUMIDADELineChart() {
      try {
        let UMIDADEDados = await authService.getLeituraDynamoPorGrafico({
          tableName: this.baseDados,
          chartName: "Umid",
          sn: this.instrumentoEscolhido.auto_sn_dl,
          dataIni: await this.datesRange[0],
          dataFim: await this.datesRange[1],
        });
        this.UMIDADEData = UMIDADEDados?.data?.map((el) => {
          return {
            data_leitura: moment(el.timestamp).format("YYYY-MM-DD"),
            leitura: el.value,
            hora_leitura: moment(el.timestamp, "YYYY-MM-DD HH:mm:ss").format("HH:mm:ss"),
          };
        });
        return this.UMIDADEData;
      } catch (err) {
        this.UMIDADEData = undefined;
        return err;
      }
    },

    async getDataToCSQLineChart() {
      try {
        let CSQDados = await authService.getLeituraDynamoPorGrafico({
          tableName: this.baseDados,
          chartName: "CSQ",
          sn: this.instrumentoEscolhido.auto_sn_dl,
          dataIni: await this.datesRange[0],
          dataFim: await this.datesRange[1],
        });

        this.CSQData = CSQDados?.data?.map((el) => {
          return {
            data_leitura: moment(el.timestamp).format("YYYY-MM-DD"),
            leitura: el.value,
            hora_leitura: moment(el.timestamp, "YYYY-MM-DD HH:mm:ss").format("HH:mm:ss"),
          };
        });
        return this.CSQData;
      } catch (err) {
        this.CSQData = undefined;
        return err;
      }
    },

    async getDataToRssiLineChart() {
      try {
        let rssiDados = await authService.getLeituraDynamoPorGrafico({
          tableName: this.baseDados,
          chartName: "RSSI",
          sn: this.instrumentoEscolhido.auto_sn_dl,
          dataIni: await this.datesRange[0],
          dataFim: await this.datesRange[1],
        });

        this.RSSIData = rssiDados?.data?.map((el) => {
          return {
            data_leitura: moment(el.timestamp).format("YYYY-MM-DD"),
            leitura: el.value,
            hora_leitura: moment(el.timestamp, "YYYY-MM-DD HH:mm:ss").format("HH:mm:ss"),
          };
        });
        return this.RSSIData;
      } catch (err) {
        this.RSSIData = undefined;
        return err;
      }
    },

    async getDataToLQILineChart() {
      try {
        let LQIDados = await authService.getLeituraDynamoPorGrafico({
          tableName: this.baseDados,
          chartName: "lqi",
          sn: this.instrumentoEscolhido.auto_sn_dl,
          dataIni: await this.datesRange[0],
          dataFim: await this.datesRange[1],
        });

        this.LQIData = LQIDados?.data?.map((el) => {
          return {
            data_leitura: moment(el.timestamp).format("YYYY-MM-DD"),
            leitura: el.value,
            hora_leitura: moment(el.timestamp, "YYYY-MM-DD HH:mm:ss").format("HH:mm:ss"),
          };
        });
        return this.LQIData;
      } catch (err) {
        this.LQIData = undefined;
        return err;
      }
    },

    async getInspecoesByDate() {
      try {
        let response = await authService.visualizarInspecaoByData({
          tableName: this.baseDados,
          dataIni: moment(this.datesRange[0])
            .startOf("day")
            .format("YYYY-MM-DD HH:mm:ss"),
          dataFim: moment(this.datesRange[1])
            .endOf("day")
            .format("YYYY-MM-DD HH:mm:ss"),
        });

        this.inspecoesList(response.data);
      } catch (err) {
        return err;
      }
    },

    autoSnDlValue() {
      const instrumento = this.instrumentos.filter(
        (v) => v.auto_sn_dl == this.autoSnDl
      );
      return instrumento[0].auto_sn_dl;
    },

    inspecoesList(inspecoes) {
      if (inspecoes.length > 0) {
        let resultados = [];
        let diaAtual = moment(this.datesRange[0]).startOf("day");
        while (
          diaAtual.isSameOrBefore(moment(this.datesRange[1]).startOf("day"))
        ) {
          let objetoEncontrado = null;
          for (let objeto of inspecoes) {
            if (
              moment(objeto.data_inspecao)
                .startOf("day")
                .isSame(diaAtual.startOf("day")) &&
              objeto.instrumento_id === this.instrumentoEscolhido.id &&
              objeto.tipo_instrumento ===
                this.instrumentoEscolhido.tipo_instrumento
            ) {
              objetoEncontrado = objeto;
              break;
            }
          }
          resultados.push(objetoEncontrado);
          diaAtual.add(1, "days");
        }

        this.inspectionsList = resultados;
      }
    },
  },
};
</script>
<template>
  <v-container class="pa-0" fluid>
    <v-row ref="chartActions" class="my-8">
      <v-col cols="12" sm="auto" class="flex-sm-grow-1 px-sm-2">
        <v-select
          ref="instrumentoSelect"
          label="Selecione o instrumento"
          :items="instrumentos"
          v-model="instrumentoEscolhido"
          :disabled="loading"
          item-text="nome"
          return-object
          outlined
          hide-details
        />
      </v-col>
      <v-col cols="12" sm="auto" class="flex-sm-grow-1 px-sm-2">
        <v-menu
          v-model="datesMenu"
          :close-on-content-click="false"
          :disabled="loading"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-bind="attrs"
              v-on="on"
              v-model="datesText"
              height="100%"
              label="Data inicial ~ Data final"
              append-icon="mdi-calendar-range"
              readonly
              outlined
              hide-details
            />
          </template>
          <v-date-picker
            v-model="dates"
            @change="setDatesRange"
            class="minhas-estruturas-date-picker"
            show-adjacent-months
            no-title
            scrollable
            range
          />
        </v-menu>
      </v-col>
      <v-col cols="12" sm="auto" class="flex-sm-shrink-1 px-sm-2">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="grey lighten-1"
              class="py-7 thick-border"
              outlined
            >
              <v-icon
                color="grey darken-1"
                v-text="intervalo.icon || 'mdi-calendar-filter'"
              />
            </v-btn>
          </template>
          <v-list>
            <v-list-item-group v-model="intervalo">
              <v-list-item
                v-for="int in intervalos"
                :key="int.value"
                :value="int"
              >
                <v-list-item-icon>
                  <v-icon v-text="int.icon" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="int.text" />
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <v-row v-if="loading">
      <v-col cols="12" class="text-center">
        <v-progress-circular indeterminate color="primary" />
      </v-col>
    </v-row>
    <span
      v-else-if="
        instrumentoEscolhidoVisualizado.auto_manual && instrumentoEscolhido
      "
    >
      <LineChart
        v-if="BATERIAData && BATERIAData.length"
        key="1"
        chart-id="chartBateria"
        :chartTitle="'BATERIA'"
        :datas="datesRange"
        :leituras="BATERIAData"
        :intervalo="intervalo.value"
        :inspecoes="inspectionsList"
      />

      <LineChart
        v-if="TEMPERATURAData && TEMPERATURAData.length"
        key="2"
        chart-id="chartTemp"
        :chartTitle="'TEMPERATURA'"
        :datas="datesRange"
        :leituras="TEMPERATURAData"
        :intervalo="intervalo.value"
        :inspecoes="inspectionsList"
      />
      <LineChart
        v-if="UMIDADEData && UMIDADEData.length"
        key="3"
        chart-id="chartUmidade"
        :chartTitle="'UMIDADE'"
        :datas="datesRange"
        :leituras="UMIDADEData"
        :intervalo="intervalo.value"
        :inspecoes="inspectionsList"
      />
      <LineChart
        v-if="CSQData && CSQData.length"
        key="4"
        chart-id="chartCSQ"
        :chartTitle="'CSQ'"
        :datas="datesRange"
        :leituras="CSQData"
        :intervalo="intervalo.value"
        :inspecoes="inspectionsList"
      />
      <LineChart
        v-if="RSSIData && RSSIData.length"
        key="5"
        chart-id="chartRSSI"
        :chartTitle="'RSSI'"
        :datas="datesRange"
        :leituras="RSSIData"
        :intervalo="intervalo.value"
        :inspecoes="inspectionsList"
      />
      <LineChart
        v-if="LQIData && LQIData.length"
        key="6"
        chart-id="chartLQI"
        :chartTitle="'LQI'"
        :datas="datesRange"
        :leituras="LQIData"
        :intervalo="intervalo.value"
        :inspecoes="inspectionsList"
      />
      <PieChart
        v-if="instrumentoEscolhido.auto_manual"
        key="7"
        chart-id="piechart"
        :chartTitle="'PERDA DE PACOTES'"
        :labels="['Bom', 'Ruim', 'Perda de Pacotes']"
        :datasets="piechartData"
      />
    </span>

    <v-row v-else>
      <v-sheet class="mx-auto pa-5 grey lighten-5" rounded outlined>
        Não há leituras para a estrutura selecionada.
      </v-sheet>
    </v-row>
    <v-row class="my-10">
      <v-col>
        <h3>Status da saúde</h3>
        <HealthDetails
          :instrumento="instrumentoEscolhidoVisualizado"
          :alarmesAtuados="instrumentoEscolhidoVisualizado.alarmesAtuados"
          :isAuto="instrumentoEscolhidoVisualizado.auto_manual"
          :tipoInstrumento="instrumentoEscolhido.tipo_instrumento"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<style lang="scss">
.minhas-estruturas-date-picker {
  .v-date-picker-table {
    .v-btn.v-btn--active {
      background-color: map-get($app-colors, "lighten-5") !important;

      &.v-date-picker--first-in-range {
        background-color: map-get($app-colors, "base") !important;
      }

      &.v-date-picker--last-in-range {
        background-color: map-get($app-colors, "base") !important;
      }
    }
  }
}

.instrumento-bar {
  cursor: pointer;
}
</style>
