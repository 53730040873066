<script>
export default {
  name: "HealthDetails",
  components: {},
  props: {
    instrumento: { type: Object },
    alarmesAtuados: { type: Array },
    isAuto: { type: Boolean },
    tipoInstrumento: { type: String },
  },
  computed: {
    estruturaResumo() {
      return [
        {
          text: "Nome",
          value: this.instrumento.name || "-",
          instrumento_boolean: true,
          color: "grey--text",
        },
        {
          text: "Estrutura",
          value: this.instrumento.estrutura || "-",
          instrumento_boolean: true,
          color: "grey--text",
        },
        {
          text: "Status do instrumento",
          value: this.instrumento.status || "-",
          instrumento_boolean: true,
          color:
            this.instrumento.status == "Normal" ? "grey--text" : "orange--text",
        },
        {
          text:
            this.tipoInstrumento == "inst_pluviometro"
              ? "Precipitação no dia (mm)"
              : this.instrumento?.unidade_eng
              ? `Valor atual (${this.instrumento?.unidade_eng})`
              : "Nível atual (m)",
          // value: Number(this.instrumento.nivel).toFixed(2) || "-",
          value: isNaN(Number(this.instrumento.nivel))
            ? "-"
            : Number(this.instrumento.nivel).toFixed(2) || "-",
          instrumento_boolean: true,
          color: "grey--text",
        },
        {
          text: "Temperatura (°C)", // Temperatura (°C)
          value: this.instrumento.temperatura || "-",
          instrumento_boolean: this.isAuto,
          color: "grey--text",
        },
        {
          text: "Bateria (V)",
          value: this.instrumento.bateria || "-",
          instrumento_boolean: this.isAuto,
          color: "grey--text",
        },
        {
          text: "Sinal de rádio (dB)",
          value: this.instrumento.rssi || "-",
          instrumento_boolean: this.isAuto,
          color: "grey--text",
        },
        {
          text: "Perda de pacotes",
          value: this.instrumento.perdaPacotes || "-",
          instrumento_boolean: this.isAuto,
          color: "grey--text",
        },
      ];
    },
  },
};
</script>
<template>
  <v-container style="padding: 0">
    <v-card flat outlined style="display: flex">
      <v-container>
        <v-row>
          <v-col
            v-for="(item, idx) in estruturaResumo"
            :key="idx"
            cols="12"
            md="3"
            v-show="item.instrumento_boolean"
          >
            <div class="text-caption grey--text">{{ item.text }}</div>
            <div class="font-weight-bold text--darken-1" :class="item.color">
              {{ item.value }}
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-container style="width: 40%; padding: 10px; height: 100%">
        <div>
          <div>
            <div class="text-caption grey--text">Alarmes atuados:</div>
            <div style="height: 100%">
              <v-list
                class="font-weight-bold text-caption text--darken"
                style="padding: 0; margin: 0; height: 100%; color: #7a7f93"
                v-for="(alarmesAtuados, index) in this.alarmesAtuados"
                :key="index"
              >
                <p>{{ alarmesAtuados }}</p>
              </v-list>
            </div>
          </div>
        </div>
      </v-container>
    </v-card>
  </v-container>
</template>
