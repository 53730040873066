<template>
  <v-form @submit.prevent="openConfirmationDialog" ref="formInspecao">
    <v-row class="justify-content-center">
      <hr />
      <v-col md="6" offset-md="3">
        <v-select
          label="Selecione uma de suas estruturas *"
          :items="estruturas"
          v-model="payload.estrutura_id"
          item-text="text"
          item-value="value"
          outlined
          class="border--gray"
          @blur="geraListaInstrumento"
          :rules="requiredField"
          required
        />
        <v-select
          label="Selecione um de seus instrumentos *"
          :items="listaInstrumentos"
          v-model="payload.instrumento_id"
          :rules="requiredField"
          item-text="text"
          item-value="value"
          outlined
          required
          class="border--gray"
          @blur="atualizaNomeInstrumento"
        />
        <v-row>
          <v-col>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="payload.data_inspecao"
                  :rules="requiredField"
                  required
                  label="Data da inspeção *"
                  append-icon="mdi-calendar"
                  readonly
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  class="border--gray"
                  @click="setHoraAtual"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="payload.data_inspecao"
                color="primary"
                @input="menu = false"
                show-adjacent-months
                no-title
                scrollable
                id="dateOverdue"
                :max="getToday()"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col>
            <v-text-field
              @change="getHourNow"
              label="Hora da inspeção *"
              v-model="payload.hora_inspecao"
              :rules="requiredField"
              outlined
              required
              class="border--gray"
              type="time"
            />
          </v-col>
        </v-row>
        <v-text-field
          label="Inspeção *"
          v-model="payload.titulo"
          :rules="titleField"
          placeholder="Titulo"
          outlined
          required
          class="border--gray"
          type="text"
        />
        <v-textarea label="Observação" v-model="payload.observacao" outlined />
        <div v-if="tipoAcao === 'Editar'">
          <div v-for="(slide, i) in form.imagens" :key="i">
            <v-container>
              <v-row
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 10px;
                "
              >
                <v-file-input
                  :placeholder="
                    slide.key_imagem.replace(
                      /\b\w{8}-\w{4}-\w{4}-\w{4}-\w{12}(.*?)$/,
                      '$1'
                    )
                  "
                  show-size
                  accept="image/png, image/jpeg, image/bmp"
                  :key="'a' + i"
                  disabled
                />
                <v-icon style="cursor: pointer" @click="deleteImage(slide)"
                  >mdi-delete</v-icon
                >
              </v-row>
              <v-textarea
                label="Comentário"
                v-model="slide.comentario"
                outlined
                :key="'b' + i"
              />
            </v-container>
          </div>
        </div>
        <v-row
          style="
            display: flex;
            text-align: center;
            align-items: center;
            cursor: pointer;
          "
          @click="adicionaImagem"
        >
          <v-icon style="padding: 10px">mdi-camera</v-icon>
          <div>
            {{ !itens.length ? "Adicionar Imagens" : "Remover Imagens" }}
          </div>
        </v-row>
        <v-container
          v-show="itens.length"
          v-for="(i, index) in itens"
          :key="'t' + index"
          :id="'teste' + index"
        >
          <v-file-input
            placeholder="Adicionar Imagem"
            show-size
            v-model="images[index]"
            accept="image/png, image/jpeg, image/bmp"
            :key="'a' + index"
          ></v-file-input>
          <v-textarea
            label="Comentário"
            v-model="imagesText[index]"
            outlined
            :key="'b' + index"
          />
          <v-row style="gap: 10px; padding-left: 10px">
            <v-icon style="cursor: pointer" @click="adicionarItem"
              >mdi-camera-plus</v-icon
            >
            <v-icon style="cursor: pointer" @click="removeImagem(index)"
              >mdi-minus</v-icon
            >
          </v-row>
        </v-container>
        <v-btn block type="submit" class="pa-6 primary darken-2 my-6">
          {{ tipoAcao }} inspeção
        </v-btn>
        <ConfirmationDialog
          v-model="confirmationDialog"
          :theme="confirmTheme"
          :title="confirmTitle"
          :confirm="confirmButton"
          @confirmed="submit"
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import ConfirmationDialog from "@/components/ConfirmationDialog.vue";
import { AuthService } from "@/helpers/services";
import ResourceConfirmationMixin from "@/mixins/ResourceConfirmationMixin.js";
import moment from "moment";

const authService = new AuthService();
const inspecaoAgora = () => ({
  data_inspecao: moment(new Date()).format("YYYY-MM-DD"),
  hora_inspecao: moment(new Date()).format("HH:mm"),
});

function newPayload(override = {}) {
  return {
    estrutura: "",
    estrutura_id: "",
    instrumento: "",
    instrumento_id: "",
    tipo_instrumento: "",
    data_inspecao: "",
    hora_inspecao: "",
    images: [],
    imagesText: [],

    ...override,
  };
}

export default {
  name: "FormularioInspecao",
  components: { ConfirmationDialog },
  mixins: [ResourceConfirmationMixin],
  props: {
    form: {
      default: () => {},
      type: Object,
    },
    tipoAcao: {
      default: "Adicionar",
      type: String,
    },
  },
  data: () => ({
    itens: [],
    adicionar: false,
    estruturas: [],
    estruturaSelecionada: {},
    tipoInstrumentoSelecionado: {},
    nomeInstrumentoSelecionado: [],
    listaInstrumentos: [],
    originalPayload: {},
    menu: false,
    dateOverdue: "",
    payload: newPayload(),
    images: [],
    imagesText: [],
    requiredField: [(v) => !!v || "Campo obrigatório"],
    titleField: [
      (v) => !!v || "Campo obrigatório",
      (v) => (v && v.length <= 35) || "Precisa ter menos de 35 caracteres",
    ],
    deletedImages: [],
  }),
  created() {
    this.buscaEstruturas();
    this.atualizaCampos();
  },
  computed: {
    confirmResource() {
      return "essa inspeção";
    },
  },
  methods: {
    adicionarItem() {
      if (!this.length) {
        this.itens.push(1);
      }
    },
    adicionaImagem() {
      if (!this.itens.length) {
        this.itens.push(1);
      } else {
        this.itens = [];
        this.images = [];
        this.imagesText = [];
      }
    },
    removeImagem(index) {
      if (this.itens.length === 1) {
        this.itens = [];
        this.images = [];
        this.imagesText = [];
      }
      if (this.itens.length >= 1) {
        this.itens.splice(index, 1);
        this.images.splice(index, 1);
        this.imagesText.splice(index, 1);
      }
    },
    deleteImage(image) {
      let myArray = this.form.imagens.filter(function (obj) {
        return obj.id !== image.id;
      });
      this.form.imagens = myArray;
      this.deletedImages.push(image.imagem_id);
    },

    setHoraAtual() {
      const agora = inspecaoAgora();

      this.payload.hora_inspecao = agora.hora_inspecao;
    },

    setDataAtual() {
      const agora = inspecaoAgora();

      this.payload.data_inspecao = agora.data_inspecao;
      this.payload.hora_inspecao = agora.hora_inspecao;
    },
    atualizaCampos() {
      if (this.tipoAcao === "Adicionar") this.setDataAtual();
      if (this.form?.id) {
        this.payload = {
          ...this.form,
          estrutura: this.form.estrutura,
          estrutura_id: this.form.estrutura_id,
          instrumento_id: this.form.instrumento_id,
          instrumento: this.form.instrumento,
          tipo_instrumento: this.form.tipo_instrumento,
          data_inspecao: moment(this.form.data_inspecao).format("YYYY-MM-DD"),
          hora_inspecao: moment(this.form.data_inspecao).format("HH:mm"),
          images: [],
          imagesText: [],
        };

        this.originalPayload = { ...this.form };
        this.listaInstrumentos = [];

        this.listaInstrumentos.push({
          text: this.form.instrumento,
          nome: this.form.instrumento,
          value: this.form.instrumento_id,
        });
        this.listaInstrumentos.sort((a, b) => {
          const nomeA = a.text.toUpperCase(); // ignore upper and lowercase
          const nomeB = b.text.toUpperCase(); // ignore upper and lowercase
          if (nomeA < nomeB) {
            return -1;
          }
          if (nomeA > nomeB) {
            return 1;
          }

          // names must be equal
          return 0;
        });
      }
    },

    getToday() {
      return new Date().toISOString().split("T")[0];
    },

    getHourNow() {
      if (
        this.payload.data_inspecao == new Date().toISOString().split("T")[0]
      ) {
        if (this.payload.hora_inspecao.split(":")[0] > new Date().getHours()) {
          // hora escolhida é maior que a hora atual(resetar para hora atual)
          return this.setHoraAtual();
        } else if (
          this.payload.hora_inspecao.split(":")[0] == new Date().getHours() &&
          this.payload.hora_inspecao.split(":")[1] > new Date().getMinutes()
        ) {
          // hora escolhida é igual porem os minutos são maiores(resetar para hora atual)
          return this.setHoraAtual();
        } else {
          // hora escolhida é menor que a atual
          return "";
        }
      } else {
        // data da leitura escolhida é menor do que a atual;
        return "";
      }
    },

    buscaEstruturas() {
      authService
        .getClientResume()
        .then(({ data }) => {
          let payload = data.data;
          for (let i in payload) {
            this.estruturas.push({
              ...payload[i],
              text: payload[i].nome,
              value: payload[i].estrutura_id,
              estrutura: {
                value: payload[i].estrutura_id,
                text: payload[i].nome,
              },
            });
          }
          this.estruturas.sort((a, b) => {
            const nomeA = a.text.toUpperCase(); // ignore upper and lowercase
            const nomeB = b.text.toUpperCase(); // ignore upper and lowercase
            if (nomeA < nomeB) {
              return -1;
            }
            if (nomeA > nomeB) {
              return 1;
            }

            // names must be equal
            return 0;
          });
          return payload;
        })
        .catch((err) => {
          console.error(err);
          return err;
        });
    },
    atualizaNomeInstrumento() {
      const result = this.listaInstrumentos.filter(
        (e) => e.value === this.payload.instrumento_id
      );

      const instrumento = result[0]?.instrumento;

      if (instrumento) this.payload = { ...this.payload, ...instrumento };
    },

    geraListaInstrumento() {
      if (this.estruturas && this.payload.estrutura_id) {
        this.listaInstrumentos = [];
        let [{ tipo_instrumento, estrutura }] = this.estruturas?.filter((e) => {
          return e.value === this.payload.estrutura_id;
        });
        // atualiza o nome da estrutura no payload
        this.payload.estrutura = estrutura.text;

        for (let i in tipo_instrumento) {
          let instrumento_lista = tipo_instrumento[i].instrumentos;

          for (let instrumento in instrumento_lista) {
            this.listaInstrumentos.push({
              text: instrumento_lista[instrumento].nome,
              name: instrumento_lista[instrumento].nome,
              value: instrumento_lista[instrumento].id,
              instrumento: {
                instrumento_id: instrumento_lista[instrumento].id,
                instrumento: instrumento_lista[instrumento].nome,
                tipo_instrumento:
                  instrumento_lista[instrumento].tipo_instrumento,
              },
            });
          }
          this.listaInstrumentos.sort((a, b) => {
            const nomeA = a.text.toUpperCase(); // ignore upper and lowercase
            const nomeB = b.text.toUpperCase(); // ignore upper and lowercase
            if (nomeA < nomeB) {
              return -1;
            }
            if (nomeA > nomeB) {
              return 1;
            }

            // names must be equal
            return 0;
          });

          this.listaInstrumentos.unshift({
            text: "Geral",
            name: "Geral",
            value: "0",
          });
        }
      }
      return ["Selecione uma estrutura antes"];
    },

    async submit() {
      const data_inspecao = moment(
        this.payload.data_inspecao + this.payload.hora_inspecao,
        "YYYY-MM-DD HH:mm"
      ).toISOString();

      let body = {
        client_id: localStorage.getItem("cliente_uuid"),
        estrutura_id: this.payload.estrutura_id,
        instrumento_id: this.payload.instrumento_id,
        instrumento_name: this.payload.instrumento,
        tipo_instrumento: this.payload.tipo_instrumento,
        titulo: this.payload.titulo,
        observacao: this.payload.observacao,
        data_inspecao,
      };

      let imagesArray = [];
      let i = 0;
      for (let image of this.images) {
        const base64Image = await this.fileToBase64(image);

        imagesArray.push({
          filename: image.name,
          contentType: image.type,
          value: base64Image,
          comentario: this.imagesText[i] ?? null,
        });

        i++;
      }

      body = { ...body, images: imagesArray };

      if (this.tipoAcao === "Editar") {
        let deletedImages = [];
        this.deletedImages.map((id) => {
          deletedImages.push({ id: id });
        });

        let editedImages = [];
        this.form.imagens.map((image) => {
          editedImages.push({
            id: image.imagem_id,
            comentario: image.comentario,
          });
        });

        body = {
          ...body,
          inspecao_id: this.form.id,
          deletedImages,
          editedImages,
        };
      }

      switch (this.tipoAcao) {
        case "Adicionar":
          if (this.validate()) {
            this.$emit("enviaFormulario", body);
          }
          break;
        case "Editar":
          this.$emit("enviaFormulario", body);
          break;
      }
    },
    reset() {
      const override = this.tipoAcao === "Adicionar" ? inspecaoAgora() : {};
      const payload = newPayload(override);

      this.$refs.formInspecao.resetValidation();

      this.payload = payload;
    },
    validate() {
      return this.$refs.formInspecao.validate();
    },
    fileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        // Evento disparado quando a leitura do arquivo é concluída
        reader.onloadend = function () {
          const base64String = reader.result.split(",")[1];
          resolve(base64String);
        };

        // Evento disparado em caso de erro na leitura do arquivo
        reader.onerror = function (error) {
          reject(error);
        };

        // Ler o conteúdo do arquivo como uma URL de dados
        reader.readAsDataURL(file);
      });
    },
  },
};
</script>

<style scoped>
.v-text-field {
  border-radius: 10px;
}
</style>
