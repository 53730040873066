<template>
  <div>
    <v-text-field
      label="Cota topo *"
      v-model="payload.cota_topo"
      :rules="requiredField"
      placeholder="Digite a cota do topo"
      outlined
      required
      class="border--gray"
      type="number"
      @blur="payloadToParent"
      suffix="m"
    />
    <v-text-field
      label="Cota base *"
      v-model="payload.cota_base"
      :rules="requiredField"
      placeholder="Digite a cota da base"
      outlined
      required
      class="border--gray"
      type="number"
      @blur="payloadToParent"
      suffix="m"
    />
    <v-text-field
      label="Crista barragem"
      v-model="payload.crista_barragem"
      placeholder="Digite a crista da barragem"
      outlined
      class="border--gray"
      type="number"
      @blur="payloadToParent"
      suffix="m"
    />
    <v-text-field
      label="Soleira do vertedor"
      v-model="payload.soleira_vertedor"
      placeholder="Digite a soleira do vertedor"
      outlined
      class="border--gray"
      type="number"
      @blur="payloadToParent"
      suffix="m"
    />
    <v-text-field
      label="Cota alarme"
      v-model="payload.cota_alarme"
      placeholder="Digite a cota do alarme"
      outlined
      class="border--gray"
      type="number"
      @blur="payloadToParent"
      suffix="m"
    />
  </div>
</template>

<script>
export default {
  name: "IndicadorNivelDagua",
  components: {},
  props: {
    form: {
      default: () => {},
      type: Object,
    },
    tipoAcao: {
      default: "Adicionar",
      type: String,
    },
  },
  data: () => ({
    originalPayload: {},
    payload: {},
    requiredField: [(v) => !!v || "Campo obrigatório"],
  }),
  mounted() {
    this.atualizaCampos();
  },
  computed: {},
  methods: {
    atualizaCampos() {
      this.payload = { ...this.form };
      this.originalPayload = { ...this.form };
    },
    getItemSelecionado(item) {
      return item;
    },
    payloadToParent() {
      this.$emit("fieldsPayload", this.payload);
    },
  },
};
</script>

<style scoped>
.v-text-field {
  border-radius: 10px;
}
</style>
