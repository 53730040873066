<template>
  <v-container>
    <v-row>
      <v-col>
        <h2 class="text-h6 font-weight-bold pb-7">
          Preencha os dados abaixo para download das leituras
        </h2>
      </v-col>
    </v-row>
    <FormularioDownload
      ref="form"
      :tipoAcao="'Download'"
      @enviaFormulario="submit"
    />
    <ConfirmationDialog
      v-model="successDialog"
      :title="successDialogTitle"
      theme="success"
      confirm="Fechar"
      auto-close
    />
    <ConfirmationDialog
      v-model="failureDialog"
      :title="failureDialogTitle"
      :text="failureDialogText"
      theme="error"
      confirm="Fechar"
    />
  </v-container>
</template>

<script>
import ConfirmationDialog from "@/components/ConfirmationDialog.vue";
import ResourceActionMixin from "@/mixins/ResourceActionMixin";
import { mapActions, mapState } from "vuex";
import FormularioDownload from "./Components/FormularioDownload.vue";

export default {
  name: "LeituraDownload",
  components: { FormularioDownload, ConfirmationDialog },
  mixins: [ResourceActionMixin],
  data: () => ({ tipoAcao: "Download" }),
  computed: {
    ...mapState("leituras", {
      failure: (state) => state.downloadFailure,
      success: (state) => state.downloadSuccess,
    }),
    resourceNameActionSuccess() {
      return "Download leituras";
    },
    resourceNameActionFailure() {
      return "Download leituras";
    },
  },
  methods: {
    ...mapActions("leituras", ["download"]),

    submit(payload) {
      let leitura = {
        ...payload,
        cliente_id: localStorage.getItem("cliente_uuid"),
        usuario_id: localStorage.getItem("usuario_uuid"),
      };

      return this.download(leitura);
    },
  },
};
</script>
