<script>
export default {
  props: [
    "tableTitle",
    "showAction",
    "dispatchAction",
    "actionLabel",
    "hasSelection",
    "fabIcon",
  ],
};
</script>
<template>
  <v-row no-gutters justify="space-between" class="mt-4">
    <v-col cols="12" md="6">
      <h3 class="text-h6 font-weight-bold">{{ tableTitle }}</h3>
    </v-col>
    <v-col v-if="$vuetify.breakpoint.mdAndUp" class="text-right">
      <v-btn
        v-if="showAction"
        @click="dispatchAction"
        class="white--text primary darken-2 my-4"
      >
        {{ actionLabel }}
      </v-btn>
    </v-col>
    <v-fab-transition v-else>
      <v-btn
        v-if="showAction"
        v-show="hasSelection"
        color="primary"
        class="resource-table-row-fab"
        fixed
        right
        bottom
        fab
        @click="dispatchAction"
      >
        <v-icon>{{ fabIcon }}</v-icon>
      </v-btn>
    </v-fab-transition>
  </v-row>
</template>

<style scoped lang="scss">
.resource-table-row-fab {
  bottom: 2rem;
  right: 2rem;
}
</style>
