import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("instrumentos", {
      tiposInstrumentos: (state) => state.tipos,
    }),
    tipoInstrumentoNome() {
      return this.tiposInstrumentos.reduce(
        (m, t) => ({
          ...m,
          [t.value]: t.text,
        }),
        0
      );
    },
  },
  methods: {
    instrumentoWithTipo(payload) {
      let tipo = payload.tipo_instrumento;
      tipo = this.tipoInstrumentoNome[tipo] || tipo;

      return {
        ...payload,
        tipo: tipo,
        text: payload.nome,
        name: payload.nome,
        value: payload.id,
        uuid: payload.id,
      };
    },
  },
};
