import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VNavigationDrawer,{attrs:{"value":_vm.value,"width":"288","clipped":"","app":""},on:{"input":function($event){return _vm.$emit('input', $event)}}},[(_vm.isMobile)?_c(VToolbar,{staticClass:"mt-2",attrs:{"flat":"","dense":""}},[_c(VToolbarTitle,{staticClass:"text-subtitle-2"},[_vm._v(_vm._s(_vm.user.name))]),_c(VSpacer),_c(VBtn,{attrs:{"icon":""}},[_c(VIcon,{attrs:{"color":"grey darken-4","small":""}},[_vm._v("mdi-help-circle-outline")])],1),_c(VBtn,{attrs:{"title":"Sair","icon":""},on:{"click":function($event){return _vm.$emit('user:logout')}}},[_c(VIcon,{attrs:{"color":"grey darken-4","small":""}},[_vm._v("mdi-logout")])],1),_c(VBtn,{attrs:{"title":"Fechar","icon":""},on:{"click":function($event){_vm.value = false}}},[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mdi-menu")])],1)],1):_vm._e(),_c(VList,{staticClass:"mt-md-8",attrs:{"dense":""}},_vm._l((_vm.navItems),function(navItem){return _c(VListGroup,{key:navItem.title,staticClass:"mx-1 py-2",attrs:{"link":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VListItemIcon,{staticClass:"mr-2"},[_c(VIcon,{attrs:{"color":"black"}},[_vm._v(_vm._s(navItem.icon))])],1),_c(VListItemContent,{staticClass:"mx-1"},[_c(VListItemTitle,{staticClass:"text-subtitle-1 font-weight-medium",domProps:{"textContent":_vm._s(navItem.title)}})],1)]},proxy:true}],null,true)},_vm._l((navItem.isEstruturas
          ? _vm.estruturasAsListItems()
          : navItem.list),function(subItem){return _c(VListItem,{key:subItem.title,staticClass:"mx-8",attrs:{"to":subItem.to}},[_c(VListItemIcon,{staticClass:"mx-0"},[_c(VIcon,{attrs:{"color":"#FF8200","small":""}},[_vm._v(_vm._s(subItem.icon))])],1),_c(VListItemContent,{staticClass:"ma-0"},[_c(VListItemTitle,{staticClass:"grey--text text--nav-bar-left text-subtitle-1",domProps:{"textContent":_vm._s(subItem.title)}})],1)],1)}),1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }