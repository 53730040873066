import { AuthService } from "@/helpers/services";
import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";
const authService = new AuthService();

function userFromRequest(payload) {
  if (payload) {
    return {
      uuid: payload.usuario,
      token: payload.token,
      name: payload.nome,
      email: payload.email,
      customer: payload.cliente,
    };
  }
}

function userFromLocalStorage() {
  return {
    uuid: localStorage.getItem("usuario_uuid"),
    token: localStorage.getItem("token"),
    name: localStorage.getItem("userName"),
    email: localStorage.getItem("email"),
    customer: localStorage.getItem("cliente_uuid"),
  };
}

function isValid(user) {
  return user && user.uuid && user.token;
}

export default {
  namespaced: true,
  state: () => ({
    loading: false,
    failure: null,
    user: {},
  }),
  mutations: {
    LOGIN_START: (state) => {
      state.loading = true;
    },
    LOGIN_FAILURE: (state, error) => {
      state.loading = false;
      state.failure = error;
      state.user = null;
    },
    LOGIN_SUCCESS: (state, { user, save }) => {
      state.loading = false;
      state.failure = null;
      state.user = user;

      if (save) {
        try {
          localStorage.setItem("token", user.token);
          localStorage.setItem("email", user.email);
          localStorage.setItem("userName", user.name);
          localStorage.setItem("usuario_uuid", user.uuid);

          user.customer
            ? localStorage.setItem("cliente_uuid", user.customer)
            : localStorage.removeItem("cliente_uuid");
        } catch (err) {
          console.error(err, "Failure while saving user to LocalStorage");
        }
      }
    },
    LOGOUT: (state) => {
      state.user = null;

      try {
        localStorage.removeItem("token");
        localStorage.removeItem("email");
        localStorage.removeItem("userName");
        localStorage.removeItem("usuario_uuid");
        localStorage.removeItem("cliente_uuid");
        localStorage.removeItem("cliente_nome");
      } catch (err) {
        console.error(err, "Failure while deleting user from LocalStorage");
      }
    },
  },
  actions: {
    async loginFromLocalStorage({ commit }) {
      commit("LOGIN_START");

      const user = userFromLocalStorage();

      isValid(user)
        ? commit("LOGIN_SUCCESS", { user, save: false })
        : commit("LOGIN_FAILURE", new Error("Invalid user"));

      return isValid(user) ? user : null;
    },

    async loginFromServer({ commit }, { email, password }) {
      commit("LOGIN_START");

      return authService
        .authUser({ email, pass: password })
        .then((result) => {
          if (result.data.errorMessage) {
            Toastify({
              text: "E-mail ou senha inválida",
              newWindow: true,
              offset: {
                x: "70%", // horizontal axis - can be a number or a string indicating unity. eg: '2em'
                y: "50%", // vertical axis - can be a number or a string indicating unity. eg: '2em'
              },
              gravity: "top",
              position: "right",
              style: {
                background: "red",
              },
              duration: 2000,
            }).showToast();
            return commit("LOGIN_FAILURE", result.data.errorMessage);
          } else {
            Toastify({
              text: "Logado com sucesso",
              newWindow: true,
              offset: {
                x: "70%", // horizontal axis - can be a number or a string indicating unity. eg: '2em'
                y: "50%", // vertical axis - can be a number or a string indicating unity. eg: '2em'
              },
              gravity: "top",
              position: "right",
              style: {
                background: "orange",
              },
              duration: 2000,
            }).showToast();
          }

          const message = result.data?.message;
          const user = userFromRequest(result.data?.data);

          isValid(user)
            ? commit("LOGIN_SUCCESS", { user, message, save: true })
            : commit("LOGIN_FAILURE", new Error(message || "Invalid response"));

          return isValid(user) ? user : null;
        })
        .catch((err) => {
          if (err.response?.status === 400) {
            Toastify({
              text: "Usuário ou senha incorreto!",
              newWindow: true,
              offset: {
                x: "70%", // horizontal axis - can be a number or a string indicating unity. eg: '2em'
                y: "50%", // vertical axis - can be a number or a string indicating unity. eg: '2em'
              },
              gravity: "top",
              position: "right",
              style: {
                background: "orange",
              },
              duration: 2000,
            }).showToast();
            commit("LOGIN_FAILURE", err);
          } else {
            Toastify({
              text: "Falha de conexão",
              newWindow: true,
              offset: {
                x: "70%", // horizontal axis - can be a number or a string indicating unity. eg: '2em'
                y: "50%", // vertical axis - can be a number or a string indicating unity. eg: '2em'
              },
              gravity: "top",
              position: "right",
              style: {
                background: "red",
              },
              duration: 2000,
            }).showToast();
            commit("LOGIN_FAILURE", err);
          }
        });
    },

    logout({ commit }) {
      commit("LOGOUT");
    },
  },
  getters: {
    isLoading: (state) => !!state.loading,
    isAuthenticated: (state) => !!state.user?.token,
    hasCustomer: (state) => !!state.user?.customer,
  },
};
