import store from "@/store";

async function isAuthenticated(toRoute) {
  if (await store.dispatch("user/loginFromLocalStorage")) return true;

  if (toRoute && toRoute.query.token) {
    localStorage.setItem("token", toRoute.query.token);
    sessionStorage.setItem("token", toRoute.query.token);
    return true;
  }

  return false;
}

async function ensureAuthenticated(to, _from, next) {
  if (await isAuthenticated(to)) next({ name: "Admin" });
  else next({ name: "Login" });
}

async function skipAuthenticated(_to, _from, next) {
  if (!(await isAuthenticated())) next();
  else next({ name: "Admin" });
}

async function handleAuthMeta(to, _from, next) {
  if (to.matched.some((record) => record.meta.auth)) {
    if (!(await isAuthenticated())) return next({ name: "Login" });
  }

  next();
}

export { ensureAuthenticated, handleAuthMeta, skipAuthenticated };
