<template>
  <v-form @submit.prevent="openConfirmationDialog" ref="formInstrumento">
    <v-row>
      <v-col cols="12" md="6" lg="6">
        <v-select
          label="Tipo de instrumento *"
          :items="tiposInstrumentos"
          :rules="requiredField"
          v-model="payload.tipo_instrumento"
          outlined
          required
          :disabled="tipoAcao === 'Editar'"
          class="border--gray"
        />
        <v-select
          label="Estrutura *"
          :items="estruturas"
          :rules="requiredField"
          v-model="payload.estrutura_id"
          outlined
          required
          :disabled="tipoAcao === 'Editar'"
          class="border--gray"
        />
        <v-text-field
          label="Instrumento *"
          v-model="payload.nome"
          :rules="requiredField"
          placeholder="Digite o nome do instrumento"
          outlined
          required
          class="border--gray"
        />
        <v-text-field
          label="Longitude"
          v-model="payload.longitude"
          placeholder="Digite a longitude"
          outlined
          class="border--gray"
          type="number"
          suffix="°"
        />
        <v-text-field
          label="Latitude"
          v-model="payload.latitude"
          placeholder="Digite a latitude"
          outlined
          class="border--gray"
          type="number"
          suffix="°"
        />
        <v-text-field
          label="Elevação"
          v-model="payload.elevacao"
          placeholder="Digite a elevação"
          outlined
          class="border--gray"
          type="number"
          suffix="m"
        />
        <v-select
          :label="
            payload.tipo_instrumento === 'inst_outros'
              ? 'Condição do instrumento'
              : 'Condição do instrumento *'
          "
          :items="condicao_instrumento"
          :rules="
            payload.tipo_instrumento === 'inst_outros' ? [] : requiredField
          "
          v-model="payload.condicao_instrumento"
          outlined
          required
          class="border--gray"
        />
        <v-text-field
          label="Local de Instalação"
          v-model="payload.local_instalacao"
          placeholder="Digite o local de instalação"
          outlined
          class="border--gray"
        />
      </v-col>
      <v-col cols="12" md="6" lg="6">
        <Pluviometro
          v-if="payload.tipo_instrumento === 'inst_pluviometro'"
          @fieldsPayload="fillFields"
          :form="payload"
        />
        <Piezometro
          v-if="payload.tipo_instrumento === 'inst_piezometro'"
          @fieldsPayload="fillFields"
          :form="payload"
        />
        <IndicadorNivelDagua
          v-if="payload.tipo_instrumento === 'inst_outros'"
          @fieldsPayload="fillFields"
          :form="payload"
        />
        <ReguaReservatorio
          v-if="payload.tipo_instrumento === 'inst_regua_reservatorio'"
          @fieldsPayload="fillFields"
          :form="payload"
        />

        <v-textarea label="Observação" v-model="payload.anotacoes" outlined />
        <div class="text-center d-flex">
          <v-switch
            class="my-0 pb-12 pt-2 pr-6"
            v-model="payload.auto_manual"
            label="Instrumento automatizado?"
            color="orange"
            hide-details
          >
          </v-switch>

          <v-tooltip
            v-if="
              payload.auto_manual &&
              payload.tipo_instrumento == 'inst_piezometro'
            "
            top
          >
            <template v-slot:activator="{ on }">
              <v-icon
                size="20"
                class="my-0 pb-12 pt-2 pr-6"
                v-on="on"
                color="orange"
                >mdi-help-circle</v-icon
              >
            </template>
            <span>L_calc = (L_bruta * ConstA) + ConstB</span>
          </v-tooltip>
        </div>
        <!-- <v-select
          v-if="
            payload.auto_manual && payload.tipo_instrumento === 'inst_outros'
          "
          label="Tipo datalogger"
          v-model="payload.tipo_datalogger"
          :items="tipodatalog"
          outlined
          required
          class="border--gray"
        /> -->
        <v-text-field
          v-if="payload.auto_manual"
          label="Número de série"
          v-model="auto_sn_dl"
          placeholder="Digite o número de série"
          outlined
          class="border--gray"
          type="text"
          @blur="canal"
        />
        <v-select
          v-if="payload.auto_manual"
          label="Canal"
          v-model="payload.auto_canal_dl"
          :items="canais"
          outlined
          required
          class="border--gray"
        />
        <v-select
          v-if="
            payload.auto_manual && payload.tipo_instrumento === 'inst_outros'
          "
          label="Tipo de fórmula"
          :items="formulas"
          item-value="text"
          v-model="payload.tipo_formula"
          outlined
          required
          class="border--gray"
        />

        <v-textarea
          v-if="
            payload.auto_manual && payload.tipo_instrumento === 'inst_outros'
          "
          v-model="viewFormulas"
          class="descrition"
          disabled
        />
        <v-text-field
          v-if="payload.auto_manual"
          label="Constante A"
          v-model="payload.auto_consta"
          placeholder="Digite a Constante A"
          outlined
          class="border--gray"
          type="number"
        />

        <v-text-field
          v-if="payload.auto_manual"
          label="Constante B"
          v-model="payload.auto_constb"
          placeholder="Digite a Constante B"
          outlined
          class="border--gray"
          type="number"
        />

        <v-text-field
          v-if="
            (payload.auto_manual &&
              payload.tipo_instrumento === 'inst_outros' &&
              payload.tipo_formula === 'Polinomial') ||
            payload.tipo_formula === 'Seno' ||
            payload.tipo_formula === 'Tangente'
          "
          label="Constante C"
          v-model="payload.auto_constc"
          placeholder="Digite a Constante C"
          outlined
          class="border--gray"
          type="number"
        />

        <v-text-field
          v-if="
            payload.auto_manual &&
            payload.tipo_instrumento === 'inst_outros' &&
            payload.tipo_formula === 'Polinomial'
          "
          label="Constante D"
          v-model="payload.auto_constd"
          placeholder="Digite a Constante D"
          outlined
          class="border--gray"
          type="number"
        />

        <v-text-field
          v-if="
            payload.auto_manual &&
            payload.tipo_instrumento === 'inst_outros' &&
            payload.tipo_formula === 'Polinomial'
          "
          label="Constante E"
          v-model="payload.auto_conste"
          placeholder="Digite a Constante E"
          outlined
          class="border--gray"
          type="number"
        />
        <v-btn block type="submit" class="pa-6 primary darken-2">
          {{ tipoAcao }} instrumento
        </v-btn>
        <ConfirmationDialog
          v-model="confirmationDialog"
          :theme="confirmTheme"
          :title="confirmTitle"
          :confirm="confirmButton"
          @confirmed="submit"
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import ConfirmationDialog from "@/components/ConfirmationDialog.vue";
import { AuthService } from "@/helpers/services";
import { estados } from "@/helpers/utils/ufCidades";
import ResourceConfirmationMixin from "@/mixins/ResourceConfirmationMixin.js";
import IndicadorNivelDagua from "./FormularioIndicadorNivelDagua.vue";
import Piezometro from "./FormularioPiezometro.vue";
import Pluviometro from "./FormularioPluviometro.vue";
import ReguaReservatorio from "./FormularioReguaReservatorio.vue";

const authService = new AuthService();

export default {
  name: "FormularioTipoInstrumento",
  mixins: [ResourceConfirmationMixin],
  components: {
    ConfirmationDialog,
    Pluviometro,
    Piezometro,
    IndicadorNivelDagua,
    ReguaReservatorio,
  },
  props: {
    form: {
      default: () => {},
      type: Object,
    },
    tipoAcao: {
      default: "Adicionar",
      type: String,
    },
    tiposInstrumentos: {
      default: () => [],
      type: Array,
    },
  },
  data: () => ({
    confirmEdit: false,
    estados: estados,
    estruturas: [],
    canais: [],
    baseDados: "",
    auto_sn_dl: "",
    tipodatalog: ["DL_NB_tilt", "DL_NB"],
    estruturaSelecionada: {},
    condicao_instrumento: [
      { text: "Operante", value: "operante" },
      { text: "Inativo", value: "inativo" },
    ],
    tipo_emergencia: [{ text: "Emergencia", value: "emergencia_1" }],
    originalPayload: {},
    payload: {},
    requiredField: [(v) => !!v || "Campo obrigatório"],
    formulas: [
      {
        text: "Linear",
        value:
          "f(x) = Ax + B \nA - Coeficiente da equação linear \nB - Constante",
      },
      {
        text: "Polinomial",
        value:
          "f(x) = Ax⁴ + Bx³ + Cx² + Dx + E \nA, B, C, D - Coeficientes da equação polinomial \nE - Constante",
      },
      {
        text: "Tangente",
        value:
          "f(x) = A * tan(x - B) + C \nA - Constante para conversão de unidades \nB - Leitura inicial \nC - Offset",
      },
      {
        text: "Seno",
        value:
          "f(x) = A *sin(x - B) + C \nA - Constante para conversão de unidades \nB - Leitura inicial \nC - Offset",
      },
      {
        text: "Corrente/Tensão",
        value:
          "f(x) = (x - A)/(B - A) \nA - Início de escala \nB - Fim de escala",
      },
    ],
    listaCanalPiezometro: [
      { text: "I0", value: "I0" },
      { text: "I1", value: "I1" },
      { text: "0-1V", value: "V1" },
      { text: "0-10V", value: "V10" },
      { text: "engineeringValue", value: "engineegingValue" },
    ],
    listaPluviometro: ["INT0", "INT1"],
    listaCanalOutros: [
      { text: "I0", value: "I0" },
      { text: "I1", value: "I1" },
      { text: "INT0", value: "INT0" },
      { text: "INT1", value: "INT1" },
      { text: "Tilt_X", value: "Tilt_X" },
      { text: "Tilt_Y", value: "Tilt_Y" },
      { text: "Tilt_Z", value: "Tilt_Z" },
      { text: "0-1V", value: "V1" },
      { text: "0-10V", value: "V10" },
      { text: "engineeringValueA", value: "engineeringValueA" },
      { text: "engineeringValueB", value: "engineeringValueB" },
      { text: "engineeringValue", value: "engineeringValue" },
      { text: "engineeringValue0", value: "engineeringValue0" },
      { text: "engineeringUnit0", value: "engineeringUnit0" },
      { text: "pulso", value: "pulso" },
      { text: "tensao", value: "tensao" },
    ],
  }),
  async mounted() {
    this.buscaEstruturas();
    this.atualizaCampos();
    this.auto_sn_dl = this.payload.auto_sn_dl ?? "";
    const baseDados = await authService.getClientResume(this.estruturaId);
    this.baseDados = baseDados?.data?.data[0]?.baseDados;
    this.canal();
  },
  computed: {
    confirmResource() {
      return "esse instrumento";
    },
    estadoSelecionado() {
      if (this.payload?.uf) {
        let item = estados?.filter((e) => e.sigla === this.payload.uf);
        let lista = [...item[0]?.cidades];
        return lista;
      }
      return ["Selecione um estado"];
    },
    viewFormulas() {
      let item = this.formulas?.filter(
        (e) => e.text === this.payload.tipo_formula
      );
      return item[0]?.value ?? "";
    },
  },
  watch: {
    auto_sn_dl: function () {
      this.payload.auto_sn_dl = this.auto_sn_dl;
      return this.canal();
    },
  },
  methods: {
    textCanal() {
      if (this.tipoInstrumento == "inst_piezometro") {
        return true;
      } else {
        return false;
      }
    },
    atualizaCampos() {
      this.payload = { ...this.form };
      this.originalPayload = { ...this.form };
    },
    getItemSelecionado(item) {
      return item;
    },
    fillFields(payload) {
      this.payload = { ...this.payload, ...payload };
    },
    buscaEstruturas() {
      authService
        .buscaEstruturas()
        .then(({ data }) => {
          let payload = data.data;
          for (let i in payload) {
            this.estruturas.push({
              text: payload[i].nome,
              value: payload[i].uuid,
              uuid: payload[i].uuid,
            });
          }
          this.estruturas.sort((a, b) => {
            const nomeA = a.text.toUpperCase(); // ignore upper and lowercase
            const nomeB = b.text.toUpperCase(); // ignore upper and lowercase
            if (nomeA < nomeB) {
              return -1;
            }
            if (nomeA > nomeB) {
              return 1;
            }

            // names must be equal
            return 0;
          });
          return payload;
        })
        .catch((err) => {
          console.error(err);
          return err;
        });
    },
    async canal() {
      const data = await authService.listarTodosCanaisByAutoSn(
        this.baseDados,
        this.auto_sn_dl
      );
      if (data.data) {
        this.canais = data.data;
      } else {
        this.canais = this.listaCanalOutros;
      }
    },
    reset() {
      return this.$refs.formInstrumento.reset();
    },
    validate() {
      return this.$refs.formInstrumento.validate();
    },
    submit() {
      let estruturas = this.estruturas;
      let estrutura_nome = "";
      for (let i in estruturas) {
        if (estruturas[i].uuid === this.payload.estrutura_id) {
          estrutura_nome = estruturas[i].text;
        }
      }
      if (this.payload.auto_manual === false) {
        this.payload.auto_sn_dl = null;
        this.payload.auto_canal_dl = null;
        this.payload.auto_consta = null;
        this.payload.auto_constb = null;
      }

      switch (this.tipoAcao) {
        case "Adicionar":
          if (this.validate()) {
            this.$emit("enviaFormulario", {
              ...this.payload,
              estrutura: estrutura_nome,
            });
          }
          break;
        case "Editar":
          this.$emit("enviaFormulario", {
            ...this.originalPayload,
            estrutura: estrutura_nome,
            update: { ...this.payload, estrutura: estrutura_nome },
          });
          break;
      }
    },
  },
};
</script>

<style scoped>
.v-text-field {
  border-radius: 10px;
}
</style>
