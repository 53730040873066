<template>
  <v-form @submit.prevent="openConfirmationDialog" ref="formLeitura">
    <v-row class="justify-content-center">
      <hr />
      <v-col md="6" offset-md="3">
        <v-select
          label="Selecione uma de suas estruturas *"
          :items="estruturas"
          v-model="payload.estrutura_id"
          item-text="text"
          item-value="value"
          outlined
          class="border--gray"
          @blur="geraListaInstrumento"
          :rules="requiredField"
          required
        />
        <v-select
          label="Selecione um de seus instrumentos *"
          :items="listaInstrumentos"
          v-model="payload.instrumento_id"
          :rules="requiredField"
          item-text="text"
          item-value="value"
          outlined
          required
          class="border--gray"
          @blur="atualizaNomeInstrumento"
        />
        <v-row>
          <v-col>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="payload.data_inicial"
                  :rules="requiredField"
                  required
                  label="Data inicial *"
                  append-icon="mdi-calendar"
                  readonly
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  class="border--gray"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="payload.data_inicial"
                color="primary"
                @input="menu = false"
                show-adjacent-months
                no-title
                scrollable
                id="data_inicial"
                :max="payload.data_final || getToday()"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col>
            <v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="payload.data_final"
                  :rules="requiredField"
                  required
                  label="Data final *"
                  append-icon="mdi-calendar"
                  readonly
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  class="border--gray"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="payload.data_final"
                color="primary"
                @input="menu2 = false"
                show-adjacent-months
                no-title
                scrollable
                id="data_final"
                :min="payload.data_inicial"
                :max="getToday()"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-btn
          block
          type="submit"
          class="pa-6 primary darken-2"
          @click="onBtnExport()"
        >
          {{ tipoAcao }} dos dados
        </v-btn>
        <ConfirmationDialog
          v-model="confirmationDialog"
          :theme="confirmTheme"
          :title="confirmTitle"
          :confirm="confirmButton"
          @confirmed="gerarRelatorio()"
        />
        <div v-show="false">
          <ag-grid-vue
            style="width: 100%; height: 100%"
            class="ag-theme-alpine"
            :columnDefs="columnDefs"
            @grid-ready="onGridReady"
            :defaultColDef="defaultColDef"
            :suppressExcelExport="true"
            :popupParent="popupParent"
            :rowData="rowData"
          />
        </div>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import ConfirmationDialog from "@/components/ConfirmationDialog.vue";
import { AuthService } from "@/helpers/services";
import ResourceConfirmationMixin from "@/mixins/ResourceConfirmationMixin.js";
import { AgGridVue } from "ag-grid-vue";
import moment from "moment";
import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";

const authService = new AuthService();
const leituraAgora = () => ({
  data_final: moment(new Date()).format("YYYY-MM-DD"),
});

function newPayload(override = {}) {
  return {
    estrutura: "",
    estrutura_id: "",
    instrumento: "",
    instrumento_id: "",
    tipo_instrumento: "",
    data_inicial: "",
    data_final: "",
    statusRelatorio: false,
    ...override,
  };
}

export default {
  name: "FormularioDownload",
  components: { ConfirmationDialog, "ag-grid-vue": AgGridVue },
  mixins: [ResourceConfirmationMixin],
  props: {
    form: {
      default: () => {},
      type: Object,
    },
    tipoAcao: {
      default: "Download",
      type: String,
    },
  },
  data: () => ({
    estruturas: [],
    estruturaSelecionada: {},
    tipoInstrumentoSelecionado: {},
    nomeInstrumentoSelecionado: [],
    listaInstrumentos: [],
    originalPayload: {},
    menu: false,
    menu2: false,
    data_inicial: "",
    data_final: "",
    payload: newPayload(),
    requiredField: [(v) => !!v || "Campo obrigatório"],
    columnDefs: null,
    gridApi: null,
    columnApi: null,
    defaultColDef: {
      editable: true,
      resizable: true,
      minWidth: 100,
      flex: 1,
    },
    popupParent: null,
    rowData: null,
    contador: 0,
  }),
  created() {
    this.buscaEstruturas();
    this.atualizaCampos();
    this.popupParent = document.body;
  },
  computed: {
    confirmResource() {
      return "dos dados";
    },
  },
  methods: {
    setDataFinal() {
      const agora = leituraAgora();

      this.payload.data_final = agora.data_final;
    },
    atualizaCampos() {
      if (this.tipoAcao === "Download") this.setDataFinal();
      if (this.form?.id) {
        this.payload = {
          ...this.form,
          estrutura: this.form.estrutura,
          estrutura_id: this.form.estrutura_id,
          instrumento_id: this.form.instrumento_id,
          instrumento: this.form.instrumento,
          tipo_instrumento: this.form.tipo_instrumento,
          data_inicial: moment(this.form.data_inicial).format("YYYY-MM-DD"),
          data_final: moment(this.form.data_final).format("YYYY-MM-DD"),
        };

        this.originalPayload = { ...this.form };
        this.listaInstrumentos = [];

        this.listaInstrumentos.push({
          text: this.form.instrumento,
          nome: this.form.instrumento,
          value: this.form.instrumento_id,
        });
        this.listaInstrumentos.sort((a, b) => {
          const nomeA = a.text.toUpperCase(); // ignore upper and lowercase
          const nomeB = b.text.toUpperCase(); // ignore upper and lowercase
          if (nomeA < nomeB) {
            return -1;
          }
          if (nomeA > nomeB) {
            return 1;
          }

          // names must be equal
          return 0;
        });
      }
    },

    getToday() {
      return new Date().toISOString().split("T")[0];
    },

    buscaEstruturas() {
      authService
        .getClientResume()
        .then(({ data }) => {
          let payload = data.data;
          for (let i in payload) {
            this.estruturas.push({
              ...payload[i],
              text: payload[i].nome,
              value: payload[i].estrutura_id,
              estrutura: {
                value: payload[i].estrutura_id,
                text: payload[i].nome,
              },
            });
          }
          this.estruturas.sort((a, b) => {
            const nomeA = a.text.toUpperCase(); // ignore upper and lowercase
            const nomeB = b.text.toUpperCase(); // ignore upper and lowercase
            if (nomeA < nomeB) {
              return -1;
            }
            if (nomeA > nomeB) {
              return 1;
            }

            // names must be equal
            return 0;
          });
          return payload;
        })
        .catch((err) => {
          console.error(err);
          return err;
        });
    },
    atualizaNomeInstrumento() {
      const result = this.listaInstrumentos.filter(
        (e) => e.value === this.payload.instrumento_id
      );

      const instrumento = result[0]?.instrumento;

      if (instrumento) this.payload = { ...this.payload, ...instrumento };
    },

    geraListaInstrumento() {
      if (this.estruturas && this.payload.estrutura_id) {
        this.listaInstrumentos = [];
        let [{ tipo_instrumento, estrutura }] = this.estruturas?.filter((e) => {
          return e.value === this.payload.estrutura_id;
        });
        // atualiza o nome da estrutura no payload
        this.payload.estrutura = estrutura.text;

        for (let i in tipo_instrumento) {
          let instrumento_lista = tipo_instrumento[i].instrumentos;

          for (let instrumento in instrumento_lista) {
            this.listaInstrumentos.push({
              text: instrumento_lista[instrumento].nome,
              name: instrumento_lista[instrumento].nome,
              value: instrumento_lista[instrumento].id,
              instrumento: {
                instrumento_id: instrumento_lista[instrumento].id,
                instrumento: instrumento_lista[instrumento].nome,
                tipo_instrumento:
                  instrumento_lista[instrumento].tipo_instrumento,
              },
            });
          }
        }
        this.listaInstrumentos.sort((a, b) => {
          const nomeA = a.instrumento?.instrumento?.toUpperCase(); // ignore upper and lowercase
          const nomeB = b.instrumento?.instrumento?.toUpperCase(); // ignore upper and lowercase
          if (nomeA < nomeB) {
            return -1;
          }
          if (nomeA > nomeB) {
            return 1;
          }

          // names must be equal
          return 0;
        });
        this.listaInstrumentos.unshift("Todos instrumentos");
      }
      return ["Selecione uma estrutura antes"];
    },

    //arrumar as logicas!
    submit() {
      let estruturas = this.estruturas;
      let estrutura_nome = "";
      for (let i in estruturas) {
        if (estruturas[i].uuid === this.payload.estrutura_id) {
          estrutura_nome = estruturas[i].text;
        }
      }

      let payload = {
        ...this.payload,
        usuario_id: localStorage.getItem("usuario_uuid"),
        cliente_id: localStorage.getItem("cliente_uuid"),
        [`${this.payload.tipo_instrumento + "_id"}`]:
          this.payload.instrumento_id,
        data_inicial: new Date(this.payload.data_inicial),
        data_final: new Date(this.payload.data_final),
      };
      if (this.validate()) {
        this.$emit("enviaFormulario", {
          ...this.originalPayload,
          update: { ...this.payload, estrutura: estrutura_nome },
        });
        this.onBtnExport();
      } else {
        return payload;
      }
    },
    reset() {
      const override = this.tipoAcao === "Download" ? leituraAgora() : {};
      const payload = newPayload(override);

      this.$refs.formLeitura.resetValidation();

      this.payload = payload;
    },

    validate() {
      return this.$refs.formLeitura.validate();
    },

    async onBtnExport() {
      // tratativas de erro aqui
      this.statusRelatorio = false;
      const response = await authService.csvDataDownload({
        name: this.payload.instrumento,
        id: this.payload.instrumento_id,
        dataIni: this.payload.data_inicial,
        dataFim: this.payload.data_final,
      });
      if (response && response.data && response.data.items.length > 0) {
        const firstItem = response.data.items[0];
        // Dynamically create columnDefs based on the keys of the first item
        this.columnDefs = Object.keys(firstItem).map((key) => {
          return { field: key, headerName: this.formatHeaderName(key) };
        });
        // Set the rowData to include all the data
        this.rowData = response.data.items;
      }

      // if (response.data.type === "auto") {
      //   this.columnDefs = [
      //     { field: "sn", headerName: "SN" },
      //     { field: "instrumento", headerName: "Instrumento" },
      //     { field: "dataRecebimento", headerName: "Data/Hora de recebimento" },
      //     { field: "pacote", headerName: "Pacote" },
      //     { field: "bat", headerName: "Bateria (V)" },
      //     { field: "lqi", headerName: "LQI" },
      //     { field: "csq", headerName: "Sinal de rádio (CSQ)" },
      //     { field: "rssi", headerName: "Sinal de rádio (RSSI)" },
      //     { field: "temp", headerName: "Temperatura (°C)" },
      //     { field: "umid", headerName: "Umidade (%)" },
      //     { field: "leitura", headerName: "Nivel (m)" },
      //   ];
      // } else {
      //   this.columnDefs = [
      //     { field: "instrumento", headerName: "Instrumento" },
      //     { field: "data_leitura", headerName: "Data/Hora de recebimento" },
      //     { field: "leitura", headerName: "Nivel (m)" },
      //   ];
      // }
      // this.rowData = response.data.items;
      this.statusRelatorio = true;
    },

    // Helper function to format the header names (optional)
    formatHeaderName(key) {
      // Capitalize and add spaces between camelCase or snake_case words (customize as needed)
      return key
        .replace(/([a-z0-9])([A-Z])/g, "$1 $2")
        .replace(/_/g, " ")
        .replace(/\b\w/g, (char) => char.toUpperCase());
    },

    gerarRelatorio() {
      let attempts = 5;
      this.contador++;
      if (this.contador > attempts) {
        this.contador = 1;
      }
      if (!this.statusRelatorio && this.contador <= attempts) {
        Toastify({
          text: `Aguardando Relatório...(${this.contador}/${attempts})`,
          newWindow: true,
          offset: {
            x: "70%", // horizontal axis - can be a number or a string indicating unity. eg: '2em'
            y: "50%", // vertical axis - can be a number or a string indicating unity. eg: '2em'
          },
          gravity: "top",
          position: "right",
          style: {
            background: "orange",
          },
          duration: 2000,
        }).showToast();
        setTimeout(() => {
          if (this.contador == attempts) {
            Toastify({
              text: "Erro ao gerar relatório",
              newWindow: true,
              offset: {
                x: "70%", // horizontal axis - can be a number or a string indicating unity. eg: '2em'
                y: "50%", // vertical axis - can be a number or a string indicating unity. eg: '2em'
              },
              gravity: "top",
              position: "right",
              style: {
                background: "red",
              },
              duration: 2000,
            }).showToast();
          } else {
            this.gerarRelatorio();
          }
        }, 5000);
      }

      if (this.statusRelatorio) {
        Toastify({
          text: "Relatório gerado com sucesso",
          newWindow: true,
          offset: {
            x: "70%", // horizontal axis - can be a number or a string indicating unity. eg: '2em'
            y: "50%", // vertical axis - can be a number or a string indicating unity. eg: '2em'
          },
          gravity: "top",
          position: "right",
          style: {
            background: "green",
          },
          duration: 2000,
        }).showToast();
        this.gridApi.exportDataAsCsv({
          fileName:
            "Relatório de Leituras_" +
            moment(new Date()).format("YYYY-MM-DD_HHmmss") +
            ".csv",
        });
        this.contador = 0;
        this.statusRelatorio = false;
      }
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
  },
};
</script>

<style scoped>
.v-text-field {
  margin: 0;
  border-radius: 10px;
}
</style>
