<template>
  <div>
    <ResourceTable
      @activated:action="openSecondConfirmation"
      @selected:item="instrumento = $event"
      :actionName="acao"
      :items="instrumentos"
      :tableTitle="tabelaTitulo"
      :headers="cabecalhos"
      :resourceName="tipo"
      :confirmation="deleteConfirmation"
      item-key="index"
    />
    <ConfirmationDialog
      v-model="secondConfirmationDialog"
      v-bind="secondConfirmation"
      @confirmed="submit"
    />
    <ConfirmationDialog
      v-model="successDialog"
      :title="successDialogTitle"
      theme="success"
      confirm="Fechar"
      auto-close
    />
    <ConfirmationDialog
      v-model="failureDialog"
      :title="failureDialogTitle"
      :text="failureDialogText"
      theme="error"
      confirm="Fechar"
    />
  </div>
</template>

<script>
import ConfirmationDialog from "@/components/ConfirmationDialog.vue";
import ResourceTable from "@/components/ResourceTable.vue";
import { AuthService } from "@/helpers/services";
import InstrumentoTiposMixin from "@/mixins/InstrumentoTiposMixin";
import ResourceActionMixin from "@/mixins/ResourceActionMixin";
import { mapActions, mapState } from "vuex";

const authService = new AuthService();
export default {
  name: "InstrumentoExcluir",
  components: { ResourceTable, ConfirmationDialog },
  mixins: [ResourceActionMixin, InstrumentoTiposMixin],
  data: () => ({
    secondConfirmationDialog: false,
    acao: "excluir",
    tipo: "instrumento",
    tabelaTitulo: "Selecione qual instrumento gostaria de excluir",
    cliente_id: localStorage.getItem("cliente_uuid"),
    usuario_id: localStorage.getItem("usuario_uuid"),
    instrumentos: [],
    instrumento: {},
    render: 0,
  }),
  mounted() {
    this.buscaInstrumentos();
  },
  computed: {
    ...mapState("instrumentos", {
      failure: (state) => state.deleteFailure,
      success: (state) => state.deleteSuccess,
    }),
    resourceNameActionSuccess() {
      return "Instrumento excluído";
    },
    resourceNameActionFailure() {
      return "excluir esse instrumento";
    },
    failureDialogText() {
      return "Tente novamente mais tarde";
    },
    deleteConfirmation() {
      return {
        theme: "warning",
        title: `Você está prestes a excluir o instrumento "${this.instrumento.nome}"`,
        confirm: "Sim, quero excluir",
        cancel: "Não tenho certeza",
      };
    },
    secondConfirmation() {
      return {
        theme: "info",
        title: `Confirmação de exclusão do instrumento "${this.instrumento.nome}"`,
        text:
          "Para realmente excluir este instrumento," +
          " digite EXCLUIR no campo a baixo e pressione confirmar",
        confirmationText: "excluir",
      };
    },
    cabecalhos() {
      return [
        {
          text: "Instrumento",
          value: "nome",
          align: "start",
          filterable: {
            label: "Nome do instrumento",
          },
        },
        {
          text: "Tipo de instrumento",
          value: "tipo",
          filterable: {
            input: "select",
            items: this.tiposInstrumentos.map((ti) => ti.text),
            label: "Tipo do instrumento",
          },
        },
        {
          text: "Estrutura",
          value: "estrutura",
          filterable: {
            label: "Nome da estrutura",
          },
        },
      ];
    },
  },
  methods: {
    ...mapActions("instrumentos", ["delete"]),
    openSecondConfirmation(item) {
      this.instrumento = item;
      this.secondConfirmationDialog = true;
    },
    buscaInstrumentos() {
      authService
        .buscaInstrumentos()
        .then(({ data }) => {
          if (Array.isArray(data)) {
            let dadosTabela = data.forEach((i) =>
              this.instrumentos.unshift(this.instrumentoWithTipo(i))
            );
            dadosTabela = this.instrumentos.sort((a, b) => {
              const nomeA = a.text.toUpperCase(); // ignore upper and lowercase
              const nomeB = b.text.toUpperCase(); // ignore upper and lowercase
              if (nomeA < nomeB) {
                return -1;
              }
              if (nomeA > nomeB) {
                return 1;
              }

              // names must be equal
              return 0;
            });
            return dadosTabela;
          } else {
            return [];
          }
        })
        .catch((err) => {
          console.error(err);
          return err;
        });
    },
    submit() {
      let instrumento = {
        ...this.instrumento,
        instrumento_id: this.instrumento.id,
        cliente_id: localStorage.getItem("cliente_uuid"),
        usuario_id: localStorage.getItem("usuario_uuid"),
      };

      return this.delete(instrumento).then(() =>
        this.markForReloadEstruturas()
      );
    },
  },
};
</script>
