import Vue from "vue";
import VueRouter from "vue-router";

import {
  ensureAuthenticated,
  handleAuthMeta,
  skipAuthenticated,
} from "@/router/authenticate";

import Admin from "@/views/Admin/Admin.vue";
import Cliente from "@/views/Admin/Old_Cadastros/Formularios/Cliente.vue";
import Login from "@/views/Login/Login.vue";

import EstruturaRoutes from "@/views/Admin/Estruturas/router";
import InspecoesRoutes from "@/views/Admin/Inspecoes/router";
import InstrumentoRoutes from "@/views/Admin/Instrumentos/router";
import LeituraRoutes from "@/views/Admin/Leituras/router";
import MinhasEstruturasRoutes from "@/views/Admin/MinhasEstruturas/router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: { template: "Welcome!" },
    beforeEnter: ensureAuthenticated,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    beforeEnter: skipAuthenticated,
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
    meta: { auth: true },
    children: [
      EstruturaRoutes,
      InstrumentoRoutes,
      LeituraRoutes,
      MinhasEstruturasRoutes,
      InspecoesRoutes,
      {
        name: "ClienteAdicionar",
        path: "/cliente/adicionar",
        component: Cliente,
      },
    ],
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

router.beforeEach(handleAuthMeta);

export default router;
