import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAppBar,{attrs:{"color":"white","height":_vm.isMobile ? 60 : 80,"dense":_vm.isMobile,"flat":!_vm.isMobile,"clipped-left":"","app":""}},[_c('router-link',{attrs:{"to":{ name: 'Admin' }}},[(!_vm.isMobile)?_c(VImg,{staticClass:"my-5 ml-2 mr-10",attrs:{"src":require("@/assets/logo-geomin.png"),"max-width":"152px","contain":""}}):_vm._e()],1),_c(VAppBarNavIcon,{on:{"click":function($event){return _vm.$emit('update:drawer', !_vm.drawer)}},scopedSlots:_vm._u([{key:"default",fn:function(){return [(_vm.drawer)?_c(VIcon,[_vm._v(" "+_vm._s(!_vm.isMobile ? "mdi-chevron-double-left" : "")+" ")]):_c(VIcon,{attrs:{"color":_vm.isMobile ? 'primary' : undefined}},[_vm._v(" "+_vm._s(_vm.isMobile ? "mdi-menu" : "mdi-chevron-double-right")+" ")])]},proxy:true}])}),_c('span',{staticClass:"ma-1 text-h6 font-weight-bold"},[_vm._v("Olá, "+_vm._s(_vm.user.name)+"!")]),_c(VSpacer),(!_vm.isMobile)?_c('div',[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('user:logout')}}},[_vm._v("Sair")])],1):_c('div',[_c('router-link',{attrs:{"to":{ name: 'Admin' }}},[_c(VImg,{staticClass:"ma-1",attrs:{"src":require("@/assets/logo-geomin.png"),"max-height":"32px","contain":""}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }