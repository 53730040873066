<template>
  <div></div>
</template>

<script>
export default {
  name: "Pluviometro",
  components: {},
  props: {
    form: {
      default: () => {},
      type: Object,
    },
    tipoAcao: {
      default: "Adicionar",
      type: String,
    },
  },
  data: () => ({
    originalPayload: {},
    payload: {},
    requiredField: [(v) => !!v || "Campo obrigatório"],
  }),
  mounted() {
    this.atualizaCampos();
  },
  computed: {},
  methods: {
    atualizaCampos() {
      this.payload = { ...this.form };
      this.originalPayload = { ...this.form };
    },
    getItemSelecionado(item) {
      return item;
    },
    payloadToParent() {
      this.$emit("fieldsPayload", this.payload);
    },
  },
};
</script>

<style scoped>
.v-text-field {
  border-radius: 10px;
}
</style>
