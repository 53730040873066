<template>
  <v-container class="pa-md-5">
    <v-progress-linear
      v-if="leiturasLoading"
      color="primary"
      top
      absolute
      indeterminate
    />
    <v-row>
      <v-col>
        <span class="text-h5 font-weight-bold minhas-estruturas-title">
          {{ estruturaNome }},
          {{ selectedDashboard.short || selectedDashboard.text }}
        </span>
        <v-menu offset-y :disabled="leiturasLoading">
          <template v-slot:activator="{ on, attrs, value }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon color="primary" large :class="value ? 'open' : 'closed'">
                mdi-chevron-down
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item-group
              v-model="dashboardType"
              color="primary"
              :value-comparator="(a, b) => a && b && a.value === b.value"
            >
              <template v-for="(item, i) in dashboardTypes">
                <v-divider v-if="!item" :key="`div-${i}`" />
                <v-list-item
                  v-else
                  :key="item.value"
                  :value="item"
                  :to="item.route"
                >
                  <v-list-item-title>
                    {{ item.short || item.text }}
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <router-view />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapState } from "vuex";

export const generalDashboards = {
  VisaoGeralDashboard: {
    text: "Visão Geral",
    value: "visao_geral",
    route: { name: "VisaoGeralDashboard" },
  },
  GeneralDashboard: {
    text: "Saúde",
    value: "tela_saude",
    serial_number: "",
    route: { name: "GraficosGeraisDashboard" },
  },
};

const generalDashboardsTypes = Object.values(generalDashboards);

export default {
  name: "MinhasEstruturas",
  props: {
    estruturaId: { type: String, required: true },
    dashboardTypeName: { type: String, required: false },
    serial_number: { type: String },
  },
  data: () => ({
    dashboardType: null,
  }),
  created() {
    if (!this.dashboardTypeName) {
      this.$router.push(this.selectedDashboard.route).catch(() => null);
    }
  },
  computed: {
    ...mapState("instrumentos", {
      tiposInstrumentos: (state) => state.tipos,
    }),
    ...mapState("estruturas", {
      estruturas: (state) => state.estruturas,
    }),
    ...mapState("leituras", {
      leiturasLoading: (state) => state.summaryLoading,
    }),
    ...mapState("inspecoes", {
      leiturasLoading: (state) => state.summaryLoading,
    }),
    selectedDashboard() {
      if (this.dashboardType) return this.dashboardType;
      if (!this.dashboardTypeName) return this.dashboardTypes[5];

      return this.dashboardTypes.find(
        (t) => t && t.value === this.dashboardTypeName
      );
    },
    instrumentoDashboards() {
      return this.tiposInstrumentos.map((ti) => ({
        ...ti,
        route: {
          name: "InstrumentoDashboard",
          params: { tipoInstrumento: ti.value },
        },
      }));
    },
    estrutura() {
      return this.estruturas.find((e) => e.estruturaId === this.estruturaId);
    },
    estruturaNome() {
      return this.estrutura.estruturaNome;
    },
    dashboardTypes() {
      return [...this.instrumentoDashboards, null, ...generalDashboardsTypes];
    },
  },
};
</script>

<style lang="scss" scoped>
.open {
  transform: rotate(-180deg);
}

.closed {
  transform: rotate(0deg);
}

.minhas-estruturas-title {
  vertical-align: middle;
}
</style>
