import { render, staticRenderFns } from "./FormularioInspecao.vue?vue&type=template&id=52b3db84&scoped=true&"
import script from "./FormularioInspecao.vue?vue&type=script&lang=js&"
export * from "./FormularioInspecao.vue?vue&type=script&lang=js&"
import style0 from "./FormularioInspecao.vue?vue&type=style&index=0&id=52b3db84&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52b3db84",
  null
  
)

export default component.exports