<template>
  <v-container class="mt-md-6">
    <v-row class="d-flex justify-center">
      <v-col md="8">
        <FormLogin />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import FormLogin from "@/views/Login/Components/FormLogin.vue";

export default {
  name: "Login",
  components: { FormLogin },
};
</script>
