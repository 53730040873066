import Inspecoes from "./Inspecao.vue";
import InspecaoAdicionar from "./InspecaoAdicionar.vue";
import InspecaoEditar from "./InspecaoEditar.vue";
import InspecaoExcluir from "./InspecaoExcluir.vue";
import InspecaoHistorico from "./InspecaoHistorico.vue";
import InspecaoVisualizar from "./InspecaoVisualizar.vue";

const routes = {
  name: "InspecaoListagem",
  path: "inspecao/",
  component: Inspecoes,
  children: [
    {
      name: "InspecaoAdicionar",
      path: "adicionar",
      component: InspecaoAdicionar,
    },
    {
      name: "InspecaoEditar",
      path: "editar",
      component: InspecaoEditar,
    },
    {
      name: "InspecaoExcluir",
      path: "excluir",
      component: InspecaoExcluir,
    },
    {
      name: "InspecaoHistorico",
      path: "historico",
      component: InspecaoHistorico,
    },
    {
      name: "InspecaoVisualizar",
      path: "visualizar",
      component: InspecaoVisualizar,
    },
  ],
};

export default routes;
