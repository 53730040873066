<script>
import { mapActions, mapGetters, mapState } from "vuex";

const navItems = [
  {
    title: "Minhas estruturas",
    icon: "mdi-account",
    isEstruturas: true,
  },
  {
    title: "Gestão de Ativos",
    icon: "mdi-view-grid-plus-outline",
    list: [
      {
        to: { name: "EstruturaAdicionar" },
        title: "Estrutura",
        icon: "mdi-view-grid-plus-outline",
        color: "gray",
      },
      {
        to: { name: "InstrumentoAdicionar" },
        title: "Instrumentos",
        icon: "mdi-view-grid-plus-outline",
        color: "gray",
      },
    ],
  },
  {
    title: "Gestão de Leituras",
    icon: "mdi-exit-to-app",
    list: [
      {
        to: { name: "LeituraAdicionar" },
        title: "Leitura",
        icon: "mdi-view-grid-plus-outline",
        color: "gray",
      },
      {
        to: { name: "LeituraDownload" },
        title: "Download",
        icon: "mdi-view-grid-plus-outline",
        color: "gray",
      },
    ],
  },
  {
    title: "Gestão de Inspeções",
    icon: "mdi-magnify",
    list: [
      {
        to: { name: "InspecaoAdicionar" },
        title: "Inspecao",
        icon: "mdi-view-grid-plus-outline",
        color: "gray",
      },
    ],
  },
];

export default {
  name: "UserNavigationDrawer",
  data: () => ({ navItems }),
  props: {
    value: { type: Boolean },
    user: { type: Object },
  },
  computed: {
    ...mapGetters("user", ["hasCustomer"]),
    ...mapState("estruturas", {
      estruturas: (state) => state.estruturas,
    }),
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  mounted() {
    this.fetchEstruturas(this.user.id);
  },
  methods: {
    ...mapActions("estruturas", ["fetchEstruturas"]),
    estruturasAsListItems() {
      return (this.estruturas || []).map((e) => ({
        to: e.routes.EstruturaVisualizar,
        title: e.estruturaNome,
        icon: "mdi-view-grid-plus-outline",
        color: "gray",
      }));
    },
  },
};
</script>
<template>
  <v-navigation-drawer
    :value="value"
    @input="$emit('input', $event)"
    width="288"
    clipped
    app
  >
    <v-toolbar v-if="isMobile" class="mt-2" flat dense>
      <v-toolbar-title class="text-subtitle-2">{{ user.name }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon color="grey darken-4" small>mdi-help-circle-outline</v-icon>
      </v-btn>
      <v-btn title="Sair" @click="$emit('user:logout')" icon>
        <v-icon color="grey darken-4" small>mdi-logout</v-icon>
      </v-btn>
      <v-btn title="Fechar" @click="value = false" icon>
        <v-icon color="primary">mdi-menu</v-icon>
      </v-btn>
    </v-toolbar>

    <v-list class="mt-md-8" dense>
      <v-list-group
        v-for="navItem in navItems"
        :key="navItem.title"
        link
        class="mx-1 py-2"
      >
        <template v-slot:activator>
          <v-list-item-icon class="mr-2">
            <v-icon color="black">{{ navItem.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content class="mx-1">
            <v-list-item-title
              class="text-subtitle-1 font-weight-medium"
              v-text="navItem.title"
            />
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="subItem in navItem.isEstruturas
            ? estruturasAsListItems()
            : navItem.list"
          :key="subItem.title"
          class="mx-8"
          :to="subItem.to"
        >
          <v-list-item-icon class="mx-0">
            <v-icon color="#FF8200" small>{{ subItem.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content class="ma-0">
            <v-list-item-title
              v-text="subItem.title"
              class="grey--text text--nav-bar-left text-subtitle-1"
            />
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>
  </v-navigation-drawer>
</template>
