<template>
  <v-container class="pa-md-5">
    <v-row>
      <v-col>
        <h1 class="text-h5 font-weight-bold">Instrumento</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="border--gray">
        <ResourceActionTabs />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <router-view />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import ResourceActionTabs from "@/components/ResourceActionTabs.vue";

export default {
  name: "Instrumento",
  stoggleHidden: false,
  components: {
    ResourceActionTabs,
  },
  data: () => ({
    toggleHidden: false,
    toggle: {
      resumo: true,
      estrutura: false,
      instrumento: false,
      editarEstrutura: false,
      editarInstrumento: false,
    },
  }),
};
</script>
