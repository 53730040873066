<template>
  <div>
    <ResourceTable
      @activated:action="openSecondConfirmation"
      @selected:item="leitura = $event"
      :actionName="acao"
      :items="leituras"
      :tableTitle="tabelaTitulo"
      :headers="cabecalhos"
      :resourceName="tipo"
      :confirmation="deleteConfirmation"
      item-key="id"
    >
      <template v-slot:dialogTitle>
        <span class="d-block">Você está prestes a excluir essa leitura</span>
        <LeituraResumo :leitura="leitura" />
      </template>
    </ResourceTable>
    <ConfirmationDialog
      v-model="secondConfirmationDialog"
      v-bind="secondConfirmation"
      @confirmed="submit"
    >
      <template v-slot:title>
        <span class="d-block">Confirmação de exclusão dessa leitura</span>
        <LeituraResumo :leitura="leitura" />
      </template>
    </ConfirmationDialog>
    <ConfirmationDialog
      v-model="successDialog"
      :title="successDialogTitle"
      theme="success"
      confirm="Fechar"
      auto-close
    />
    <ConfirmationDialog
      v-model="failureDialog"
      :title="failureDialogTitle"
      :text="failureDialogText"
      theme="error"
      confirm="Fechar"
    />
  </div>
</template>

<script>
import ConfirmationDialog from "@/components/ConfirmationDialog.vue";
import ResourceTable from "@/components/ResourceTable.vue";
import { AuthService } from "@/helpers/services";
import ResourceActionMixin from "@/mixins/ResourceActionMixin";
import moment from "moment";
import { mapActions, mapState } from "vuex";
import LeituraResumo from "./Components/LeituraResumo.vue";

const authService = new AuthService();
export default {
  name: "LeituraExcluir",
  components: { ResourceTable, ConfirmationDialog, LeituraResumo },
  mixins: [ResourceActionMixin],
  data: () => ({
    secondConfirmationDialog: false,
    acao: "excluir",
    tipo: "leitura",
    tabelaTitulo: "Selecione qual leitura gostaria de excluir",
    toggleHidden: true,
    instrumentoAdicionarSubmit: "Adicionar instrumento",
    instrumentoSelecionado: "Piziometro",
    leituras: [],
    leitura: {},
    render: 0,
  }),
  mounted() {
    this.toggleHidden = true;
    this.buscaLeituras();
  },
  computed: {
    ...mapState("leituras", {
      failure: (state) => state.deleteFailure,
      success: (state) => state.deleteSuccess,
    }),
    resourceNameActionSuccess() {
      return "Leitura excluída";
    },
    resourceNameActionFailure() {
      return "excluir essa leitura";
    },
    failureDialogText() {
      return "Tente novamente mais tarde";
    },
    deleteConfirmation() {
      return {
        theme: "warning",
        confirm: "Sim, quero excluir",
        cancel: "Não tenho certeza",
      };
    },
    secondConfirmation() {
      return {
        theme: "info",
        text:
          "Para realmente excluir esta leitura," +
          " digite EXCLUIR no campo a baixo e pressione confirmar",
        confirmationText: "excluir",
      };
    },
    leituraString() {
      return `${this.leitura.estrutura}: ${this.leitura.instrumento} [${this.leitura.data} ${this.leitura.hora}] ${this.leitura.leitura}`;
    },
    cabecalhos() {
      return [
        {
          text: "Data da leitura",
          align: "start",
          value: "data",
          filterable: {
            input: "date",
            label: "Data da leitura",
          },
        },
        {
          text: "Hora da leitura",
          value: "hora",
          filterable: {
            input: "text",
            label: "Hora da leitura",
          },
        },
        {
          text: "Instrumento",
          value: "instrumento",
          filterable: true,
        },
        {
          text: "Estrutura",
          value: "estrutura",
          filterable: {
            label: "Nome da estrutura",
          },
        },
        {
          text: "Responsável",
          value: "responsavel",
          filterable: true,
        },
        {
          text: "Leitura",
          value: "leitura",
        },
      ];
    },
  },
  methods: {
    ...mapActions("leituras", ["delete"]),
    openSecondConfirmation(item) {
      this.leitura = item;
      this.secondConfirmationDialog = true;
    },
    toggleShow() {
      this.toggleHidden = !this.toggleHidden;
    },
    buscaLeituras() {
      authService
        .buscaLeituras()
        .then(({ data }) => {
          for (let i in data) {
            let _date = new Date(data[i].data_leitura);
            this.leituras.push({
              ...data[i],
              text: data[i].nome,
              name: data[i].nome,
              value: data[i].index,
              uuid: data[i].uuid,
              data: moment(_date).format("YYYY-MM-DD"),
              hora: moment(_date).format("hh:mm"),
            });
          }
          // instrumentos (ultimo)
          let dadosTabelas = this.leituras.sort((a, b) => {
            const nomeA = a.instrumento.toUpperCase(); // ignore upper and lowercase
            const nomeB = b.instrumento.toUpperCase(); // ignore upper and lowercase
            if (nomeA < nomeB) {
              return -1;
            }
            if (nomeA > nomeB) {
              return 1;
            }

            // names must be equal
            return 0;
          });
          // estruturas (penultimo)
          dadosTabelas = dadosTabelas.sort((a, b) => {
            const nomeA = a.estrutura.toUpperCase(); // ignore upper and lowercase
            const nomeB = b.estrutura.toUpperCase(); // ignore upper and lowercase
            if (nomeA < nomeB) {
              return -1;
            }
            if (nomeA > nomeB) {
              return 1;
            }

            // names must be equal
            return 0;
          });
          // horas (segundo)
          dadosTabelas = dadosTabelas.sort((a, b) => {
            const nomeA = a.hora.toUpperCase(); // ignore upper and lowercase
            const nomeB = b.hora.toUpperCase(); // ignore upper and lowercase
            if (nomeA > nomeB) {
              return -1;
            }
            if (nomeA < nomeB) {
              return 1;
            }

            // names must be equal
            return 0;
          });
          // data (primeiro)
          dadosTabelas = dadosTabelas.sort((a, b) => {
            const nomeA = a.data.toUpperCase(); // ignore upper and lowercase
            const nomeB = b.data.toUpperCase(); // ignore upper and lowercase
            if (nomeA > nomeB) {
              return -1;
            }
            if (nomeA < nomeB) {
              return 1;
            }

            // names must be equal
            return 0;
          });

          return dadosTabelas;
        })
        .catch((err) => {
          console.error(err);
          return err;
        });
    },
    submit() {
      let leitura = {
        ...this.leitura,
        leitura_id: this.leitura.id,
        cliente_id: localStorage.getItem("cliente_uuid"),
        usuario_id: localStorage.getItem("usuario_uuid"),
      };

      return this.delete(leitura);
    },
  },
};
</script>
