<template>
  <v-container v-if="!toggleHidden">
    <v-row>
      <v-col>
        <h2 class="text-h6 font-weight-bold pb-7">
          Preencha os dados abaixo para editar uma leitura
        </h2>
      </v-col>
    </v-row>
    <Formulario
      ref="form"
      :form="leitura"
      :tipoAcao="'Editar'"
      :key="render"
      @enviaFormulario="submit"
    />
    <ConfirmationDialog
      v-model="successDialog"
      :title="successDialogTitle"
      theme="success"
      confirm="Fechar"
      auto-close
    />
    <ConfirmationDialog
      v-model="failureDialog"
      :title="failureDialogTitle"
      :text="failureDialogText"
      theme="error"
      confirm="Fechar"
    />
  </v-container>
  <ResourceTable
    v-else
    @activated:action="editarLeitura"
    :actionName="acao"
    :items="leituras"
    :tableTitle="tabelaTitulo"
    :headers="cabecalhos"
    :resourceName="tipo"
    item-key="id"
  />
</template>

<script>
import ConfirmationDialog from "@/components/ConfirmationDialog.vue";
import ResourceTable from "@/components/ResourceTable.vue";
import { AuthService } from "@/helpers/services";
import ResourceActionMixin from "@/mixins/ResourceActionMixin";
import moment from "moment";
import { mapActions, mapState } from "vuex";
import Formulario from "./Components/FormularioLeitura.vue";

const authService = new AuthService();
export default {
  name: "LeituraEditar",
  components: { Formulario, ResourceTable, ConfirmationDialog },
  mixins: [ResourceActionMixin],
  data: () => ({
    acao: "editar",
    tipo: "leitura",
    tabelaTitulo: "Selecione qual leitura gostaria de editar",
    toggleHidden: true,
    instrumentoAdicionarSubmit: "Adicionar instrumento",
    instrumentoSelecionado: "Piziometro",
    leituras: [],
    leitura: {},
    render: 0,
  }),
  computed: {
    ...mapState("leituras", {
      failure: (state) => state.editFailure,
      success: (state) => state.editSuccess,
    }),
    resourceNameActionSuccess() {
      return "Leitura atualizada";
    },
    resourceNameActionFailure() {
      return "atualizar essa leitura";
    },
    cabecalhos() {
      return [
        {
          text: "Data da leitura",
          align: "start",
          value: "data_leitura",
          filterable: {
            input: "date",
            label: "Data da leitura",
          },
        },
        {
          text: "Hora da leitura",
          value: "hora_leitura",
          filterable: {
            input: "text",
            label: "Hora da leitura",
          },
        },
        {
          text: "Instrumento",
          value: "instrumento",
          filterable: true,
        },
        {
          text: "Estrutura",
          value: "estrutura",
          filterable: {
            label: "Nome da estrutura",
          },
        },
        {
          text: "Responsável",
          value: "responsavel",
          filterable: true,
        },
        {
          text: "Leitura",
          value: "leitura",
        },
      ];
    },
  },
  mounted() {
    this.toggleHidden = true;
    this.buscaLeituras();
  },
  methods: {
    ...mapActions("leituras", ["edit"]),
    editarLeitura(payload) {
      this.toggleShow();
      this.leitura = { ...payload };
      return payload;
      // this.estrutura((e) => (e = payload[i].value));
    },
    toggleShow() {
      this.toggleHidden = !this.toggleHidden;
    },
    buscaLeituras() {
      authService
        .buscaLeituras()
        .then(({ data }) => {
          for (let i in data) {
            let _date = new Date(data[i].data_leitura);
            this.leituras.push({
              ...data[i],
              data_leitura: moment(_date).format("YYYY-MM-DD"),
              hora_leitura: moment(_date).format("hh:mm"),
            });
          }
          // instrumentos (ultimo)
          let dadosTabelas = this.leituras.sort((a, b) => {
            const nomeA = a.instrumento.toUpperCase(); // ignore upper and lowercase
            const nomeB = b.instrumento.toUpperCase(); // ignore upper and lowercase
            if (nomeA < nomeB) {
              return -1;
            }
            if (nomeA > nomeB) {
              return 1;
            }

            // names must be equal
            return 0;
          });
          // estruturas (penultimo)
          dadosTabelas = this.leituras.sort((a, b) => {
            const nomeA = a.estrutura.toUpperCase(); // ignore upper and lowercase
            const nomeB = b.estrutura.toUpperCase(); // ignore upper and lowercase
            if (nomeA < nomeB) {
              return -1;
            }
            if (nomeA > nomeB) {
              return 1;
            }

            // names must be equal
            return 0;
          });
          // horas (segundo)
          dadosTabelas = this.leituras.sort((a, b) => {
            const nomeA = a.hora_leitura.toUpperCase(); // ignore upper and lowercase
            const nomeB = b.hora_leitura.toUpperCase(); // ignore upper and lowercase
            if (nomeA > nomeB) {
              return -1;
            }
            if (nomeA < nomeB) {
              return 1;
            }

            // names must be equal
            return 0;
          });
          // data (primeiro)
          dadosTabelas = this.leituras.sort((a, b) => {
            const nomeA = a.data_leitura.toUpperCase(); // ignore upper and lowercase
            const nomeB = b.data_leitura.toUpperCase(); // ignore upper and lowercase
            if (nomeA > nomeB) {
              return -1;
            }
            if (nomeA < nomeB) {
              return 1;
            }

            // names must be equal
            return 0;
          });

          return dadosTabelas;
        })
        .catch((err) => {
          console.error(err);
          return err;
        });
    },
    submit(payload) {
      let leitura = {
        ...payload,
        cliente_id: localStorage.getItem("cliente_uuid"),
        usuario_id: localStorage.getItem("usuario_uuid"),
      };
      return this.edit(leitura);
    },
  },
};
</script>
