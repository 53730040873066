import axios from "axios";
// import {eventBus} from '@/plugins/eventBus'

export default (options = {}) => {
  const http = axios.create(options);

  http.defaults.headers.post["Content-Type"] = "application/json";

  return http;
};
