<template>
  <v-simple-table dense class="leitura-resumo-table">
    <tbody>
      <tr>
        <td class="text-caption font-weight-bold">Estrutura</td>
        <td class="ml-1 text-caption">{{ leitura.estrutura }}</td>
      </tr>
      <tr>
        <td class="text-caption font-weight-bold">Instrumento</td>
        <td class="ml-1 text-caption">{{ leitura.instrumento }}</td>
      </tr>
      <tr>
        <td class="text-caption font-weight-bold">Leitura</td>
        <td class="ml-1 text-caption">
          [{{ leitura.data }} {{ leitura.hora }}]
          {{ leitura.leitura }}
        </td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
export default {
  name: "LeituraResumo",
  props: { leitura: Object },
};
</script>

<style scoped lang="scss">
.leitura-resumo-table {
  tr > td {
    text-align: right;
    & + td {
      text-align: left;
    }
  }
}
</style>
