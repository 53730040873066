import { mapActions } from "vuex";

export default {
  data: () => ({
    successDialog: false,
    failureDialog: false,
  }),
  computed: {
    successDialogTitle() {
      return `${this.resourceNameActionSuccess} com sucesso!`;
    },
    failureDialogTitle() {
      return `Não foi possivel ${this.resourceNameActionFailure}`;
    },
    failureDialogText() {
      return "Verifique os campos e tente novamente";
    },
  },
  watch: {
    success(val) {
      if (val) {
        this.successDialog = true;

        const form = this.$refs.form;
        if (form) {
          form.reset();
        }
      }
    },
    failure(val) {
      if (val) {
        this.failureDialog = true;

        const form = this.$refs.form;
        if (form) {
          form.validate();
        } else {
          console.log(`resource action failed: ${val}`);
        }
      }
    },
    successDialog(newVal, oldVal) {
      if (oldVal == true && newVal == false) {
        if (!this.$refs.form) this.$router.go();
      }
    },
  },
  methods: {
    ...mapActions("estruturas", {
      markForReloadEstruturas: "reloadEstruturas",
    }),
  },
};
