<template>
  <ResourceTable
    :actionName="acao"
    :items="inspecoes"
    :tableTitle="tabelaTitulo"
    :headers="cabecalhos"
    :resourceName="tipo"
    :showSelect="false"
    item-key="id"
  />
</template>

<script>
import ResourceTable, {
  historicoActionsNames,
} from "@/components/ResourceTable.vue";
import { AuthService } from "@/helpers/services";
import moment from "moment";

const authService = new AuthService();
export default {
  name: "InspecoesHistorico",
  stoggleHidden: false,
  components: {
    ResourceTable,
  },
  data: () => ({
    acao: "historico",
    tipo: "inspeção",
    tabelaTitulo: "Histórico de alterações nas inspeções",
    inspecoes: [],
    render: 0,
  }),
  computed: {
    cabecalhos() {
      return [
        {
          text: "Data da Inspeção",
          align: "start",
          value: "data_cadastro",
          filterable: {
            input: "date",
            label: "Data da inspeção",
          },
        },
        {
          text: "Instrumento",
          value: "instrumento_nome",
          align: "start",
          filterable: {
            label: "Nome do instrumento",
          },
        },
        {
          text: "Estrutura",
          value: "estrutura_nome",
          align: "start",
          filterable: {
            label: "Nome da estrutura",
          },
        },
        {
          text: "Responsável",
          value: "responsavel",
          filterable: true,
        },
        {
          text: "Ação",
          value: "acao",
          filterable: {
            input: "select",
            items: historicoActionsNames,
            label: "Ação",
          },
        },
        {
          text: "Título da inspeção",
          value: "conteudo.titulo",
          filterable: true,
        },
      ];
    },
  },
  mounted() {
    this.buscaHistorico();
  },
  methods: {
    buscaHistorico() {
      authService
        .buscaHistorico("Inspecao")
        .then(({ data }) => {
          let payload = data.data;
          for (let i in payload) {
            let _date = new Date(payload[i].data_cadastro);
            let inspecao = this.inspecaoFromHistorico(payload[i].conteudo);
            let payloadJson = JSON.parse(inspecao.payload);

            this.inspecoes.push({
              ...payload[i],
              conteudo: {
                inspecao: inspecao,
                dataHora: inspecao.dataHora,
                titulo: payloadJson.titulo,
              },
              text: payload[i].nome,
              name: payload[i].nome,
              value: payload[i].id,
              uuid: payload[i].id,
              data_cadastro: moment(_date).format("YYYY-MM-DD hh:mm:ss"),
            });
          }
          this.inspecoes.reverse();
          return payload;
        })
        .catch((err) => {
          console.error(err);
          return err;
        });
    },
    inspecaoFromHistorico(payload) {
      return { payload };
    },
  },
};
</script>

<style></style>
