import { AuthService } from "@/helpers/services";
const authService = new AuthService();

function instrumentosFromRequest(payloads) {
  if (payloads && Array.isArray(payloads)) {
    return payloads.reduce((grouped, data) => {
      const instruments = grouped[data.nome] || [];
      grouped[data.nome] = instruments;

      for (const i of data.instrumentos) {
        instruments.push({
          instrumentoId: i.id,
          instrumentoNome: i.nome,
          instrumentoTipe: i.tipo_instrumento,
          estruturaId: i.estrutura_id,
          estruturaNome: i.estrutura,
        });
      }

      return grouped;
    }, {});
  }

  return {};
}

function estruturaFromRequest(payloads) {
  if (payloads && Array.isArray(payloads)) {
    const alphabeticPayload = payloads.sort((a, b) => {
      const nomeA = a.nome.toUpperCase(); // ignore upper and lowercase
      const nomeB = b.nome.toUpperCase(); // ignore upper and lowercase
      if (nomeA < nomeB) {
        return -1;
      }
      if (nomeA > nomeB) {
        return 1;
      }

      // names must be equal
      return 0;
    });

    return alphabeticPayload.map((payload) => ({
      clienteId: payload.cliente_id,
      estruturaId: payload.estrutura_id,
      estruturaNome: payload.nome,
      routes: {
        EstruturaVisualizar: {
          name: "EstruturaVisualizar",
          params: { estruturaId: payload.estrutura_id },
        },
      },
      instrumentosGroupedByName: instrumentosFromRequest(
        payload.tipo_instrumento
      ),
    }));
  }
}

export default {
  namespaced: true,
  state: () => ({
    loading: false,
    failure: null,
    estruturas: [],
    reload: false,

    creating: false,
    createFailure: null,
    createSuccess: null,

    editing: false,
    editFailure: null,
    editSuccess: null,

    deleting: false,
    deleteFailure: null,
    deleteSuccess: null,
  }),
  mutations: {
    ESTRUTURA_RELOAD: (state) => {
      state.reload = true;
    },
    ESTRUTURA_START: (state) => {
      state.loading = true;
    },
    ESTRUTURA_FAILURE: (state, error) => {
      state.loading = false;
      state.failure = error;
      state.estruturas = null;
      state.reload = false;
    },
    ESTRUTURA_SUCCESS: (state, estruturas) => {
      state.loading = false;
      state.failure = null;
      state.estruturas = estruturas;
      state.reload = false;
    },
    CREATE_START: (state) => {
      state.creating = true;
      state.createFailure = null;
      state.createSuccess = null;
    },
    CREATE_FAILURE: (state, error) => {
      state.creating = false;
      state.createFailure = error;
    },
    CREATE_SUCCESS: (state, response) => {
      state.creating = false;
      state.createFailure = null;
      state.createSuccess = response;
    },
    EDIT_START: (state) => {
      state.editing = true;
      state.editFailure = null;
      state.editSuccess = null;
    },
    EDIT_FAILURE: (state, error) => {
      state.editing = false;
      state.editFailure = error;
    },
    EDIT_SUCCESS: (state, response) => {
      state.editing = false;
      state.editFailure = null;
      state.editSuccess = response;
    },
    DELETE_START: (state) => {
      state.deleting = true;
      state.deleteFailure = null;
      state.deleteSuccess = null;
    },
    DELETE_FAILURE: (state, error) => {
      state.deleting = false;
      state.deleteFailure = error;
    },
    DELETE_SUCCESS: (state, response) => {
      state.deleting = false;
      state.deleteFailure = null;
      state.deleteSuccess = response;
    },
  },
  actions: {
    async reloadEstruturas({ commit }) {
      return commit("ESTRUTURA_RELOAD");
    },
    async fetchEstruturas({ commit }, userId) {
      commit("ESTRUTURA_START");

      try {
        const result = await authService.getClientResume(userId);

        if (result.data.errorMessage) {
          return commit("ESTRUTURA_FAILURE", result.data.errorMessage);
        }

        const message = result.data?.message;
        const estruturas = estruturaFromRequest(result.data?.data);

        return estruturas
          ? commit("ESTRUTURA_SUCCESS", estruturas)
          : commit(
              "ESTRUTURA_FAILURE",
              new Error(message || "Invalid response")
            );
      } catch (err) {
        console.error(err);
        return commit("ESTRUTURA_FAILURE", err);
      }
    },
    async create({ commit }, payload) {
      commit("CREATE_START");

      try {
        const result = await authService.gravaEstrutura(payload);
        const message = result?.data?.message;

        return message
          ? commit("CREATE_SUCCESS", result.data.message)
          : commit("CREATE_FAILURE", new Error(message || "Invalid response"));
      } catch (err) {
        console.log(err);
        return commit("CREATE_FAILURE", err);
      }
    },
    async edit({ commit }, payload) {
      commit("EDIT_START");

      try {
        const result = await authService.editaEstrutura(payload);
        const message = result?.data?.message;

        return message
          ? commit("EDIT_SUCCESS", result.data.message)
          : commit("EDIT_FAILURE", new Error(message || "Invalid response"));
      } catch (err) {
        console.log(err);
        return commit("EDIT_FAILURE", err);
      }
    },
    async delete({ commit }, payload) {
      commit("DELETE_START");

      try {
        const result = await authService.deletaEstrutura(payload);
        const message = result?.data?.message;

        return message
          ? commit("DELETE_SUCCESS", result.data.message)
          : commit("DELETE_FAILURE", new Error(message || "Invalid response"));
      } catch (err) {
        console.log(err);
        return commit("DELETE_FAILURE", err);
      }
    },
  },
  getters: {},
};
