import store from "@/store";
import GraficosGeraisDashboard from "./GraficosGeraisDashboard.vue";
import InstrumentoDashboard from "./InstrumentoDashboard.vue";
import MinhasEstruturas, { generalDashboards } from "./MinhasEstruturas.vue";
import VisaoGeralDashboard from "./VisaoGeralDashboard.vue";

export default {
  name: "EstruturaVisualizar",
  path: "minhas-estruturas/:estruturaId/",
  component: MinhasEstruturas,
  props: (route) => {
    let selectedDashboard = {};
    switch (route.name) {
      case "InstrumentoDashboard":
        selectedDashboard = {
          dashboardTypeName: route.params.tipoInstrumento,
        };
        break;
      case "VisaoGeralDashboard":
        selectedDashboard = {
          dashboardTypeName: generalDashboards.VisaoGeralDashboard.value,
        };
        break;
      case "GraficosGeraisDashboard":
        selectedDashboard = {
          dashboardTypeName: generalDashboards.GeneralDashboard.value,
        };
        break;
    }

    return {
      ...selectedDashboard,
      estruturaId: route.params.estruturaId,
    };
  },
  beforeEnter: async (_to, _from, next) => {
    if (store.state.estruturas.reload) {
      store.dispatch("estruturas/fetchEstruturas").then(() => next());
    } else {
      next();
    }
  },
  children: [
    {
      name: "InstrumentoDashboard",
      path: "instrumento/:tipoInstrumento/visualizar",
      component: InstrumentoDashboard,
      props: (route) => ({
        estruturaId: route.params.estruturaId,
        tipoInstrumentoId: route.params.tipoInstrumento,
      }),
    },
    {
      name: "VisaoGeralDashboard",
      path: "visaogeral/visualizar",
      component: VisaoGeralDashboard,
      props: (route) => ({
        estruturaId: route.params.estruturaId,
      }),
    },
    {
      name: "GraficosGeraisDashboard",
      path: "general/visualizar",
      component: GraficosGeraisDashboard,
      props: (route) => ({
        estruturaId: route.params.estruturaId,
      }),
    },
  ],
};
