import Vue from "vue";
import Vuex from "vuex";

import estruturas from "@/store/estruturas";
import inspecoes from "@/store/inspecoes";
import instrumentos from "@/store/instrumentos";
import leituras from "@/store/leituras";
import user from "@/store/user";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: { estruturas, instrumentos, leituras, user, inspecoes },
});
