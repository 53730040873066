import LeituraAdicionar from "./LeituraAdicionar.vue";
import LeituraDownload from "./LeituraDownload.vue";
import LeituraEditar from "./LeituraEditar.vue";
import LeituraExcluir from "./LeituraExcluir.vue";
import LeituraHistorico from "./LeituraHistorico.vue";
import Leituras from "./Leituras.vue";

const routes = {
  name: "LeituraListagem",
  path: "leitura/",
  component: Leituras,
  children: [
    {
      name: "LeituraAdicionar",
      path: "adicionar",
      component: LeituraAdicionar,
    },
    {
      name: "LeituraEditar",
      path: "editar",
      component: LeituraEditar,
    },
    {
      name: "LeituraExcluir",
      path: "excluir",
      component: LeituraExcluir,
    },
    {
      name: "LeituraHistorico",
      path: "historico",
      component: LeituraHistorico,
    },
    {
      name: "LeituraDownload",
      path: "download",
      component: LeituraDownload,
    },
  ],
};

export default routes;
