import { AuthService } from "@/helpers/services";
const authService = new AuthService();

export default {
  namespaced: true,
  state: () => ({
    summaryLoading: false,
    summaryFailure: null,
    summarySuccess: null,

    creating: false,
    createFailure: null,
    createSuccess: null,

    editing: false,
    editFailure: null,
    editSuccess: null,

    deleting: false,
    deleteFailure: null,
    deleteSuccess: null,

    downloading: false,
    downloadFailure: null,
    downloadSuccess: null,
  }),
  mutations: {
    SUMMARY_START: (state) => {
      state.summaryLoading = true;
    },
    SUMMARY_FAILURE: (state, error) => {
      state.summaryLoading = false;
      state.summaryFailure = error;
    },
    SUMMARY_SUCCESS: (state, response) => {
      state.summaryLoading = false;
      state.summaryFailure = null;
      state.summarySuccess = response;
    },
    CREATE_START: (state) => {
      state.creating = true;
      state.createFailure = null;
      state.createSuccess = null;
    },
    CREATE_FAILURE: (state, error) => {
      state.creating = false;
      state.createFailure = error;
    },
    CREATE_SUCCESS: (state, response) => {
      state.creating = false;
      state.createFailure = null;
      state.createSuccess = response;
    },
    EDIT_START: (state) => {
      state.editing = true;
      state.editFailure = null;
      state.editSuccess = null;
    },
    EDIT_FAILURE: (state, error) => {
      state.editing = false;
      state.editFailure = error;
    },
    EDIT_SUCCESS: (state, response) => {
      state.editing = false;
      state.editFailure = null;
      state.editSuccess = response;
    },
    DELETE_START: (state) => {
      state.deleting = true;
      state.deleteFailure = null;
      state.deleteSuccess = null;
    },
    DELETE_FAILURE: (state, error) => {
      state.deleting = false;
      state.deleteFailure = error;
    },
    DELETE_SUCCESS: (state, response) => {
      state.deleting = false;
      state.deleteFailure = null;
      state.deleteSuccess = response;
    },
    DOWNLOAD_START: (state) => {
      state.downloading = true;
      state.downloadFailure = null;
      state.downloadSuccess = null;
    },
    DOWNLOAD_FAILURE: (state, error) => {
      state.downloading = false;
      state.downloadFailure = error;
    },
    DOWNLOAD_SUCCESS: (state, response) => {
      state.downloading = false;
      state.downloadFailure = null;
      state.downloadSuccess = response;
    },
  },
  actions: {
    async fetchSummary({ commit }, payload) {
      commit("SUMMARY_START");

      try {
        // chamada para grafico no DynamoDB (somente leitores automaticos)
        if (payload.tipo_instrumento === "tela_saude") {
          const result = await authService.getLeituraDynamoPorGrafico(payload);
          const message = result.message || result.data?.message;
          const summary = result.data?.data;

          if (summary.errorMessage) {
            return commit("SUMMARY_FAILURE", new Error(summary.errorMessage));
          }

          return summary
            ? commit("SUMMARY_SUCCESS", summary)
            : commit(
                "SUMMARY_FAILURE",
                new Error(message || "Invalid response")
              );
        }

        // chamada para grafico no postgres
        const result = await authService.getLeituraResumo(payload);
        const message = result.message || result.data?.message;
        const summary = result.data?.data;
        if (summary.errorMessage) {
          return commit("SUMMARY_FAILURE", new Error(summary.errorMessage));
        }
        return summary
          ? commit("SUMMARY_SUCCESS", summary)
          : commit("SUMMARY_FAILURE", new Error(message || "Invalid response"));
      } catch (err) {
        console.log(err);
        return commit("SUMMARY_FAILURE", err);
      }
    },
    async create({ commit }, payload) {
      commit("CREATE_START");

      try {
        const result = await authService.gravaLeitura(payload);
        const message = result?.data?.message;

        return message
          ? commit("CREATE_SUCCESS", result.data.message)
          : commit("CREATE_FAILURE", new Error(message || "Invalid response"));
      } catch (err) {
        console.log(err);
        return commit("CREATE_FAILURE", err);
      }
    },
    async edit({ commit }, payload) {
      commit("EDIT_START");

      try {
        const result = await authService.editaLeitura(payload);
        const message = result?.data?.message;

        return message
          ? commit("EDIT_SUCCESS", result.data.message)
          : commit("EDIT_FAILURE", new Error(message || "Invalid response"));
      } catch (err) {
        console.log(err);
        return commit("EDIT_FAILURE", err);
      }
    },
    async delete({ commit }, payload) {
      commit("DELETE_START");

      try {
        const result = await authService.deletaLeitura(payload);
        const message = result?.data?.message;

        return message
          ? commit("DELETE_SUCCESS", result.data.message)
          : commit("DELETE_FAILURE", new Error(message || "Invalid response"));
      } catch (err) {
        console.log(err);
        return commit("DELETE_FAILURE", err);
      }
    },
    async download({ commit }, payload) {
      commit("DOWNLOAD_START");

      try {
        const result = await authService.getLeituraResumo(payload);
        const message = result.message || result.data?.message;
        return message
          ? commit("DOWNLOAD_SUCCESS", result.data.message)
          : commit(
              "DOWNLOAD_FAILURE",
              new Error(message || "Invalid response")
            );
      } catch (err) {
        console.log(err);
        return commit("DOWNLOAD_FAILURE", err);
      }
    },
  },
};
