<template>
  <ResourceTable
    :actionName="acao"
    :items="estruturas"
    :tableTitle="tabelaTitulo"
    :headers="cabecalhos"
    :resourceName="tipo"
    :showSelect="false"
    item-key="name"
  />
</template>

<script>
import { AuthService } from "@/helpers/services";
import ResourceTable, {
  historicoActionsNames,
} from "@/components/ResourceTable.vue";
import moment from "moment";

const authService = new AuthService();
export default {
  components: {
    ResourceTable,
  },
  data: () => ({
    acao: "historico",
    tipo: "estrutura",
    tabelaTitulo: "Histórico de alterações nas estruturas",
    cliente_id: localStorage.getItem("cliente_uuid"),
    usuario_id: localStorage.getItem("usuario_uuid"),
    estruturas: [],
    minhasEstruturas: [],
    render: 0,
  }),
  mounted() {
    this.buscaHistorico();
  },
  computed: {
    cabecalhos() {
      return [
        {
          text: "Estrutura",
          value: "estrutura_nome",
          align: "start",
          filterable: {
            label: "Nome da estrutura",
          },
        },
        {
          text: "Ação",
          value: "acao",
          filterable: {
            input: "select",
            items: historicoActionsNames,
            label: "Ação",
          },
        },
        {
          text: "Responsável",
          value: "responsavel",
          filterable: {
            label: "Nome do responsável",
          },
        },
        {
          text: "Data do registro",
          value: "data_cadastro",
          filterable: {
            input: "date",
            label: "Data da alteração",
          },
        },
      ];
    },
  },
  methods: {
    buscaHistorico() {
      authService
        .buscaHistorico("Estrutura")
        .then(({ data }) => {
          let payload = data.data;
          for (let i in payload) {
            let _date = new Date(payload[i].data_cadastro);
            this.estruturas.push({
              ...payload[i],
              text: payload[i].nome,
              name: payload[i].nome,
              value: payload[i].id,
              uuid: payload[i].id,
              data_cadastro: moment(_date).format("YYYY-MM-DD hh:mm"),
            });
          }
          this.estruturas.reverse();
          return payload;
        })
        .catch((err) => {
          console.error(err);
          return err;
        });
    },
  },
};
</script>

<style></style>
