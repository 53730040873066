<template>
  <v-container v-if="!toggleHidden">
    <v-row>
      <v-col>
        <h2 class="text-h6 font-weight-bold pb-7">Visualizar inspeção</h2>
      </v-col>
    </v-row>
    <Formulario
      ref="form"
      :form="inspecao"
      theme="success"
      confirm="Fechar"
      auto-close
      @toggle="toggleShow"
    />
  </v-container>
  <ResourceTable
    v-else
    @activated:action="visualizarInspecao"
    :actionName="acao"
    :items="inspecoes"
    :tableTitle="tabelaTitulo"
    :headers="cabecalhos"
    :resourceName="tipo"
    item-key="id"
  />
</template>

<script>
import ResourceTable from "@/components/ResourceTable.vue";
import { AuthService } from "@/helpers/services";
import ResourceActionMixin from "@/mixins/ResourceActionMixin";
import moment from "moment";
import { mapState } from "vuex";
import Formulario from "./Components/FormularioVisualizar.vue";

const authService = new AuthService();

export default {
  name: "InspecaoVisualizar",
  components: { Formulario, ResourceTable },
  mixins: [ResourceActionMixin],
  data: () => ({
    acao: "visualizar",
    tipo: "inspeção",
    tabelaTitulo: "Selecione qual inspeção gostaria de visualizar",
    toggleHidden: true,
    instrumentoSelecionado: "Piziometro",
    inspecoes: [],
    inspecao: {},
    render: 0,
  }),
  computed: {
    ...mapState("inspecoes", {
      failure: (state) => state.editFailure,
      success: (state) => state.editSuccess,
    }),
    resourceNameActionSuccess() {
      return "Inspeção atualizada";
    },
    resourceNameActionFailure() {
      return "atualizar essa inspeção";
    },
    cabecalhos() {
      return [
        {
          text: "Data da inspeção",
          align: "start",
          value: "data_inspecao",
          filterable: {
            input: "date",
            label: "Data da inspeção",
          },
        },
        {
          text: "Instrumento",
          value: "instrumento",
          filterable: { label: "Nome do instrumento" },
        },
        {
          text: "Estrutura",
          value: "estrutura",
          filterable: {
            label: "Nome da estrutura",
          },
        },
        {
          text: "Responsável",
          value: "responsavel",
          filterable: true,
        },
        {
          text: "Título da inspeção",
          value: "inspecao",
          filterable: true,
        },
      ];
    },
  },
  mounted() {
    this.toggleHidden = true;
    this.buscaInspecoes();
  },
  methods: {
    async visualizarInspecao(payload) {
      const inspecao = await this.buscaInspecoesById(payload.id);
      inspecao.data_inspecao = payload.data_inspecao;
      this.toggleShow();
      this.inspecao = { ...payload, ...inspecao };
      return payload;
    },
    toggleShow() {
      this.toggleHidden = !this.toggleHidden;
    },
    buscaInspecoes() {
      let client_id = localStorage.getItem("cliente_uuid");

      authService
        .findInspecoes(client_id)
        .then(({ data }) => {
          for (let i in data) {
            this.inspecoes.push({
              data_inspecao: moment(data[i].data_inspecao).format(
                "YYYY-MM-DD HH:mm:ss"
              ),
              instrumento: data[i].instrumento_nome,
              estrutura: data[i].nome_estrutura,
              responsavel: data[i].responsavel,
              inspecao: data[i].titulo,
              id: data[i].id,
            });
          }
          // instrumentos (ultimo)
          let dadosTabelas = this.inspecoes.sort((a, b) => {
            const nomeA = a.instrumento.toUpperCase(); // ignore upper and lowercase
            const nomeB = b.instrumento.toUpperCase(); // ignore upper and lowercase
            if (nomeA < nomeB) {
              return -1;
            }
            if (nomeA > nomeB) {
              return 1;
            }

            // names must be equal
            return 0;
          });
          // estruturas (penultimo)
          dadosTabelas = this.inspecoes.sort((a, b) => {
            const nomeA = a.estrutura.toUpperCase(); // ignore upper and lowercase
            const nomeB = b.estrutura.toUpperCase(); // ignore upper and lowercase
            if (nomeA < nomeB) {
              return -1;
            }
            if (nomeA > nomeB) {
              return 1;
            }

            // names must be equal
            return 0;
          });

          // data (primeiro)
          dadosTabelas = this.inspecoes.sort((a, b) => {
            const nomeA = a.data_inspecao.toUpperCase(); // ignore upper and lowercase
            const nomeB = b.data_inspecao.toUpperCase(); // ignore upper and lowercase
            if (nomeA > nomeB) {
              return -1;
            }
            if (nomeA < nomeB) {
              return 1;
            }

            // names must be equal
            return 0;
          });

          return dadosTabelas;
        })
        .catch((err) => {
          console.error(err);
          return err;
        });
    },
    async buscaInspecoesById(inspecao_id) {
      let client_id = localStorage.getItem("cliente_uuid");
      const data = await authService
        .findInspecoesById(client_id, inspecao_id)
        .then(({ data }) => {
          return data;
        });
      return data;
    },
  },
};
</script>
