import Estrutura from "./Estrutura.vue";
import EstruturaAdicionar from "./EstruturaAdicionar.vue";
import EstruturaEditar from "./EstruturaEditar.vue";
import EstruturaExcluir from "./EstruturaExcluir.vue";
import EstruturaHistorico from "./EstruturaHistorico.vue";

const routes = {
  name: "EstruturaListagem",
  path: "estrutura/",
  component: Estrutura,
  children: [
    {
      name: "EstruturaAdicionar",
      path: "adicionar",
      component: EstruturaAdicionar,
    },
    {
      name: "EstruturaEditar",
      path: "editar",
      component: EstruturaEditar,
    },
    {
      name: "EstruturaExcluir",
      path: "excluir",
      component: EstruturaExcluir,
    },
    {
      name: "EstruturaHistorico",
      path: "historico",
      component: EstruturaHistorico,
    },
  ],
};

export default routes;
