import colors from "../../scss/colors.scss";

export default {
  primary: { ...colors },

  accent: colors.accent2,
  error: "#F83F3F",
  info: "#47BCFF",
  success: "#20DF6C",
  warning: "#FFCB47",
};
