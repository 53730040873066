<script>
import { mapActions, mapState } from "vuex";

import UserAppBar from "@/components/UserAppBar.vue";
import UserNavigationDrawer from "@/components/UserNavigationDrawer.vue";

export default {
  name: "App",
  data: () => ({
    drawer: null,
  }),
  computed: {
    ...mapState("user", {
      user: (state) => state.user,
    }),
  },
  methods: {
    ...mapActions("user", ["logout", "loginFromLocalStorage"]),
    logoutAndRedirect() {
      this.logout();
      this.$router.push({ name: "Login" });
    },
  },
  mounted() {
    this.loginFromLocalStorage();
  },
  components: { UserAppBar, UserNavigationDrawer },
};
</script>
<template>
  <v-app id="geomin">
    <UserNavigationDrawer
      v-if="user"
      :user="user"
      v-model="drawer"
      @user:logout="logoutAndRedirect"
    />

    <UserAppBar
      v-if="user"
      :user="user"
      :drawer.sync="drawer"
      @user:logout="logoutAndRedirect"
    />

    <v-main class="grey lighten-5">
      <router-view></router-view>
    </v-main>
    <!--<v-footer></v-footer>-->
  </v-app>
</template>
<style lang="scss">
@import "~vuetify/src/styles/styles.sass";

h1 {
  color: map-get($grey, "darken-4");
}

h2,
h3 {
  color: map-get($grey, "darken-3");
}

.v-text-field--outlined {
  label.v-label {
    color: map-get($grey, "lighten-1");
    font-weight: 500;
  }

  fieldset {
    border: 2px solid map-get($grey, "lighten-1");
  }
}
</style>
