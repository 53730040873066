import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VRow,[_c(VCol,[_c(VBtn,{on:{"click":_vm.backHome}},[_vm._v("Voltar")])],1)],1),_c(VRow,[_c(VCol,[_c(VForm,{ref:"form",staticClass:"pa-5 rounded",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VTextField,{attrs:{"rules":_vm.clienteRule,"type":"text","label":"Nome da empresa/cliente","required":""},model:{value:(_vm.cliente.nome),callback:function ($$v) {_vm.$set(_vm.cliente, "nome", $$v)},expression:"cliente.nome"}}),_c(VTextField,{attrs:{"rules":_vm.cnpjRule,"type":"number","label":"Nome da empresa/cliente","required":""},model:{value:(_vm.cliente.cnpj),callback:function ($$v) {_vm.$set(_vm.cliente, "cnpj", $$v)},expression:"cliente.cnpj"}}),_c(VBtn,{staticClass:"mr-4",attrs:{"disabled":!_vm.valid,"color":"success"},on:{"click":_vm.sendClienteForm}},[_vm._v(" Adicionar meus dados ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }