<template>
  <v-container>
    <v-row>
      <v-col>
        <h2 class="text-h6 font-weight-bold pb-7">
          Preencha os dados abaixo para adicionar um novo instrumento
        </h2>
      </v-col>
    </v-row>
    <FormularioInstrumento
      ref="form"
      :tiposInstrumentos="tiposInstrumentos"
      @enviaFormulario="submit"
    />
    <ConfirmationDialog
      v-model="successDialog"
      :title="successDialogTitle"
      theme="success"
      confirm="Fechar"
      auto-close
    />
    <ConfirmationDialog
      v-model="failureDialog"
      :title="failureDialogTitle"
      :text="failureDialogText"
      theme="error"
      confirm="Fechar"
    />
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import FormularioInstrumento from "./Components/FormularioInstrumento.vue";
import ConfirmationDialog from "@/components/ConfirmationDialog.vue";
import ResourceActionMixin from "@/mixins/ResourceActionMixin";

export default {
  name: "InstrumentoAdicionar",
  components: { FormularioInstrumento, ConfirmationDialog },
  mixins: [ResourceActionMixin],
  data: () => ({ tipoAcao: "Adicionar" }),
  computed: {
    ...mapState("instrumentos", {
      tiposInstrumentos: (state) => state.tipos,
      failure: (state) => state.createFailure,
      success: (state) => state.createSuccess,
    }),
    resourceNameActionSuccess() {
      return "Novo instrumento criado";
    },
    resourceNameActionFailure() {
      return "criar esse instrumento";
    },
  },
  methods: {
    ...mapActions("instrumentos", ["create", "createOther"]),
    submit(payload) {
      if (payload.tipo_instrumento === "inst_outros") {
        let instrumento = {
          ...payload,
          client_id: localStorage.getItem("cliente_uuid"),
          usuario_id: localStorage.getItem("usuario_uuid"),
        };
        return this.createOther(instrumento).then(() =>
          this.markForReloadEstruturas()
        );
      }
      let instrumento = {
        ...payload,
        cliente_id: localStorage.getItem("cliente_uuid"),
        usuario_id: localStorage.getItem("usuario_uuid"),
      };

      return this.create(instrumento).then(() =>
        this.markForReloadEstruturas()
      );
    },
  },
};
</script>
