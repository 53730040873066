<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "Admin",
  computed: {
    ...mapGetters("user", ["hasCustomer"]),
    ...mapState("estruturas", {
      estrutura: (state) => (state.estruturas || [])[0],
      estruturasLoading: (state) => state.loading,
    }),
  },
  beforeUpdate() {
    const estrutura = this.estrutura;
    const hasCustomer = this.hasCustomer;

    if (this.$route.name == "Admin") {
      if (estrutura) {
        this.$router
          .push(estrutura.routes.EstruturaVisualizar)
          .catch(() => null);
      } else if (hasCustomer) {
        this.$router.push({ name: "EstruturaAdicionar" }).catch(() => null);
      } else {
        this.$router.push({ name: "ClienteAdicionar" }).catch(() => null);
      }
    }
  },
};
</script>
<template>
  <v-card v-if="estruturasLoading" max-width="375" class="mx-auto mt-4" loading>
    <v-card-title>Carregando informações</v-card-title>
    <v-card-text>Aguarde enquanto carregamos seus dados...</v-card-text>
  </v-card>
  <v-card v-else flat class="mt-md-8 ml-md-8 pa-3 rounded-lg">
    <router-view :key="$route.fullPath" />
  </v-card>
</template>
