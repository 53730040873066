<template>
  <v-form @submit.prevent="openConfirmationDialog" ref="formEstrutura">
    <v-row>
      <v-col cols="12" md="6" lg="6">
        <v-text-field
          label="Nome da estrutura *"
          v-model="payload.nome"
          placeholder="Digite o nome da estrutura"
          outlined
          required
          :rules="requiredField"
          class="border--gray"
        />
        <v-text-field
          label="Nome da mina"
          v-model="payload.mina"
          placeholder="Digite o nome da mina"
          outlined
          class="border--gray"
        />
        <v-select
          label="Tipo de estrutura *"
          :items="tipo_estrutura"
          :rules="requiredField"
          v-model="payload.tipo_estrutura"
          outlined
          required
          class="border--gray"
        />
        <v-select
          label="UF"
          :items="estados"
          item-value="sigla"
          item-text="sigla"
          v-model="payload.uf"
          outlined
          class="border--gray"
        />
        <v-select
          label="Cidade"
          :items="estadoSelecionado"
          v-model="payload.cidade"
          item-value="cidades"
          item-text="cidades"
          outlined
          class="border--gray"
        />
        <v-text-field
          label="Altura"
          v-model="payload.altura"
          placeholder="Digite o altura"
          outlined
          class="border--gray"
          type="number"
          suffix="m"
        />
        <v-text-field
          label="Volume"
          v-model="payload.volume"
          placeholder="Digite o volume"
          outlined
          class="border--gray"
          type="number"
          suffix="m³"
        />
        <v-select
          label="Método construtivo"
          :items="metodo_construtivo"
          v-model="payload.metodo_construtivo"
          outlined
          class="border--gray"
        />
        <v-select
          label="DPA"
          :items="dpa"
          v-model="payload.dpa"
          outlined
          class="border--gray"
        />
        <v-select
          label="Nível emergência"
          :items="nivel_emergencia"
          v-model="payload.nivel_emergencia"
          outlined
          class="border--gray"
        />
        <v-text-field
          label="Geotécnico responsável"
          v-model="payload.geotecnico_responsavel"
          placeholder="Digite o nome do geotécnico responsável"
          outlined
          class="border--gray"
        />
      </v-col>

      <v-col cols="12" md="6" lg="6">
        <v-textarea label="Observação" v-model="payload.anotacoes" outlined />
        <!-- <v-icon @click="adicionar = !adicionar" v-show="!adicionar"
          >mdi-plus</v-icon
        >
        <v-file-input
          v-show="adicionar"
          label="Solte sua imagem aqui, ou pesquise"
        ></v-file-input> -->
        <v-btn block type="submit" class="pa-6 primary darken-2 my-6">
          {{ tipoAcao }} estrutura
        </v-btn>
      </v-col>
    </v-row>
    <ConfirmationDialog
      v-model="confirmationDialog"
      :theme="confirmTheme"
      :title="confirmTitle"
      :confirm="confirmButton"
      @confirmed="submit"
    />
  </v-form>
</template>

<script>
import { estados } from "@/helpers/utils/ufCidades";
import ConfirmationDialog from "@/components/ConfirmationDialog.vue";
import ResourceConfirmationMixin from "@/mixins/ResourceConfirmationMixin.js";

export default {
  name: "CardEditar",
  components: { ConfirmationDialog },
  mixins: [ResourceConfirmationMixin],
  props: {
    form: {
      default: () => {},
      type: Object,
    },
    tipoAcao: {
      default: "Adicionar",
      type: String,
    },
  },
  data: () => ({
    adicionar: false,
    estados: estados,
    nome_estrutura: [{ text: "Estrutura 1", value: "estrutura_1" }],
    // Etapa única, alteamento à montante, alteamento à jusante, alteamento por linha de centro
    metodo_construtivo: [
      { text: "Etapa única", value: "etapa unica" },
      { text: "Alteamento à montante", value: "alteamento a montante" },
      { text: "Alteamento à jusante", value: "alteamento a jusante" },
      {
        text: "Alteamento por linha de centro",
        value: "alteamento por linha de centro",
      },
    ],
    dpa: [
      { text: "Baixo", value: "baixo" },
      { text: "Médio", value: "medio" },
      { text: "Alto", value: "alto" },
    ],
    tipo_estrutura: [
      { text: "Barragem", value: "barragem" },
      { text: "Pilha", value: "pilha" },
      { text: "Dique", value: "dique" },
      { text: "Cava", value: "cava" },
    ],
    nivel_emergencia: [
      { text: "Sem Emergência", value: "sem_emergencia" },
      { text: "Nível 1", value: "nivel_1" },
      { text: "Nível 2", value: "nivel_2" },
      { text: "Nível 3", value: "nivel_3" },
    ],
    originalPayload: {},
    payload: {},
    requiredField: [(v) => !!v || "Campo obrigatório"],
  }),
  mounted() {
    this.atualizaCampos();
  },
  computed: {
    confirmResource() {
      return "essa estrutura";
    },
    estadoSelecionado() {
      if (this.payload?.uf) {
        let item = estados?.filter((e) => e.sigla === this.payload.uf);
        let lista = [...item[0]?.cidades];
        return lista;
      }
      return ["Selecione um estado"];
    },
  },
  methods: {
    atualizaCampos() {
      this.payload = { ...this.form };
      this.originalPayload = { ...this.form };
    },
    reset() {
      return this.$refs.formEstrutura.reset();
    },
    validate() {
      return this.$refs.formEstrutura.validate();
    },
    submit() {
      if (this.validate()) {
        let payload = {};

        switch (this.tipoAcao) {
          case "Adicionar":
            payload = this.payload;
            break;
          case "Editar":
            payload = {
              ...this.originalPayload,
              estrutura_id: this.payload.uuid,
              update: { ...this.payload, estrutura_id: this.payload.uuid },
            };
            break;
        }

        this.$emit("enviaFormulario", payload);
      }
    },
  },
};
</script>

<style scoped>
.v-text-field {
  border-radius: 10px;
}
</style>
