import Instrumento from "./Instrumento.vue";
import InstrumentoAdicionar from "./InstrumentoAdicionar.vue";
import InstrumentoEditar from "./InstrumentoEditar.vue";
import InstrumentoExcluir from "./InstrumentoExcluir.vue";
import InstrumentoHistorico from "./InstrumentoHistorico.vue";

const routes = {
  name: "InstrumentoListagem",
  path: "instrumentos/",
  component: Instrumento,
  children: [
    {
      name: "InstrumentoAdicionar",
      path: "adicionar",
      component: InstrumentoAdicionar,
    },
    {
      name: "InstrumentoEditar",
      path: "editar",
      component: InstrumentoEditar,
    },
    {
      name: "InstrumentoExcluir",
      path: "excluir",
      component: InstrumentoExcluir,
    },
    {
      name: "InstrumentoHistorico",
      path: "historico",
      component: InstrumentoHistorico,
    },
  ],
};

export default routes;
