<script lang="js">
import HealthDetails from "@/components/HealthDetails.vue";
import InstrumentoDetails from "@/components/InstrumentoDetails.vue";

import LeituraChart from "@/components/LeituraChart.vue";
import { AuthService } from "@/helpers/services";
import moment from "moment";
import { mapActions, mapState } from "vuex";

const authService = new AuthService();

const intervals = {
  day: { value: "day", text: "Dia", icon: "mdi-calendar-today" },
  week: { value: "week", text: "Semana", icon: "mdi-calendar-week" },
  month: { value: "month", text: "Mês", icon: "mdi-calendar-month" },
  none: { value: "none", text: "Sem Agrupamento", icon: "mdi-calendar-clock" },
};

export default {
  name: "InstrumentoDashboard",
  components: { InstrumentoDetails, LeituraChart, HealthDetails },
  props: {
    estruturaId: { type: String, required: true },
    tipoInstrumentoId: { type: String, required: true },
    date: { type: Date, required: false, default: () => new Date() },
  },
  data: (vm) => ({
    dadosFromPostgres: [],
    leiturasDynamo: [],
    leiturasDynamoCanalINT0: {},
    leiturasDynamoCanalINT1: {},
    intervalo: intervals.day,
    intervalos: [intervals.month, intervals.week, intervals.day, intervals.none],
    datesMenu: false,
    datesRange: [
      moment(vm.date).add({ days: -30 }).format("YYYY-MM-DD HH:mm:ss"),
      moment(vm.date).format("YYYY-MM-DD HH:mm:ss"),
    ],
    dates: [
      moment(vm.date).add({ days: -30 }).format("YYYY-MM-DD"),
      moment(vm.date).format("YYYY-MM-DD"),
    ],
    tipoInstrumento: null,
    instrumentoId: null,
    pluviometro: null,
    autoSnDl: null,
    instrumentosDataFromDynamo: [],
    estruturaChart: null,
    healthDetailsDynamo: {},
    baseDados: null,
    isAuto: false,
    nivelBruto: null,
    ultimaData: null,
    nivelPluviometro: null,
    alarmesList: [],
    inspectionsList: [],
    pluviometroSn: null,
    leiturasPluviometro: [],
    disablePluviometroBtn: false, // vm.intervalo.value !== 'none',
  }),
  async mounted() {
    moment.tz.setDefault("America/Sao_Paulo");

    const baseDados = await authService.getClientResume(this.instrumento);
    this.baseDados = baseDados?.data?.data[0]?.baseDados;
    const dadosDynamo = await authService.getTipoInstrumentosAutomaticos(
      this.estruturaId
    );
    this.instrumentosDataFromDynamo = dadosDynamo.data.data[0];
    this.isAuto = this.instrumento?.auto_manual;
    const id = this.instrumentos[0]?.instrumentoId;

    this.instrumentoId = id;

    if (this.getSnByInstrumentoId) {
      this.getSnByInstrumentoId(), this.getLeituraByDynamo() || [];
    }
    this.autoRefresh();
    this.alarmeList();
  },

  watch: {
    instrumentoSaude() {
      this.alarmesAtuados = this.instrumentoSaude.status;
      this.isAuto = this.instrumento?.auto_manual;
    },
    tipoInstrumento(val) {
      this.fetchSummary({
        tipo_instrumento: val.value,
        estrutura_id: this?.estruturaId,
      });
    },
    estruturaId(val) {
      this.fetchSummary({
        tipo_instrumento: this.tipoInstrumento.value,
        estrutura_id: val,
      });
    },
    leiturasSummary(val) {
      const id = this.instrumentos[0]?.instrumentoId;
      if (Array.isArray(val) && val[0]) {
        this.instrumentoId = id;
      }
    },

    instrumentoId: function () {
      if (this.getSnByInstrumentoId) {
        this.alarmeList();
        return this.getSnByInstrumentoId(), this.getLeituraByDynamo() || [];
      }
    },

    datesRange: function () {
      return (
        this.getSnByInstrumentoId(),
        this.getLeituraByDynamo(),
        this.instrumentoLeituras,
        this.hasPluviometroSelect,
        this.alarmeList(),
        this.pluviometroValue()
      );
    },

    pluviometro: function () {
      return (
        this.getSnByInstrumentoId(),
        this.getLeituraByDynamo(),
        this.instrumentoLeituras,
        this.pluviometroValue()
      );
    },

    intervalo: function () {
      if (this.intervalo.value === 'none') {
      //   this.dates = [
      //   moment().format("YYYY-MM-DD"),
      //   moment().format("YYYY-MM-DD")
      // ];
      // this.startTime = "00:00:00"; // Set start time for 'none' interval
      // this.endTime = "23:59:59";   // Set end time for 'none' interval

      // Set the disable state for pluviometer button
      this.disablePluviometroBtn = this.tipoInstrumento.value !== "inst_pluviometro";

      return (
        this.getSnByInstrumentoId(),
        this.getLeituraByDynamo(),
        this.hasPluviometroSelect,
        this.instrumentoLeituras,
        this.pluviometroValue()
      );
    } else {
      this.disablePluviometroBtn = false
    }
  },
},
  computed: {
    ...mapState("instrumentos", {
      tiposInstrumentos: (state) => state.tipos,
    }),
    ...mapState("estruturas", {
      estruturas: (state) => state.estruturas,
    }),
    ...mapState("leituras", {
      loading: (state) => state.summaryLoading,
      leiturasSummary: (state) => state.summarySuccess,
    }),
    estrutura() {
      return this.estruturas.find((e) => e.estruturaId === this.estruturaId);
    },
    estruturaNome() {
      return this.estrutura.estruturaNome;
    },
    instrumentos() {
      this.instrumentosAlphabetic();
      return (
        this.estrutura.instrumentosGroupedByName[this.tipoInstrumento.value] ||
        []
      );
    },
    instrumento() {
      return this.leiturasSummary?.find((i) => i.id === this.instrumentoId);
    },
    instrumento_automatizado() {
      return this.instrumento?.auto_canal_dl !== null;
    },

    hasPluviometroSelect() {
      return (
        (this.tipoInstrumento.value === "inst_piezometro" ||
          this.tipoInstrumento.value === "inst_outros") &&
        this.instrumento &&
        this.instrumento.pluviometros
      );
    },

    instrumentoLeituras() {
      let leiturasAutomaticasDynamo = this.leiturasDynamo || [];
      if (this.isAuto !== true) {
        leiturasAutomaticasDynamo = [];
      }

      const leiturasManuaisPostGres =
        (this.instrumento && this.instrumento.leituras) || [];

      const leituras = [leiturasAutomaticasDynamo, leiturasManuaisPostGres];

      const dataInicio = this.datesRange[0]
        ? moment(this.datesRange[0]).startOf("day")
        : null;

      const dataFim = this.datesRange[1]
        ? moment(this.datesRange[1]).endOf("day")
        : null;

        return leituras.filter((l) => {
        const dataLeitura = l.data_leitura ? moment(l.data_leitura) : null;

        return (
          dataLeitura === null ||
          ((dataInicio === null || dataInicio.isSameOrBefore(dataLeitura)) &&
            (dataFim === null || dataFim.isSameOrAfter(dataLeitura)))
        );
      });
    },

    datesText() {
      const today = moment().format("YYYY-MM-DD");
      const dates = [...this.dates].sort();

      if (dates[1] === today) {
        const inicio = moment(dates[0]);
        const fim = moment(dates[1]);
        const dias = fim.diff(inicio, "days");
        if (dias === 0) {
          return "Hoje";
        } else if (dias === 1) {
          return "Desde ontem";
        } else if (dias > 0) {
          return `Últimos ${dias} dias`;
        }
      }
      return dates.join(" ~ ");
    },
    getLeiturasAutomaticasPluviomentos() {
      if (this.leiturasPluviometro) {
        let result = this.leiturasPluviometro.map((item) => ({
          ...item, leitura:
          item.leitura * this.pluviometro.auto_consta +
          this.pluviometro.auto_constb,
        }))
        return result;
        // return this.leiturasPluviometro;
      } else return [];
    },
    instrumentoSaude() {
      let statusAlarme = false;
      const instrumento = this.leiturasSummary?.find(
        (i) => i.id === this.instrumentoId
      );
      const isPluviometro =
        this.instrumento?.tipo_instrumento == "inst_pluviometro";

      let leituraBruta = 0;
      let nivel = this.healthDetailsDynamo?.leitura;

      if (!this.healthDetailsDynamo?.leitura) {
        let ultimaLeitura = instrumento?.leituras?.sort((a, b) =>
          moment(a.data_leitura) > moment(b.data_leitura) ? -1 : 1
        );

        if (instrumento?.cota_topo && instrumento?.cota_fundo) {
          nivel =
            instrumento?.cota_topo -
            instrumento?.cota_fundo -
            (ultimaLeitura?.length > 0
              ? ultimaLeitura[0].leitura
              : instrumento?.leituras[0]?.leitura);
        }

        leituraBruta = nivel;
      } else {
        leituraBruta = nivel;
        nivel = (
          nivel * instrumento.auto_consta +
          instrumento.auto_constb
        ).toFixed(2);

        if (instrumento.tipo_instrumento === "inst_regua_reservatorio") {
          nivel = (
            instrumento?.cota_topo -
            instrumento?.cota_base -
            leituraBruta
          ).toFixed(2);
        }

        if (this.instrumento?.tipo_instrumento_value) {
          nivel = leituraBruta.toFixed(2);
        }
      }

      let alarmesAtuados = [];

      if (this.healthDetailsDynamo.rssi < -90) {
        const radioAlarme = "Sinal de rádio baixo";
        alarmesAtuados.push(radioAlarme);
      }
      if (this.healthDetailsDynamo.bateria < 3.35) {
        const bateriaAlarme = "Bateria baixa";
        alarmesAtuados.push(bateriaAlarme);
      }
      if (this.healthDetailsDynamo.temperatura > 55) {
        const temperaturaAlarme = "Temperatura alta no datalogger";
        alarmesAtuados.push(temperaturaAlarme);
      }
      if (
        this.ultimaData !== null &&
        moment().diff(moment(this.ultimaData)) >= 14400000
      ) {
        const conexao = "Falha de comunicação";
        alarmesAtuados.push(conexao);
      }
      if (
        instrumento?.cota_alarme &&
        Number(nivel) + instrumento.cota_base >= instrumento?.cota_alarme
      ) {
        const nivelAltoRR = "Nível alto no reservatório";
        alarmesAtuados.push(nivelAltoRR);
      }

      let leituraPluviometro = this.healthDetailsDynamo.leituraPluviometro;

      if (isPluviometro) {
        if (
          moment(this.healthDetailsDynamo.data_leituraPluviometro) >=
          moment().subtract(1, "day")
        ) {
          leituraPluviometro = this.healthDetailsDynamo.leituraPluviometro;
          if (this.healthDetailsDynamo.leituraPluviometro >= 20) {
            const excessoChuva = "Excesso de chuva na região";
            alarmesAtuados.push(excessoChuva);
          }
        } else {
          leituraPluviometro = "-";
        }
      }

      if (instrumento) {
        let nivelBruto = Number(leituraBruta) + instrumento.cota_fundo;
        if (nivelBruto >= instrumento.nivel_emergencia) {
          const nivelSuperior = "Nível de emergência atingido";
          alarmesAtuados.push(nivelSuperior);
        } else if (nivelBruto >= instrumento.nivel_alerta) {
          const nivelSuperior = "Nível de alerta atingido";
          alarmesAtuados.push(nivelSuperior);
        } else if (nivelBruto >= instrumento.nivel_atencao) {
          const nivelSuperior = "Nível de atenção atingido";
          alarmesAtuados.push(nivelSuperior);
        }
      }

      if (
        this.healthDetailsDynamo.rangeI0 > 20.2 ||
        this.healthDetailsDynamo.rangeI1 > 20.2 ||
        this.healthDetailsDynamo.rangeI0 < 3.8 ||
        this.healthDetailsDynamo.rangeI1 < 3.8
      ) {
        const rangeAlarme = "Leitura do sensor fora do range";
        alarmesAtuados.push(rangeAlarme);
      }
      const listaAlarme =
        this.alarmesList?.filter((item) => item.nome === instrumento.nome) ??
        [];
      if (listaAlarme[0]?.alarmes?.length > 0) {
        statusAlarme = true;
      } else {
        statusAlarme = false;
      }

      return {
        name: instrumento?.nome || "-",
        estrutura: instrumento?.estrutura || "-",
        status: statusAlarme ? "Alarme" : "Normal",
        nivel: isPluviometro ? leituraPluviometro : nivel || "-",
        temperatura: this.healthDetailsDynamo?.temperatura || "-",
        umidade: this.healthDetailsDynamo?.umidade || "-",
        rssi: this.healthDetailsDynamo?.rssi || "-",
        bateria: this.healthDetailsDynamo?.bateria || "-",
        alarmesAtuados: listaAlarme[0]?.alarmes || [],
        perdaPacotes: listaAlarme[0]?.items?.perda_pacotes || "",
        unidade_eng: instrumento?.unidade_eng || "",
      };
    },
  },

  beforeMount() {
    this.tipoInstrumento = this.tiposInstrumentos.find(
      (ti) => ti.value === this.tipoInstrumentoId
    );
  },
  methods: {
    instrumentosAlphabetic() {
      const instrumentosAlphabetic = this.estrutura.instrumentosGroupedByName[
        this.tipoInstrumento.value
      ].sort((a, b) => {
        const nomeA = a.instrumentoNome.toUpperCase(); // ignore upper and lowercase
        const nomeB = b.instrumentoNome.toUpperCase(); // ignore upper and lowercase
        if (nomeA < nomeB) {
          return -1;
        }
        if (nomeA > nomeB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
      return instrumentosAlphabetic;
    },
    getSnByInstrumentoId() {
      const data = this.instrumentosDataFromDynamo.find(
        (i) => i.id === this.instrumentoId
      );
      this.autoSnDl = data?.auto_sn_dl;
      return data?.auto_sn_dl;
    },

    autoRefresh() {
      return setInterval(() => {
        this.getLeituraByDynamo();
        this.instrumentoLeituras;
        this.estruturaChart += 1;
      }, 600000); // refresh every 10 minutes
    },

    calculateMarcacoesAutomaticasDynamo(valorLeitura) {
      const autoConstA = Number(this.instrumento?.auto_consta || 1);
      const autoConstB = Number(this.instrumento?.auto_constb || 0);
      if (this.hasPluviometroSelect) {
        const autoConstAPluviometro = 1   // Number(
//          this.instrumento?.pluviometros[0]?.auto_consta || 1
//        );
        const autoConstBPluviometro = 0   // Number(
//          this.instrumento?.pluviometros[0]?.auto_constb || 0
//        );
        return valorLeitura * autoConstAPluviometro + autoConstBPluviometro;
      } else {
        return valorLeitura * autoConstA + autoConstB;
      }
    },

    calculateMarcacoesOutrosInstrumentos(element) {
      const formula = this.instrumento?.formulas.filter(
        (item) =>
          moment(item.data_val_ini).isSameOrBefore(element?.Data?.S) &&
          moment(item.data_val_fim).isAfter(element?.Data?.S)
      );
      let equacao = formula[0]?.equacao;
      let x = element?.[formula[0]?.x]?.S;
      let y = element?.[formula[0]?.y]?.S;
      let z = element?.[formula[0]?.z]?.S;

      if (equacao.includes("x")) {
        equacao = equacao.replace("x", x);
      }
      if (equacao.includes("x")) {
        equacao = equacao.replace("x", x);
      }
      if (equacao.includes("x")) {
        equacao = equacao.replace("x", x); // prever casos onde a variavel x apareca mais de uma vez. Para a formula funcionar, é preciso substituir todas as variaveis x, y e z, mais de uma vez
      }
      if (equacao.includes("y")) {
        equacao = equacao.replace("y", y);
      }
      if (equacao.includes("y")) {
        equacao = equacao.replace("y", y);
      }
      if (equacao.includes("z")) {
        equacao = equacao.replace("z", z);
      }
      if (equacao.includes("z")) {
        equacao = equacao.replace("z", z);
      }
      return eval(equacao).toFixed(6);
    },

    async getLeituraByDynamo() {
      this.dates = [...this.dates].sort();

      let response = await authService.getLeituraDynamoCalculada({
        tableName: this.baseDados,
        sn: this.autoSnDl,
        dataIni: this.datesRange[0],
        dataFim: this.datesRange[1],
      });

      this.inspecoesList(response?.data?.data?.inspecoes);

      const lastIndex = response?.data?.data?.Items.length - 1;
      const resumo = response?.data?.data?.Items;
      this.ultimaData = resumo[lastIndex]?.Timestamps?.S;

      const leiturasDynamoCanalI0 = resumo.map((element) => {
        const leituraBruta = Number(element.I0?.S);

        return {
          data_leitura: moment(element.Timestamps.S, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD"),
          leitura: this.calculateMarcacoesAutomaticasDynamo(leituraBruta),
          // leitura: leituraBruta,
          pluviometros: this.hasPluviometroSelect,
          hora_leitura: moment(element.Timestamps.S, "YYYY-MM-DD HH:mm:ss").format("HH:mm:ss")
        };
      });

      const leiturasDynamoCanalI1 = resumo.map((element) => {
        const leituraBruta = Number(element.I1?.S);
        return {
          data_leitura: moment(element.Timestamps.S, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD"),
          leitura: this.calculateMarcacoesAutomaticasDynamo(leituraBruta),
          // leitura: leituraBruta,
          pluviometros: this.hasPluviometroSelect,
          hora_leitura: moment(element.Timestamps.S, "YYYY-MM-DD HH:mm:ss").format("HH:mm:ss")
        };
      });

      const leiturasDynamoCanal01V = resumo.map((element) => {
        const leituraBruta = Number(element?.["0-1V"]?.S);
        return {
          data_leitura: moment(element.Timestamps.S, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD"),
          leitura: this.calculateMarcacoesAutomaticasDynamo(leituraBruta),
          // leitura: leituraBruta,
          pluviometros: this.hasPluviometroSelect,
          hora_leitura: moment(element.Timestamps.S, "YYYY-MM-DD HH:mm:ss").format("HH:mm:ss")
        };
      });

      const leiturasDynamoCanal010V = resumo.map((element) => {
        const leituraBruta = Number(element?.["0-10V"]?.S);
        return {
          data_leitura: moment(element.Timestamps.S, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD"),
          leitura: leituraBruta,
          pluviometros: this.hasPluviometroSelect,
          hora_leitura: moment(element.Timestamps.S, "YYYY-MM-DD HH:mm:ss").format("HH:mm:ss")
        };
      });

      const leiturasDynamoCanalY = resumo.map((element) => {
        const leituraBruta = Number(element?.["Y"]?.S);
        return {
          data_leitura: moment(element.Timestamps.S, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD"),
          leitura: leituraBruta,
          pluviometros: this.hasPluviometroSelect,
          hora_leitura: moment(element.Timestamps.S, "YYYY-MM-DD HH:mm:ss").format("HH:mm:ss")
        };
      });

      const leiturasDynamoCanalEV = resumo.map((element) => {
        const leituraBruta = Number(element?.[this.instrumento?.auto_canal_dl]?.S);

        return {
          data_leitura: moment(element.Timestamps.S, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD"),
          // leitura: this.calculateMarcacoesOutrosInstrumentos(leituraBruta),
          leitura: this.calculateMarcacoesAutomaticasDynamo(leituraBruta),
          // leitura: leituraBruta,
          pluviometros: this.hasPluviometroSelect,
          hora_leitura: moment(element.Timestamps.S, "YYYY-MM-DD HH:mm:ss").format("HH:mm:ss")
        };
      });

      this.leiturasDynamoCanalINT0 = resumo.map((element) => {
        const leituraBruta = Number(element.INT0?.S);
        return {
          data_leitura: moment(element.Timestamps.S, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD"),
          leitura: this.calculateMarcacoesAutomaticasDynamo(leituraBruta),
          // leitura: leituraBruta,
          hora_leitura: moment(element.Timestamps.S, "YYYY-MM-DD HH:mm:ss").format("HH:mm:ss")
        };
      });

      this.leiturasDynamoCanalINT1 = resumo.map((element) => {
        const leituraBruta = Number(element.INT1?.S);
        return {
          data_leitura: moment(element.Timestamps.S, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD"),
          leitura: this.calculateMarcacoesAutomaticasDynamo(leituraBruta),
          // leitura: leituraBruta,
          hora_leitura: moment(element.Timestamps.S, "YYYY-MM-DD HH:mm:ss").format("HH:mm:ss")
        };
      });

      if (this.instrumento?.tipo_instrumento_value) { //tipo_instrumento_value == "inst_outros", arquivo postgres.js back-end
        this.leiturasDynamo = resumo.map((element) => {
          const leituraBruta = Number(
            this.calculateMarcacoesOutrosInstrumentos(element)
          );
          return {
            data_leitura: moment(element.Timestamps.S, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD"),
            leitura: leituraBruta,
            hora_leitura: moment(element.Timestamps.S, "YYYY-MM-DD HH:mm:ss").format("HH:mm:ss")
          };
        });

          // Sort this.leiturasDynamo by data_leitura and hora_leitura
          // this.leiturasDynamo.sort((a, b) => {
          //   const dateA = `${a.data_leitura} ${a.hora_leitura}`;
          //   const dateB = `${b.data_leitura} ${b.hora_leitura}`;
          //   return moment(dateA, "YYYY-MM-DD HH:mm:ss").diff(moment(dateB, "YYYY-MM-DD HH:mm:ss"));
          // });
        }
      if (!this.instrumento?.tipo_instrumento_value) {
        switch (this.instrumento?.auto_canal_dl) {
          case "I0":
            this.leiturasDynamo = leiturasDynamoCanalI0;
            break;
          case "I1":
            this.leiturasDynamo = leiturasDynamoCanalI1;
            break;
          case "Y":
            this.leiturasDynamo = leiturasDynamoCanalY;
            break;
          case "engineeringValue":
          case "engineeringValueA":
          case "engineeringValueB":
          case "engineeringValue_ch0":
          case "engineeringValue_ch1":
          case "engineeringValue_ch2":
          case "engineeringValue_ch3":
          case "engineeringValue_ch4":
          case "engineeringValue_ch5":
            this.leiturasDynamo = leiturasDynamoCanalEV;
            break;
          case "V1":
            this.leiturasDynamo = leiturasDynamoCanal01V;
            break;
          case "V10":
            this.leiturasDynamo = leiturasDynamoCanal010V;
            break;
          case "INT0":
            this.leiturasDynamo = this.leiturasDynamoCanalINT0;
            break;
          case "INT1":
            this.leiturasDynamo = this.leiturasDynamoCanalINT1;
            break;
          default:
            console.error(
              `Sem dados para esse tipo de Canal ${this.instrumento.auto_canal_dl}`
            );
        }
          // Sort this.leiturasDynamo by data_leitura and hora_leitura
          // this.leiturasDynamo.sort((a, b) => {
          //   const dateA = `${a.data_leitura} ${a.hora_leitura}`;
          //   const dateB = `${b.data_leitura} ${b.hora_leitura}`;
          //   return moment(dateA, "YYYY-MM-DD HH:mm:ss").diff(moment(dateB, "YYYY-MM-DD HH:mm:ss"));
          // });
      }

      const instrumento = this.leiturasSummary?.find(
        (i) => i.id === this.instrumentoId
      );
      const lastDataAuto = this.leiturasDynamo[lastIndex]?.data_leitura;
      const lastDataManual =
        instrumento?.leituras?.sort((a, b) =>
          moment(a.data_leitura) > moment(b.data_leitura) ? -1 : 1
        ) ?? [];

      if (lastDataAuto && lastDataManual[0]?.data_leitura) {
        if (moment(lastDataAuto) < moment(lastDataManual[0]?.data_leitura)) {
          let filter = lastDataManual.filter(
            (value) => value?.data_leitura == lastDataManual[0]?.data_leitura
          );
          let somaPluviometroManual = filter.reduce(
            (value, currentValue) =>
              Number(value) + Number(currentValue.leitura),
            0
          );

          this.nivelPluviometro = somaPluviometroManual;
        } else {
          let filter = this.leiturasDynamo.filter(
            (data) => data?.data_leitura == lastDataAuto
          );

          let somaPluviometroAuto = filter.reduce(
            (valor, currentValue) =>
              Number(valor) + Number(currentValue.leitura),
            0
          );
          this.nivelPluviometro = somaPluviometroAuto;
        }
      } else if (lastDataAuto) {
        let filter = this.leiturasDynamo.filter(
          (data) => data?.data_leitura == lastDataAuto
        );

        let somaPluviometroAuto = filter.reduce(
          (valor, currentValue) => Number(valor) + Number(currentValue.leitura),
          0
        );
        this.nivelPluviometro = somaPluviometroAuto;
      } else if (lastDataManual[0]?.data_leitura) {
        let filter = lastDataManual.filter(
          (value) => value.data_leitura == lastDataManual[0]?.data_leitura
        );
        let somaPluviometroManual = filter.reduce(
          (value, currentValue) =>
            Number(value) + Number(currentValue?.leitura),
          0
        );
        this.nivelPluviometro = somaPluviometroManual;
      } else {
        this.nivelPluviometro = 0;
      }
      this.healthDetailsDynamo = {
        bateria: resumo[lastIndex]?.Bat?.S,
        temperatura: resumo[lastIndex]?.Temp?.S,
        umidade: resumo[lastIndex]?.Umid?.S,
        rssi: resumo[lastIndex]?.RSSI?.S,
        rangeI0: resumo[lastIndex]?.I0?.S,
        rangeI1: resumo[lastIndex]?.I1?.S,
        leitura: this.leiturasDynamo[lastIndex]?.leitura,
        leituraPluviometro: this.nivelPluviometro.toFixed(2),
        data_leituraPluviometro: lastDataAuto
          ? lastDataAuto
          : lastDataManual[0]?.data_leitura,
      };

      return resumo;
    },

    ...mapActions("leituras", ["fetchSummary"]),
    setDatesRange(val) {
      if (val.length > 1) {
        this.datesRange = [...val].sort();
        if (
          moment().format("YYYY-MM-DD") ===
          moment(this.datesRange[1]).format("YYYY-MM-DD")
        ) {
          this.datesRange[1] = moment().format("YYYY-MM-DD HH:mm:ss");
        } else {
          this.datesRange[1] = moment(this.datesRange[1])
            .endOf("day")
            .format("YYYY-MM-DD HH:mm:ss");
        }
        this.datesRange[0] = moment(this.datesRange[0])
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
      }
    },

    async alarmeList() {
      let body = null;
      let array = [];
      await Promise.all(
        this.instrumentos.map((item) => {
          let isOutro = item?.instrumentoId === this.instrumento?.id;
          array.push({
            id:
              isOutro && this.instrumento?.tipo_instrumento_value
                ? this.instrumento?.id
                : 0,
            sn: this.getSnByInstrumentoId(),
            name: item.instrumentoNome,
            outros:
              isOutro && this.instrumento?.tipo_instrumento_value
                ? true
                : false,
          });
        })
      );
      body = array;
      let isToday =
        moment().format("YYYY-MM-DD") ===
        moment(this.datesRange[1]).format("YYYY-MM-DD");
      let dataFimToday = moment(this.datesRange[1]).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      let dataFim = moment(this.datesRange[1])
        .endOf("day")
        .format("YYYY-MM-DD HH:mm:ss");
      const alarmes = await authService.resumoAlarmeDynamo({
        tableName: this.baseDados,
        estruturaId: this.estruturaId,
        dataIni: moment(this.datesRange[0]).format("YYYY-MM-DD HH:mm:ss"),
        dataFim: isToday ? dataFimToday : dataFim,
        body: body,
      });
      this.alarmesList = alarmes?.data?.data;
    },

    inspecoesList(inspecoes) {
      let tipo_instrumento = this.instrumento?.tipo_instrumento;

      if (inspecoes.length > 0) {
        let resultados = [];
        let diaAtual = moment(this.datesRange[0]).startOf("day");
        while (
          diaAtual.isSameOrBefore(moment(this.datesRange[1]).startOf("day"))
        ) {
          let objetoEncontrado = null;
          for (let objeto of inspecoes) {
            if (
              moment(objeto.data_inspecao)
                .startOf("day")
                .isSame(diaAtual.startOf("day")) &&
              objeto.instrumento_id === this.instrumento.id &&
              objeto.tipo_instrumento === tipo_instrumento
            ) {
              objetoEncontrado = objeto;
              break;
            }
          }
          resultados.push(objetoEncontrado);
          diaAtual.add(1, "days");
        }

        this.inspectionsList = resultados;
      }
    },

    async pluviometroValue() {
      if (!this.pluviometro) {
        return;
      }
      this.dates = [...this.dates].sort();

      let response = await authService.getLeituraDynamoCalculada({
        tableName: this.baseDados,
        sn: this.pluviometro.auto_sn_dl,
        dataIni: this.datesRange[0],
        dataFim: this.datesRange[1],
      });

      const resumo = response?.data?.data?.Items;

      const value_pluviometro= resumo.map((element) => {
        const leituraBruta = Number(element[this.pluviometro?.auto_canal_dl]?.S);
        return {
          data_leitura: moment(element.Timestamps.S, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD"),
          leitura: this.calculateMarcacoesAutomaticasDynamo(leituraBruta),
          // leitura: leituraBruta,
        };
      });

      // const valueINT0 = resumo.map((element) => {
      //   const leituraBruta = Number(element.INT0?.S);
      //   return {
      //     data_leitura: moment(element.Timestamps.S, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD"),
      //     leitura: this.calculateMarcacoesAutomaticasDynamo(leituraBruta),
      //   };
      // });

      // const valueINT1 = resumo.map((element) => {
      //   const leituraBruta = Number(element.INT1?.S);
      //   return {
      //     data_leitura: moment(element.Timestamps.S, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD"),
      //     leitura: this.calculateMarcacoesAutomaticasDynamo(leituraBruta),
      //   };
      // });

      this.leiturasPluviometro = value_pluviometro;

      // switch (this.pluviometro?.auto_canal_dl) {
      //   case "INT0":
      //     this.leiturasPluviometro = valueINT0;
      //     break;
      //   case "INT1":
      //     this.leiturasPluviometro = valueINT1;
      //     break;
      //   default:
      //     console.error(
      //       `Sem dados para esse tipo de Canal ${this.instrumento.auto_canal_dl}`
      //     );
      // }
    },
  },
};
</script>

<template>
  <v-container class="pa-0" fluid>
    <v-row ref="chartActions" class="my-8">
      <v-col cols="12" sm="auto" class="flex-sm-grow-1 px-sm-2">
        <v-select
          label="Selecione o instrumento"
          :items="instrumentos"
          :disabled="loading"
          v-model="instrumentoId"
          item-text="instrumentoNome"
          item-value="instrumentoId"
          outlined
          hide-details
        />
      </v-col>
      <v-col cols="12" sm="auto" class="flex-sm-grow-1 px-sm-2">
        <v-menu
          v-model="datesMenu"
          :close-on-content-click="false"
          :disabled="loading"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-bind="attrs"
              v-on="on"
              v-model="datesText"
              height="100%"
              label="Data inicial ~ Data final"
              append-icon="mdi-calendar-range"
              readonly
              outlined
              hide-details
            />
          </template>
          <v-date-picker
            v-model="dates"
            @change="setDatesRange"
            class="minhas-estruturas-date-picker"
            show-adjacent-months
            no-title
            scrollable
            range
          />
        </v-menu>
      </v-col>
      <v-col cols="12" sm="auto" class="flex-sm-shrink-1 px-sm-2">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="grey lighten-1"
              class="py-7 thick-border"
              outlined
            >
              <v-icon
                color="grey darken-1"
                v-text="intervalo.icon || 'mdi-calendar-filter'"
              />
            </v-btn>
          </template>
          <v-list>
            <v-list-item-group v-model="intervalo">
              <v-list-item
                v-for="int in intervalos"
                :key="int.value"
                :value="int"
              >
                <v-list-item-icon>
                  <v-icon v-text="int.icon" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="int.text" />
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>

        <v-menu v-if="hasPluviometroSelect" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              :input-value="pluviometro"
              :disabled="disablePluviometroBtn"
              color="grey lighten-1"
              class="py-7 ml-5 thick-border"
              outlined
            >
              <v-icon :color="pluviometro ? 'primary' : 'grey darken-1'">
                mdi-thermometer-lines
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item-group
              v-model="pluviometro"
              :value-comparator="(a, b) => !!a && !!b && a.id === b.id"
            >
              <v-list-item>
                <v-list-item-title
                  class="text--disabled text-body-2"
                  :key="null"
                  :value="null"
                >
                  Desativar
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-for="p in instrumento.pluviometros"
                :key="p.id"
                :value="p"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="p.nome" />
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>

    <v-row v-if="loading">
      <v-col cols="12" class="text-center">
        <v-progress-circular indeterminate color="primary" />
      </v-col>
    </v-row>

    <v-row v-else-if="instrumento">
      <LeituraChart
        class="mx-auto"
        :key="estruturaChart"
        chart-id="estruturaChart"
        :width="$refs.chartActions.clientWidth"
        :instrumento="instrumento"
        :leituras="instrumentoLeituras"
        :pluviometro="pluviometro === 0 ? null : pluviometro"
        :leiturasPluviometroAutomaticos="getLeiturasAutomaticasPluviomentos"
        :datas="dates"
        :intervalo="intervalo.value"
        :inspecoes="inspectionsList"
      />
    </v-row>

    <v-row v-else>
      <v-sheet class="mx-auto pa-5 grey lighten-5" rounded outlined>
        Não há leituras para a estrutura selecionada.
      </v-sheet>
    </v-row>

    <v-row v-if="instrumentoId" class="my-10">
      <v-col>
        <h3>Informações do instrumento:</h3>
        <InstrumentoDetails v-if="instrumento" :instrumento="instrumento" />
      </v-col>
    </v-row>
    <v-row class="my-10">
      <v-col>
        <h3>Status da saúde</h3>
        <HealthDetails
          :instrumento="instrumentoSaude"
          :alarmesAtuados="instrumentoSaude.alarmesAtuados"
          :isAuto="instrumento_automatizado"
          :tipoInstrumento="tipoInstrumento.value"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="scss">
.minhas-estruturas-date-picker {
  .v-date-picker-table {
    .v-btn.v-btn--active {
      background-color: map-get($app-colors, "lighten-5") !important;

      &.v-date-picker--first-in-range {
        background-color: map-get($app-colors, "base") !important;
      }

      &.v-date-picker--last-in-range {
        background-color: map-get($app-colors, "base") !important;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.thick-border {
  border-width: 2px;
}
</style>
