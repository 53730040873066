import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import { en, pt } from "vuetify/lib/locale";

import light from "@/plugins/vuetify/theme";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { en, pt },
    current: "pt",
  },
  theme: {
    themes: { light },
  },
});
