<script lang="js">
import HealthDetails from "@/components/HealthDetails.vue";
import InstrumentoBarChart from "@/components/InstrumentoBarChart.vue";
import InstrumentoDetails from "@/components/InstrumentoDetails.vue";
import { AuthService } from "@/helpers/services";
import moment from "moment";
import "moment-timezone";
import { mapActions } from "vuex";
const authService = new AuthService();

export default {
  name: "VisaoGeralChart",
  components: { HealthDetails, InstrumentoBarChart, InstrumentoDetails },
  props: {
    estruturaId: { type: String, required: true },
  },
  data: () => ({
    tipoInstrumento: { value: "inst_piezometro" },
    instrumentoId: null,
    healthDetailsDynamo: {},
    baseDados: null,
    nivelAtual: null,
    falhaConexao: false,
    instrumentoEscolhido: {},
    alarmesList: [],
    dadosGerais: [],
    loading: true,
    datesRange: [
      moment().subtract(1, "week").format("YYYY-MM-DD HH:mm:ss"),
      moment().format("YYYY-MM-DD HH:mm:ss"),
    ],
  }),
  async mounted() {
    moment.tz.setDefault("America/Sao_Paulo");
    this.getDados();
    const baseDados = await authService.getClientResume(this.estruturaId);
    this.baseDados = baseDados?.data?.data[0]?.baseDados;
    this.autoRefresh();
  },
  computed: {
    instrumentos() {
      const instrumentos = this.dadosGerais?.filter(
        (i) => i.tipo_instrumento == "inst_piezometro"
      );

      instrumentos?.sort((a, b) => {
        const nomeA = a.nome.toUpperCase(); // ignore upper and lowercase
        const nomeB = b.nome.toUpperCase(); // ignore upper and lowercase
        if (nomeA < nomeB) {
          return -1;
        }
        if (nomeA > nomeB) {
          return 1;
        }

        // names must be equal
        return 0;
      });

      return instrumentos;
    },
    elevacaoList() {
      return this.instrumentos.map((i) => i.elevacao || 0);
    },
    elevacaoMax() {
      return this.elevacaoList.reduce((a, b) => Math.max(a, b), -Infinity);
    },
    instrumento() {
      return this.dadosGerais?.find((i) => i.id === this.instrumentoId);
    },
    instrumento_automatizado() {
      return this.instrumento?.auto_canal_dl !== null;
    },
  },

  methods: {
    ...mapActions("leituras", ["fetchSummary"]),
    async getDados() {
      let { data } = await authService.getVisaoGeral({
        estrutura_id: this.estruturaId,
      });
      this.dadosGerais = data?.data;
      await this.alarmeList();

      this.dadosGerais?.sort((a, b) => {
        const nomeA = a.nome.toUpperCase(); // ignore upper and lowercase
        const nomeB = b.nome.toUpperCase(); // ignore upper and lowercase
        if (nomeA < nomeB) {
          return -1;
        }
        if (nomeA > nomeB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
      const instrumentosFiltrados = this.dadosGerais?.filter(
        (instrumento) => instrumento.tipo_instrumento === "inst_piezometro"
      );

      if (instrumentosFiltrados?.length > 0) {
	      await this.alarmeList();
        this.setInstrumentoId(instrumentosFiltrados[0]);
        this.loading = false;
      } else {
        const instrumentosOutros = this.dadosGerais?.filter(
          (instrumento) => instrumento.tipo_instrumento === "inst_outros"
        );

        if (instrumentosOutros?.length > 0) {
          this.$router.push(`/admin/minhas-estruturas/${this.$route.params.estruturaId}/instrumento/inst_outros/visualizar`);
          this.loading = false;
        }
      }
    },
    async setInstrumentoId(piezometro) {
      let diff = null;
      if (piezometro.auto_manual) {
        this.nivelAtual = this.tipoDados(
          piezometro.auto_canal_dl,
          piezometro
        )?.toFixed(2);
        this.dataAtual = piezometro.leituras.Timestamps.S;
        diff = moment().diff(moment(this.dataAtual));
        if (diff >= 14400000) {
          this.falhaConexao = true;
        } else {
          this.falhaConexao = false;
        }
      } else {
        this.nivelAtual =
          piezometro.cota_topo -
          piezometro.cota_fundo -
          piezometro.leituras.leitura;
        this.dataAtual = piezometro.leituras.data_leitura;
        this.falhaComunicacao = false;
      }

      const listaAlarme = this.alarmesList?.filter(
        (item) => item.nome === piezometro.nome
      );

      let statusAlarme = false;
      if (listaAlarme[0]?.alarmes?.length > 0) {
        statusAlarme = true;
      } else {
        statusAlarme = false;
      }

      const body = {
        ...piezometro,
        name: piezometro.nome,
        nivel: this.nivelAtual || "-",
        status: statusAlarme ? "Alarme" : "Normal",
        temperatura: piezometro.leituras.Temp?.S || null,
        umidade: piezometro.leituras.Umid?.S || null,
        rssi: piezometro.leituras.RSSI?.S || null,
        bateria: piezometro.leituras.Bat?.S || null,
        alarmesAtuados: listaAlarme[0]?.alarmes || [],
        perdaPacotes: listaAlarme[0]?.items?.perda_pacotes,
      };
      this.instrumentoEscolhido = body;
    },
    tipoDados(req, data) {
      if (req === "I0") {
        return data.leituras.I0.S * data.auto_consta + data.auto_constb;
      } else if (req === "I1") {
        return data.leituras.I1.S * data.auto_consta + data.auto_constb;
      } else if (req === "V1") {
        return data.leituras?.["0-1V"].S * data.auto_consta + data.auto_constb;
      } else if (req === "V10") {
        return (
          parseFloat(data.leituras?.["0-10V"].S) * data.auto_consta +
          data.auto_constb
        );
      } else if (req?.startsWith("engineeringValue") || req?.includes("engineeringValue")) {
        return parseFloat(
          data.leituras?.[req].S * data.auto_consta +
            data.auto_constb
        );
      } else {
        try {
          return parseFloat(data.leituras?.[req].S);
        } catch (e) {
          console.error(
            `Sem dados para esse tipo de Canal ${this.instrumento?.auto_canal_dl}`
          );
        }
      }
    },
    autoRefresh() {
      return setInterval(() => {
        this.getDados();
      }, 600000); // 10 minutos
    },
    positionYByHighest(piezometro) {
      const me = piezometro.elevacao || 0;
      if (this.elevacaoMax === 0) return 1;
      return (me / this.elevacaoMax) * 100;
    },
    async alarmeList() {
      let array = [];
      await Promise.all(
        this.instrumentos?.map((item) => {
          array.push({
            sn: item.auto_sn_dl,
            name: item.nome,
          });
        })
      );
      const alarmes = await authService.resumoAlarmeDynamo({
        tableName: this.baseDados,
        estruturaId: this.estruturaId,
        dataIni: this.datesRange[0],
        dataFim: this.datesRange[1],
        body: array,
      });
      this.alarmesList = alarmes?.data?.data;
    },
    ...mapActions("leituras", ["fetchSummary"]),
  },
};
</script>
<template>
  <v-container class="pa-0" fluid>
    <v-card class="my-8" min-height="20rem" flat>
      <v-container>
        <v-row v-if="loading">
          <v-col cols="12" class="text-center">
            <v-progress-circular indeterminate color="primary" />
          </v-col>
        </v-row>

        <v-row v-else>
          <!-- <v-col v-if="instrumentos.length> 0"  -->
          <v-col
            cols="12"
            class="d-inline-flex flex-nowrap justify-space-between overflow-x-auto"
          >
            <v-hover v-for="(piezometro, i) in instrumentos" :key="`p-${i}`">
              <template v-slot:default="{ hover }">
                <div @click="setInstrumentoId(piezometro)">
                  <InstrumentoBarChart
                    :instrumento="piezometro"
                    :position-y="positionYByHighest(piezometro)"
                    :hover="hover"
                    :alarmesAtuados="alarmesList"
                    class="instrumento-bar mx-4"
                  />
                </div>
              </template>
            </v-hover>
          </v-col>
          <!-- if no instrumento piezometro, check if inst_outros. Foi implementado um redirecionamento automatico -->
          <!-- <v-col v-else cols="12" class="text-center">
            <p>Nenhum piezômetro cadastrado nesta estrutura.</p>
            <p>Clique no botão abaixo para visualizar os outros instrumentos</p>
            <v-btn color="primary" @click="$router.push(`/admin/minhas-estruturas/${$route.params.estruturaId}/instrumento/inst_outros/visualizar`)">
              Verificar Instrumentos
            </v-btn>
          </v-col> -->
        </v-row>
      </v-container>
    </v-card>

    <v-row v-if="instrumentoEscolhido" class="my-10">
      <v-col>
        <h3>Informações do instrumento:</h3>
        <InstrumentoDetails
          v-if="instrumentoEscolhido"
          :instrumento="instrumentoEscolhido"
        />
      </v-col>
    </v-row>
    <v-row class="my-10">
      <v-col>
        <h3>Status da saúde</h3>
        <HealthDetails
          :instrumento="instrumentoEscolhido"
          :alarmesAtuados="instrumentoEscolhido.alarmesAtuados"
          :isAuto="instrumento_automatizado"
          :tipoInstrumento="tipoInstrumento.value"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<style lang="scss" scoped>
.instrumento-bar {
  cursor: pointer;
}
</style>
