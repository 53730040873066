<template>
  <v-container>
    <v-row>
      <v-col>
        <h2 class="text-h6 font-weight-bold pb-7">
          Preencha os dados abaixo para adicionar uma nova inspeção
        </h2>
      </v-col>
    </v-row>
    <FormularioInspecao
      ref="form"
      :tipoAcao="'Adicionar'"
      @enviaFormulario="submit"
    />
    <ConfirmationDialog
      v-model="successDialog"
      :title="successDialogTitle"
      theme="success"
      confirm="Fechar"
      auto-close
    />
    <ConfirmationDialog
      v-model="failureDialog"
      :title="failureDialogTitle"
      :text="failureDialogText"
      theme="error"
      confirm="Fechar"
    />
  </v-container>
</template>

<script>
import ConfirmationDialog from "@/components/ConfirmationDialog.vue";
import ResourceActionMixin from "@/mixins/ResourceActionMixin";
import { mapActions, mapState } from "vuex";
import FormularioInspecao from "./Components/FormularioInspecao.vue";

export default {
  name: "InspecaoAdicionar",
  components: { FormularioInspecao, ConfirmationDialog },
  mixins: [ResourceActionMixin],
  data: () => ({ tipoAcao: "Adicionar" }),
  computed: {
    ...mapState("inspecoes", {
      failure: (state) => state.createFailure,
      success: (state) => state.createSuccess,
    }),
    resourceNameActionSuccess() {
      return "Nova inspeção adicionada";
    },
    resourceNameActionFailure() {
      return "adicionar essa inspeção";
    },
  },
  methods: {
    ...mapActions("inspecoes", ["create"]),
    submit(payload) {
      return this.create(payload);
    },
  },
};
</script>
