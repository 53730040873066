<template>
  <div>
    <ResourceTable
      @activated:action="openSecondConfirmation"
      @selected:item="inspecao = $event"
      :actionName="acao"
      :items="inspecoes"
      :tableTitle="tabelaTitulo"
      :headers="cabecalhos"
      :resourceName="tipo"
      :confirmation="deleteConfirmation"
      item-key="id"
    >
      <template v-slot:dialogTitle>
        <span class="d-block">Você está prestes a excluir essa inspeção</span>
        <InspecaoResumo :inspecao="inspecao" />
      </template>
    </ResourceTable>
    <ConfirmationDialog
      v-model="secondConfirmationDialog"
      v-bind="secondConfirmation"
      @confirmed="submit"
    >
      <template v-slot:title>
        <span class="d-block">Confirmação de exclusão dessa inspeção</span>
        <InspecaoResumo :inspecao="inspecao" />
      </template>
    </ConfirmationDialog>
    <ConfirmationDialog
      v-model="successDialog"
      :title="successDialogTitle"
      theme="success"
      confirm="Fechar"
      auto-close
    />
    <ConfirmationDialog
      v-model="failureDialog"
      :title="failureDialogTitle"
      :text="failureDialogText"
      theme="error"
      confirm="Fechar"
    />
  </div>
</template>

<script>
import ConfirmationDialog from "@/components/ConfirmationDialog.vue";
import ResourceTable from "@/components/ResourceTable.vue";
import { AuthService } from "@/helpers/services";
import ResourceActionMixin from "@/mixins/ResourceActionMixin";
import moment from "moment";
import { mapActions, mapState } from "vuex";
import InspecaoResumo from "./Components/InspecaoResumo.vue";

const authService = new AuthService();
export default {
  name: "InspecaoExcluir",
  components: { ResourceTable, ConfirmationDialog, InspecaoResumo },
  mixins: [ResourceActionMixin],
  data: () => ({
    secondConfirmationDialog: false,
    acao: "excluir",
    tipo: "inspeção",
    tabelaTitulo: "Selecione qual inspeção gostaria de excluir",
    toggleHidden: true,
    instrumentoAdicionarSubmit: "Adicionar instrumento",
    instrumentoSelecionado: "Piziometro",
    inspecoes: [],
    inspecao: {},
    render: 0,
  }),

  computed: {
    ...mapState("inspecoes", {
      failure: (state) => state.deleteFailure,
      success: (state) => state.deleteSuccess,
    }),
    resourceNameActionSuccess() {
      return "Inspeção excluída";
    },
    resourceNameActionFailure() {
      return "excluir essa inspeção";
    },
    failureDialogText() {
      return "Tente novamente mais tarde";
    },
    deleteConfirmation() {
      return {
        theme: "warning",
        confirm: "Sim, quero excluir",
        cancel: "Não tenho certeza",
      };
    },
    secondConfirmation() {
      return {
        theme: "info",
        text:
          "Para realmente excluir esta inspeção," +
          " digite EXCLUIR no campo a baixo e pressione confirmar",
        confirmationText: "excluir",
      };
    },
    cabecalhos() {
      return [
        {
          text: "Data da Inspeção",
          align: "start",
          value: "data_inspecao",
          filterable: {
            input: "date",
            label: "Data da Inspeção",
          },
        },
        {
          text: "Instrumento",
          value: "instrumento",
          filterable: true,
        },
        {
          text: "Estrutura",
          value: "estrutura",
          filterable: {
            label: "Nome da estrutura",
          },
        },
        {
          text: "Responsável",
          value: "responsavel",
          filterable: true,
        },
        {
          text: "Título da inspeção",
          value: "inspecao",
          filterable: true,
        },
      ];
    },
  },
  mounted() {
    this.toggleHidden = true;
    this.buscaInspecoes();
  },
  methods: {
    ...mapActions("inspecoes", ["delete"]),
    openSecondConfirmation(item) {
      this.inspecao = item;
      this.secondConfirmationDialog = true;
    },
    buscaInspecoes() {
      authService
        .findInspecoes(localStorage.getItem("cliente_uuid"))
        .then(({ data }) => {
          for (let i in data) {
            let _date = new Date(data[i].data_inspecao);
            this.inspecoes.push({
              ...data[i],
              id: data[i].id,
              estrutura: data[i].nome_estrutura,
              instrumento: data[i].instrumento_nome,
              inspecao: data[i].titulo,
              data_inspecao: moment(_date).format("YYYY-MM-DD HH:mm:ss"),
            });
          }
          // instrumentos (ultimo)
          let dadosTabelas = this.inspecoes.sort((a, b) => {
            const nomeA = a.instrumento.toUpperCase(); // ignore upper and lowercase
            const nomeB = b.instrumento.toUpperCase(); // ignore upper and lowercase
            if (nomeA < nomeB) {
              return -1;
            }
            if (nomeA > nomeB) {
              return 1;
            }

            // names must be equal
            return 0;
          });
          // estruturas (penultimo)
          dadosTabelas = this.inspecoes.sort((a, b) => {
            const nomeA = a.estrutura.toUpperCase(); // ignore upper and lowercase
            const nomeB = b.estrutura.toUpperCase(); // ignore upper and lowercase
            if (nomeA < nomeB) {
              return -1;
            }
            if (nomeA > nomeB) {
              return 1;
            }

            // names must be equal
            return 0;
          });

          // data (primeiro)
          dadosTabelas = this.inspecoes.sort((a, b) => {
            const nomeA = a.data_inspecao.toUpperCase(); // ignore upper and lowercase
            const nomeB = b.data_inspecao.toUpperCase(); // ignore upper and lowercase
            if (nomeA > nomeB) {
              return -1;
            }
            if (nomeA < nomeB) {
              return 1;
            }

            // names must be equal
            return 0;
          });

          return dadosTabelas;
        })
        .catch((err) => {
          console.error(err);
          return err;
        });
    },
    submit() {
      let inspecao_id = this.inspecao.id;
      let client_id = localStorage.getItem("cliente_uuid");

      return this.delete({ inspecao_id, client_id });
    },
  },
};
</script>
