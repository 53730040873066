<template>
  <div>
    <ResourceTable
      @activated:action="openSecondConfirmation"
      @selected:item="estrutura = $event"
      :actionName="acao"
      :items="estruturas"
      :tableTitle="tabelaTitulo"
      :headers="cabecalhos"
      :resourceName="tipo"
      :confirmation="deleteConfirmation"
      item-key="name"
    />
    <ConfirmationDialog
      v-model="secondConfirmationDialog"
      v-bind="secondConfirmation"
      @confirmed="submit"
    />
    <ConfirmationDialog
      v-model="successDialog"
      :title="successDialogTitle"
      theme="success"
      confirm="Fechar"
      auto-close
    />
    <ConfirmationDialog
      v-model="failureDialog"
      :title="failureDialogTitle"
      :text="failureDialogText"
      theme="error"
      confirm="Fechar"
    />
  </div>
</template>

<script>
import ConfirmationDialog from "@/components/ConfirmationDialog.vue";
import ResourceTable from "@/components/ResourceTable.vue";
import { AuthService } from "@/helpers/services";
import ResourceActionMixin from "@/mixins/ResourceActionMixin";
import { mapActions, mapState } from "vuex";

const authService = new AuthService();
export default {
  components: { ResourceTable, ConfirmationDialog },
  mixins: [ResourceActionMixin],
  data: () => ({
    secondConfirmationDialog: false,
    acao: "excluir",
    tipo: "estrutura",
    tabelaTitulo: "Selecione qual estrutura gostaria de excluir",
    cliente_id: localStorage.getItem("cliente_uuid"),
    usuario_id: localStorage.getItem("usuario_uuid"),
    estruturas: [],
    minhasEstruturas: [],
    estrutura: {},
    render: 0,
  }),
  mounted() {
    this.buscaEstruturas();
  },
  computed: {
    ...mapState("estruturas", {
      failure: (state) => state.deleteFailure,
      success: (state) => state.deleteSuccess,
    }),
    resourceNameActionSuccess() {
      return "Estrutura excluída";
    },
    resourceNameActionFailure() {
      return "excluir essa estrutura";
    },
    failureDialogText() {
      return "Tente novamente mais tarde";
    },
    deleteConfirmation() {
      return {
        theme: "warning",
        title: `Você está prestes a excluir a estrutura "${this.estrutura.nome}"`,
        text:
          "Quando você exclui uma estrutura, todos os instrumentos" +
          " cadastrados nela serão excluídos também, você tem certeza" +
          " que deseja excluir?",
        confirm: "Sim, quero excluir",
        cancel: "Não tenho certeza",
      };
    },
    secondConfirmation() {
      return {
        theme: "info",
        title:
          "Confirmação de exclusão da estrutura " +
          `"${this.estrutura.nome}" e seus instrumentos`,
        text:
          "Para realmente excluir esta estrutura e seus instrumentos," +
          " digite EXCLUIR no campo a baixo e pressione confirmar",
        confirmationText: "excluir",
      };
    },
    cabecalhos() {
      return [
        {
          text: "Estrutura",
          align: "start",
          sortable: false,
          value: "name",
          filterable: {
            input: "select",
            items: this.minhasEstruturas,
            label: "Minhas estruturas",
          },
        },
        {
          text: "Localização",
          value: "cidade",
          filterable: {
            label: "Localização",
          },
        },
      ];
    },
  },
  methods: {
    ...mapActions("estruturas", ["delete"]),
    openSecondConfirmation(item) {
      this.estrutura = item;
      this.secondConfirmationDialog = true;
    },
    buscaEstruturas() {
      authService
        .buscaEstruturas()
        .then(({ data }) => {
          let payload = data.data;
          for (let i in payload) {
            this.estruturas.push({
              text: payload[i].nome,
              name: payload[i].nome,
              value: payload[i].index,
              uuid: payload[i].uuid,
              ...payload[i],
            });
          }
          this.estruturas.sort((a, b) => {
            const nomeA = a.nome.toUpperCase(); // ignore upper and lowercase
            const nomeB = b.nome.toUpperCase(); // ignore upper and lowercase
            if (nomeA < nomeB) {
              return -1;
            }
            if (nomeA > nomeB) {
              return 1;
            }

            // names must be equal
            return 0;
          });

          // filter owned estruturas
          // this.minhasEstruturas = this.estruturas
          //   .map((e) => e.estrutura_nome || e.nome)
          //   .filter((e, i, a) => a.indexOf(e));
          this.minhasEstruturas = this.estruturas.map(
            (e) => e.nome || e.estrutura_nome
          );

          return payload;
        })
        .catch((err) => {
          console.error(err);
          return err;
        });
    },
    submit() {
      let estrutura = {
        ...this.estrutura,
        estrutura_id: this.estrutura.uuid,
        cliente_id: localStorage.getItem("cliente_uuid"),
        usuario_id: localStorage.getItem("usuario_uuid"),
      };

      return this.delete(estrutura).then(() => this.markForReloadEstruturas());
    },
  },
};
</script>
