<script>
import moment from "moment";
import { Line as LineChartGenerator } from "vue-chartjs/legacy";

// prettier-ignore
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  TimeScale,
  TimeSeriesScale,
  Title,
  Tooltip,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
  TimeScale,
  TimeSeriesScale
);

function mean(leiturasValues) {
  if (leiturasValues.length === 0) return null;

  const sum = leiturasValues.reduce((sum, l) => Number(sum) + Number(l), 0);
  const qty = leiturasValues.length;

  return sum / qty;
}

function no_aggr(leiturasValues) {
  if (leiturasValues.length === 0) return null;
  // Return the values as they are, without any aggregation
  return leiturasValues.map((v) => Number(v).toFixed(2));
}

const any = (vals) => (v) => vals.includes(v);

function smallestIndex(date, dates) {
  let index = 0;

  for (const i in dates) {
    const p = dates[i];

    if (p.diff(date) <= 0) {
      index = i;
    } else {
      return index;
    }
  }

  return index;
}

export default {
  name: "LineChart",
  components: {
    LineChartGenerator,
  },
  props: {
    chartId: { type: String, required: false },
    chartTitle: { type: String, required: true },
    instrumento: { type: Object, required: false },
    leituras: { type: Array, required: true },
    datas: { type: Array, required: true },
    intervalo: {
      type: String,
      required: false,
      default: "day",
      validator: any(["day", "week", "month", "none"]),
    },
    inspecoes: { type: Array, required: false },
  },
  computed: {
    chartPlugins() {
      return [
        {
          legend: {
            display: true,
            position: "bottom",
          },
        },
      ];
    },
    chartData() {
      return {
        datasets: this.datasets,
        labels: this.labels,
      };
    },
    labels() {
      // return this.periodos.map((p) => p.format("YYYY-MM-DD"));
      if (this.intervalo === "none") {
        // Directly use the timestamps from the data points when "none" is selected
        return this.periodos.map((p) => p.format("YYYY-MM-DD HH:mm:ss"));
      } else {
        // For other intervals, use the periodos
        return this.periodos.map((p) => p.format("YYYY-MM-DD"));
      }
    },
    datasets() {
      const datasetSaude = {
        label: this.chartTitle,
        borderColor: "#F83F3F",
        backgroundColor: "#f87979",
      };
      const datasetInspecoes = {
        label: "Inspeções",
        borderColor: "#FFA500",
        backgroundColor: "#FFA500",
        fill: true,
        spanGaps: false,
        pointStyle: "rect",
        showLine: false,
        pointRadius: 6,
      };

      let inspecoes = [];
      let text = [];

      const leiturasValuesAuto =
        this.intervalo === "none"
          ? this.groupLeiturasInPeriodos(this.leituras, no_aggr) || []
          : this.groupLeiturasInPeriodos(this.leituras, mean) || [];

      let datasetLeiturasSaude = {};
      datasetLeiturasSaude = {
        ...datasetSaude,
        data: leiturasValuesAuto,
      };

      this.inspecoes?.map((item, i) => {
        if (item) {
          text.push(this.inspecoes[i].titulo);
          inspecoes.push(leiturasValuesAuto[i] ?? 3.5);
        } else {
          text.push(null);
          inspecoes.push(null);
        }
      });

      let datasetVisualizarInspecoes = {
        ...datasetInspecoes,
        data: inspecoes,
        text: text,
      };
      return [datasetVisualizarInspecoes, datasetLeiturasSaude];
    },
    periodos() {
      const end = moment(this.datas[1]);
      const dates = [];

      if (this.intervalo === "none") {
        // For "none", return the actual timestamps for each data point, date and time
        this.leituras.forEach((leitura) => {
          const ts = `${leitura.data_leitura} ${leitura.hora_leitura}`;
          dates.push(moment(ts, "YYYY-MM-DD HH:mm:ss").clone());
        });
        return dates;
      }

      for (
        const date = moment(this.datas[0]).startOf("day");
        date.isSameOrBefore(end);
        date.add(1, this.intervalo)
      ) {
        dates.push(date.clone());
      }
      if (this.datas[0] === this.datas[1] && dates.length == 1) {
        dates.push(moment(this.datas[0]).clone());
        return dates;
      }

      return dates;
    },
  },
  methods: {
    groupLeiturasInPeriodos(leituras, aggregate) {
      const leiturasValues = new Array(this.periodos.length);

      const grouped = leituras.reduce((byPeriodo, leitura) => {
        const idx = this.periodoIndexForLeitura(leitura);
        byPeriodo[idx] ||= [];
        byPeriodo[idx].push(this.calculateValue(leitura));

        return byPeriodo;
      }, Object.create(null));

      for (const i in this.periodos) {
        leiturasValues[i] = aggregate(grouped[i] || []);
      }

      return this.intervalo === "none"
        ? leiturasValues.flat().filter((value) => value !== null)
        : leiturasValues;
    },
    periodoIndexForLeitura(leitura) {
      const date = moment(leitura.data_leitura);
      return smallestIndex(date, this.periodos);
    },
    calculateValue(leitura) {
      return leitura.leitura;
    },
  },
  data() {
    return {
      label: this.chartTitle,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        fill: false,
        stepped: false,
        elements: {
          line: {
            tension: 0.1,
            spanGaps: false,
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: function (context) {
                if (context.dataset.label !== "Inspeções") {
                  return context.dataset.label + ": " + context.formattedValue;
                } else {
                  return context.dataset.text[context.dataIndex];
                }
              },
            },
          },
        },
      },
    };
  },
};
</script>
<template>
  <div style="margin-bottom: 75px">
    <h2 style="margin-bottom: 5px">{{ chartTitle }}</h2>
    <LineChartGenerator
      ref="chart"
      :chart-id="chartId"
      :chart-options="chartOptions"
      :chart-data="chartData"
      :plugins="chartPlugins"
    />
  </div>
</template>
