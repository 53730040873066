<script>
export default {
  name: "InstrumentoDetails",
  components: {},
  props: {
    instrumento: { type: Object, required: true },
  },
  computed: {
    //Piezometro e INA
    estruturaResumo() {
      return [
        {
          text: "Nome",
          value: this.instrumento.nome || "-",
          instrumento_boolean: true,
        },
        {
          text: "Estrutura",
          value: this.instrumento.estrutura || "-",
          instrumento_boolean: true,
        },
        {
          text: "Latitude/Longitude",
          value: `${this.instrumento.latitude || "?"} / ${
            this.instrumento.longitude || "?"
          }`,
          instrumento_boolean: true,
        },
        {
          text: this.instrumento?.tipo_instrumento_value
            ? "Alarme alto"
            : "Cota de atenção",
          value: this.instrumento.nivel_atencao || this.instrumento?.p1 || "-",
          instrumento_boolean:
            this.instrumento.tipo_instrumento == "inst_piezometro" ||
            this.instrumento.tipo_instrumento == "inst_outros",
        },
        {
          text: this.instrumento?.tipo_instrumento_value
            ? "Alarme baixo"
            : "Cota de alerta",
          value: this.instrumento.nivel_alerta || this.instrumento?.p2 || "-",
          instrumento_boolean:
            this.instrumento.tipo_instrumento == "inst_piezometro" ||
            this.instrumento.tipo_instrumento == "inst_outros",
        },
        {
          text: "Cota de emergência",
          value: this.instrumento.nivel_emergencia || "-",
          instrumento_boolean:
            this.instrumento.tipo_instrumento == "inst_piezometro",
        },
        {
          text: "Cota de alarme",
          value: this.instrumento.cota_alarme || "-",
          instrumento_boolean:
            this.instrumento.tipo_instrumento == "inst_regua_reservatorio",
        },
        // {
        //   text: "Local",
        //   value: `${this.instrumento.estruturaPai.cidade || "-"} - ${
        //     this.instrumento.estruturaPai.uf || "-"
        //   }`,
        //   instrumento_boolean: true,
        // },
        {
          text: "Cota de topo",
          value: this.instrumento.cota_topo || "-",
          instrumento_boolean:
            this.instrumento.tipo_instrumento == "inst_piezometro" ||
            this.instrumento.tipo_instrumento == "inst_regua_reservatorio",
        },
        {
          text: "Cota base",
          value: this.instrumento.cota_base || "-",
          instrumento_boolean:
            this.instrumento.tipo_instrumento == "inst_regua_reservatorio",
        },
        {
          text: "Cota de fundo",
          value: this.instrumento.cota_fundo || "-",
          instrumento_boolean:
            this.instrumento.tipo_instrumento == "inst_piezometro",
        },
        {
          text: "Crista barragem",
          value: this.instrumento.crista_barragem || "-",
          instrumento_boolean:
            this.instrumento.tipo_instrumento == "inst_regua_reservatorio",
        },
        {
          text: "Soleira vertedor",
          value: this.instrumento.soleira_vertedor || "-",
          instrumento_boolean:
            this.instrumento.tipo_instrumento == "inst_regua_reservatorio",
        },
        {
          text: "Diâmetro do tubo",
          value: this.instrumento.diametro_tubo || "-",
          instrumento_boolean:
            this.instrumento.tipo_instrumento == "inst_piezometro",
        },
        {
          text: "Condição",
          value: this.instrumento.condicao_instrumento || "-",
          instrumento_boolean: true,
        },
        {
          text: "Elevação",
          value: this.instrumento.elevacao || "-",
          instrumento_boolean: true,
        },
        {
          text: "Local de Instalação",
          value: this.instrumento.local_instalacao || "-",
          instrumento_boolean: true,
        },
      ];
    },
  },
};
</script>
<template>
  <v-card flat outlined v-if="instrumento">
    <v-container>
      <v-row>
        <v-col
          v-for="(item, idx) in estruturaResumo"
          :key="idx"
          cols="12"
          md="2"
          v-show="item.instrumento_boolean"
        >
          <div class="text-caption grey--text">{{ item.text }}</div>
          <div class="font-weight-bold grey--text text--darken-1">
            {{ item.value }}
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
