<template>
  <v-row style="display: flex; justify-content: center">
    <hr />
    <v-col md="5">
      <div style="padding: 10px 0px; text-align: center; font-size: 22px">
        <div class="font-weight-bold text--darken-1 grey--text">
          {{ form.inspecao }}
        </div>
      </div>
      <div style="padding: 10px 0px">
        <div class="text-caption grey--text">Estrutura</div>
        <div class="font-weight-bold text--darken-1 grey--text">
          {{ form.estrutura }}
        </div>
      </div>
      <div style="padding: 10px 0px">
        <div class="text-caption grey--text">Instrumento</div>
        <div class="font-weight-bold text--darken-1 grey--text">
          {{ form.instrumento }}
        </div>
      </div>
      <div style="padding: 10px 0px">
        <div class="text-caption grey--text">Data/Hora da inspeção</div>
        <div class="font-weight-bold text--darken-1 grey--text">
          {{ form.data_inspecao }}
        </div>
      </div>
      <div style="padding: 10px 0px">
        <div class="text-caption grey--text">Observação</div>
        <div class="font-weight-bold text--darken-1 grey--text">
          {{ form.observacao }}
        </div>
      </div>
      <div class="imagem">
        <v-carousel
          v-if="form.imagens.length"
          v-model="number"
          style="padding: 10px 0px; width: 100%"
          cycle
          hide-delimiter-background
          show-arrows-on-hover
        >
          <v-carousel-item v-for="(slide, index) in form.imagens" :key="index">
            <img :src="slide.url" class="img" alt="image" />
          </v-carousel-item>
        </v-carousel>
      </div>
      <div v-if="form.imagens.length" style="padding: 20px 0px">
        <div
          style="text-align: center"
          class="font-weight-bold text--darken-1 grey--text"
        >
          {{ form.imagens[number].comentario }}
        </div>
      </div>

      <!-- <v-row>
        <v-col>
          <v-btn block class="pa-6 primary darken-2" @click="$emit('toggle')">
            Editar
          </v-btn>
        </v-col>
        <v-col> -->
      <v-btn block class="pa-6 primary darken-2" @click="$emit('toggle')">
        Voltar
      </v-btn>
      <!-- </v-col>
      </v-row> -->
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "FormularioVisualizar",
  props: {
    form: {
      default: () => {},
      type: Object,
    },
  },
  data: () => ({
    toggleHidden: true,
    number: 0,
  }),

  computed: {},
  methods: {},
};
</script>

<style scoped>
.v-text-field {
  margin: 0;
  border-radius: 10px;
}
.v-select {
  margin: 0;
  border-radius: 10px;
}

.v-carousel {
  margin: 0;
  border-radius: 10px;
}

.imagem {
  display: flex;
  position: relative;
  width: 100%;
  height: 50vh;
}

.img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
</style>
