<template>
  <v-container v-if="!toggleHidden">
    <v-row>
      <v-col>
        <h2 class="text-h6 font-weight-bold pb-7">
          Preencha os dados abaixo para editar uma estrutura
        </h2>
      </v-col>
    </v-row>
    <Formulario
      ref="form"
      :form="estrutura"
      :tipoAcao="'Editar'"
      :key="render"
      @enviaFormulario="submit"
    />
    <ConfirmationDialog
      v-model="successDialog"
      :title="successDialogTitle"
      theme="success"
      confirm="Fechar"
      auto-close
    />
    <ConfirmationDialog
      v-model="failureDialog"
      :title="failureDialogTitle"
      :text="failureDialogText"
      theme="error"
      confirm="Fechar"
    />
  </v-container>
  <ResourceTable
    v-else
    @activated:action="editarEstrutura"
    :actionName="acao"
    :items="estruturas"
    :tableTitle="tabelaTitulo"
    :headers="cabecalhos"
    :resourceName="tipo"
    item-key="name"
  />
</template>

<script>
import ConfirmationDialog from "@/components/ConfirmationDialog.vue";
import ResourceTable from "@/components/ResourceTable.vue";
import { AuthService } from "@/helpers/services";
import ResourceActionMixin from "@/mixins/ResourceActionMixin";
import { mapActions, mapState } from "vuex";
import Formulario from "./Components/FormularioEstrutura.vue";

const authService = new AuthService();
export default {
  name: "EstruturaEditar",
  components: { ResourceTable, Formulario, ConfirmationDialog },
  mixins: [ResourceActionMixin],
  data: () => ({
    acao: "editar",
    tipo: "estrutura",
    tabelaTitulo: "Selecione qual estrutura gostaria de editar",
    toggleHidden: true,
    cliente_id: localStorage.getItem("cliente_uuid"),
    usuario_id: localStorage.getItem("usuario_uuid"),
    minhasEstruturas: [],
    estruturas: [],
    estrutura: {},
    render: 0,
    mina: "",
  }),
  mounted() {
    this.toggleHidden = true;
    this.buscaEstruturas();
  },
  computed: {
    ...mapState("estruturas", {
      failure: (state) => state.editFailure,
      success: (state) => state.editSuccess,
    }),
    resourceNameActionSuccess() {
      return "Estrutura atualizada";
    },
    resourceNameActionFailure() {
      return "atualizar essa estrutura";
    },
    cabecalhos() {
      return [
        {
          text: "Estrutura",
          align: "start",
          sortable: false,
          value: "name",
          filterable: {
            input: "select",
            items: this.minhasEstruturas,
            label: "Minhas estruturas",
          },
        },
        {
          text: "Localização",
          value: "cidade",
          filterable: {
            label: "Localização",
          },
        },
      ];
    },
  },
  methods: {
    ...mapActions("estruturas", ["edit"]),
    editarEstrutura(payload) {
      this.toggleShow();
      this.estrutura = { ...payload };
      return payload;
      // this.estrutura((e) => (e = payload[i].value));
    },
    toggleShow() {
      this.toggleHidden = !this.toggleHidden;
    },
    buscaEstruturas() {
      authService
        .buscaEstruturas()
        .then(({ data }) => {
          let payload = data.data;
          for (let i in payload) {
            this.estruturas.push({
              text: payload[i].nome,
              name: payload[i].nome,
              value: payload[i].index,
              uuid: payload[i].uuid,
              ...payload[i],
            });
          }
          this.estruturas.sort((a, b) => {
            const nomeA = a.nome.toUpperCase(); // ignore upper and lowercase
            const nomeB = b.nome.toUpperCase(); // ignore upper and lowercase
            if (nomeA < nomeB) {
              return -1;
            }
            if (nomeA > nomeB) {
              return 1;
            }

            // names must be equal
            return 0;
          });

          // filter owned estruturas
          // this.minhasEstruturas = this.estruturas
          //   .map((e) => e.estrutura_nome || e.nome)
          //   .filter((e, i, a) => a.indexOf(e));
          this.minhasEstruturas = this.estruturas.map(
            (e) => e.nome || e.estrutura_nome
          );

          return payload;
        })
        .catch((err) => {
          console.error(err);
          return err;
        });
    },
    submit(payload) {
      let estrutura = {
        ...payload,
        cliente_id: localStorage.getItem("cliente_uuid"),
        usuario_id: localStorage.getItem("usuario_uuid"),
      };
      return this.edit(estrutura).then(() => this.markForReloadEstruturas());
    },
  },
};
</script>
