<template>
  <v-container v-if="!toggleHidden">
    <v-row>
      <v-col cols="12">
        <h2 class="text-h6 font-weight-bold pb-7">
          Preencha os dados abaixo para editar um instrumento
        </h2>
      </v-col>
    </v-row>
    <Formulario
      ref="form"
      :form="instrumento"
      :tipoAcao="'Editar'"
      :key="render"
      :tiposInstrumentos="tiposInstrumentos"
      @enviaFormulario="submit"
    />
    <ConfirmationDialog
      v-model="successDialog"
      :title="successDialogTitle"
      theme="success"
      confirm="Fechar"
      auto-close
    />
    <ConfirmationDialog
      v-model="failureDialog"
      :title="failureDialogTitle"
      :text="failureDialogText"
      theme="error"
      confirm="Fechar"
    />
  </v-container>
  <ResourceTable
    v-else
    @activated:action="editarInstrumento"
    :actionName="acao"
    :items="instrumentos"
    :tableTitle="tabelaTitulo"
    :headers="cabecalhos"
    :resourceName="tipo"
    item-key="index"
  />
</template>
<script>
import ConfirmationDialog from "@/components/ConfirmationDialog.vue";
import ResourceTable from "@/components/ResourceTable.vue";
import { AuthService } from "@/helpers/services";
import InstrumentoTiposMixin from "@/mixins/InstrumentoTiposMixin";
import ResourceActionMixin from "@/mixins/ResourceActionMixin";
import { mapActions, mapState } from "vuex";
import Formulario from "./Components/FormularioInstrumento.vue";

const authService = new AuthService();
export default {
  name: "InstrumentoEditar",
  components: { ResourceTable, Formulario, ConfirmationDialog },
  mixins: [ResourceActionMixin, InstrumentoTiposMixin],
  data: () => ({
    acao: "editar",
    tipo: "instrumento",
    tabelaTitulo: "Selecione qual instrumento gostaria de editar",
    toggleHidden: true,
    cliente_id: localStorage.getItem("cliente_uuid"),
    usuario_id: localStorage.getItem("usuario_uuid"),
    instrumentos: [],
    instrumento: {},
    render: 0,
  }),
  computed: {
    ...mapState("instrumentos", {
      failure: (state) => state.editFailure,
      success: (state) => state.editSuccess,
    }),
    resourceNameActionSuccess() {
      return "Instrumento atualizado";
    },
    resourceNameActionFailure() {
      return "atualizar esse instrumento";
    },
    cabecalhos() {
      return [
        {
          text: "Instrumento",
          value: "nome",
          align: "start",
          filterable: {
            label: "Nome do instrumento",
          },
        },
        {
          text: "Tipo de instrumento",
          value: "tipo",
          filterable: {
            input: "select",
            items: this.tiposInstrumentos.map((ti) => ti.text),
            label: "Tipo do instrumento",
          },
        },
        {
          text: "Estrutura",
          value: "estrutura",
          filterable: {
            label: "Nome da estrutura",
          },
        },
      ];
    },
  },
  mounted() {
    this.toggleHidden = true;
    this.buscaInstrumentos();
  },
  methods: {
    ...mapActions("instrumentos", ["edit"]),
    editarInstrumento(payload) {
      this.toggleShow();
      this.instrumento = this.instrumentoWithTipo(payload);
      return this.instrumento;
      // this.estrutura((e) => (e = payload[i].value));
    },
    toggleShow() {
      this.toggleHidden = !this.toggleHidden;
    },
    buscaInstrumentos() {
      authService
        .buscaInstrumentos()
        .then(({ data }) => {
          if (Array.isArray(data)) {
            let dadosTabela = data.forEach((i) =>
              this.instrumentos.unshift(this.instrumentoWithTipo(i))
            );
            dadosTabela = this.instrumentos.sort((a, b) => {
              const nomeA = a.text.toUpperCase(); // ignore upper and lowercase
              const nomeB = b.text.toUpperCase(); // ignore upper and lowercase
              if (nomeA < nomeB) {
                return -1;
              }
              if (nomeA > nomeB) {
                return 1;
              }

              // names must be equal
              return 0;
            });
            return dadosTabela;
          } else {
            return [];
          }
        })
        .catch((err) => {
          console.error(err);
          return err;
        });
    },
    submit(payload) {
      if (payload.tipo_instrumento === "inst_outros") {
        let instrumento = {
          ...payload,
          cliente_id: localStorage.getItem("cliente_uuid"),
          usuario_id: localStorage.getItem("usuario_uuid"),
        };
        return this.edit(instrumento).then(() =>
          this.markForReloadEstruturas()
        );
      }
      let instrumento = {
        ...payload,
        cliente_id: localStorage.getItem("cliente_uuid"),
        usuario_id: localStorage.getItem("usuario_uuid"),
      };
      return this.edit(instrumento).then(() => this.markForReloadEstruturas());
    },
  },
};
</script>
