<template>
  <v-container>
    <v-row>
      <v-col>
        <h2 class="text-h6 font-weight-bold pb-7">
          Preencha os dados abaixo para adicionar uma nova estrutura
        </h2>
      </v-col>
    </v-row>
    <FormularioEstrutura ref="form" @enviaFormulario="submit" />
    <ConfirmationDialog
      v-model="successDialog"
      :title="successDialogTitle"
      theme="success"
      confirm="Fechar"
      auto-close
    />
    <ConfirmationDialog
      v-model="failureDialog"
      :title="failureDialogTitle"
      :text="failureDialogText"
      theme="error"
      confirm="Fechar"
    />
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import FormularioEstrutura from "./Components/FormularioEstrutura.vue";
import ConfirmationDialog from "@/components/ConfirmationDialog.vue";
import ResourceActionMixin from "@/mixins/ResourceActionMixin";

export default {
  name: "EstruturaAdicionar",
  components: { FormularioEstrutura, ConfirmationDialog },
  mixins: [ResourceActionMixin],
  data: () => ({ tipoAcao: "Adicionar" }),
  computed: {
    ...mapState("estruturas", {
      failure: (state) => state.createFailure,
      success: (state) => state.createSuccess,
    }),
    resourceNameActionSuccess() {
      return "Nova estrutura criada";
    },
    resourceNameActionFailure() {
      return "criar essa estrutura";
    },
  },
  methods: {
    ...mapActions("estruturas", ["create"]),
    submit(payload) {
      let estrutura = {
        ...payload,
        cliente_id: localStorage.getItem("cliente_uuid"),
        usuario_id: localStorage.getItem("usuario_uuid"),
      };
      return this.create(estrutura).then(() => this.markForReloadEstruturas());
    },
  },
};
</script>
