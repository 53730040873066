<template>
  <ResourceTable
    :actionName="acao"
    :items="leituras"
    :tableTitle="tabelaTitulo"
    :headers="cabecalhos"
    :resourceName="tipo"
    :showSelect="false"
    item-key="id"
  />
</template>

<script>
import moment from "moment";
import { AuthService } from "@/helpers/services";
import ResourceTable, {
  historicoActionsNames,
} from "@/components/ResourceTable.vue";

const authService = new AuthService();
export default {
  name: "LeituraHistorico",
  stoggleHidden: false,
  components: {
    ResourceTable,
  },
  data: () => ({
    acao: "historico",
    tipo: "leitura",
    tabelaTitulo: "Histórico de alterações nas leituras",
    leituras: [],
    render: 0,
  }),
  computed: {
    cabecalhos() {
      return [
        {
          text: "Instrumento",
          value: "instrumento_nome",
          align: "start",
          filterable: {
            label: "Nome do instrumento",
          },
        },
        {
          text: "Nome da estrutura",
          value: "estrutura_nome",
          align: "start",
          filterable: {
            label: "Nome da estrutura",
          },
        },
        {
          text: "Leitura",
          value: "conteudo.leitura",
          filterable: false,
        },
        {
          text: "Data leitura",
          value: "conteudo.dataHora",
          filterable: {
            input: "date",
            label: "Data da leitura",
          },
        },
        {
          text: "Ação",
          value: "acao",
          filterable: {
            input: "select",
            items: historicoActionsNames,
            label: "Ação",
          },
        },
        {
          text: "Responsável",
          value: "responsavel",
          filterable: {
            label: "Nome do responsável",
          },
        },
        {
          text: "Data do registro",
          align: "start",
          value: "data_cadastro",
          filterable: {
            input: "date",
            label: "Data da alteração",
          },
        },
      ];
    },
  },
  mounted() {
    this.buscaHistorico();
  },
  methods: {
    buscaHistorico() {
      authService
        .buscaHistorico("Leitura")
        .then(({ data }) => {
          let payload = data.data;
          for (let i in payload) {
            let _date = new Date(payload[i].data_cadastro);
            let leitura = this.leituraFromHistorico(payload[i].conteudo);

            this.leituras.push({
              ...payload[i],
              conteudo: {
                leitura: leitura.leitura,
                dataHora: leitura.dataHora,
              },
              text: payload[i].nome,
              name: payload[i].nome,
              value: payload[i].id,
              uuid: payload[i].id,
              data_cadastro: moment(_date).format("YYYY-MM-DD hh:mm"),
            });
          }
          this.leituras.reverse();

          return payload;
        })
        .catch((err) => {
          console.error(err);
          return err;
        });
    },
    leituraFromHistorico(payload) {
      if (typeof payload === "string") {
        try {
          let leitura = JSON.parse(payload);

          return {
            ...leitura,
            dataHora: moment(leitura.data_leitura).format("YYYY-MM-DD hh:mm"),
          };
        } catch (e) {
          console.error(e, "invalid leitura from historico");
        }
      }

      return {};
    },
  },
};
</script>

<style></style>
