<script>
export default {
  name: "ResourceActionTabs",
  props: {
    type: { type: String },
    tabsList: {
      type: Array,
      default: () => [
        { id: "add", title: "Adicionar", action: "adicionar" },
        { id: "edit", title: "Editar", action: "editar" },
        { id: "delete", title: "Excluir", action: "excluir" },
        { id: "audit", title: "Histórico", action: "historico" },
        { id: "download", title: "Download", action: "download" },
        { id: "visualizar", title: "Visualizar", action: "visualizar" },
      ],
      validator: (v) => v.every((x) => !!x.id),
    },
    only: {
      type: Array,
      default: () => ["add", "edit", "delete", "audit"],
      validator: (v) => v.every((x) => typeof x === "string"),
    },
    leitura: {
      type: Array,
      default: () => ["add", "edit", "delete", "audit", "download"],
      validator: (v) => v.every((x) => typeof x === "string"),
    },
    inspecao: {
      type: Array,
      default: () => ["add", "edit", "delete", "audit", "visualizar"],
      validator: (v) => v.every((x) => typeof x === "string"),
    },
    except: {
      type: Array,
      default: () => [],
      validator: (v) => v.every((x) => typeof x === "string"),
    },
  },
  computed: {
    tabs() {
      if (this.type === "Leitura") {
        const tabs = new Set(
          this.leitura.filter((x) => this.except.indexOf(x) < 0)
        );
        return this.tabsList.filter((t) => tabs.has(t.id));
      } else if (this.type === "Inspecao") {
        const tabs = new Set(
          this.inspecao.filter((x) => this.except.indexOf(x) < 0)
        );
        return this.tabsList.filter((t) => tabs.has(t.id));
      } else {
        const tabs = new Set(
          this.only.filter((x) => this.except.indexOf(x) < 0)
        );
        return this.tabsList.filter((t) => tabs.has(t.id));
      }
    },
  },
};
</script>
<template>
  <v-tabs
    color="primary darken-2"
    class="border--gray"
    :show-arrows="$vuetify.breakpoint.mobile"
  >
    <v-tab v-for="(item, index) in tabs" :key="index" :to="item.action">{{
      item.title
    }}</v-tab>
    <v-tabs-slider color="primary darken-2"></v-tabs-slider>
  </v-tabs>
</template>

<style scoped>
.v-tab {
  text-transform: none !important;
}
.border--gray {
  border-bottom: rgb(235, 235, 235, 0.5) 2px solid;
}
</style>
